import React, { useRef, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Slide,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { useTheme } from '@mui/styles';

import useStyles from './styles';

import {
  useGetCurrentUser,
  useMutateAddComment,
  useMutateEditTicket
} from '../../../hooks';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});


const EditPeriod = (props) => {

  const {
    isPeriodDialogOpen,
    setIsPeriodDialogOpen,
    ticket
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isBreakpointsDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBreakpointsUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const formRef = useRef();

  const [recurringStartPeriod, setRecurringStartPeriod] = useState(ticket?.data?.recurringStartPeriod);
  const [recurringEndPeriod, setRecurringEndPeriod] = useState(ticket?.data?.recurringEndPeriod);

  const today = new Date();

  const currentUser = useGetCurrentUser();
  const addCommentMutation = useMutateAddComment();
  const editTicketMutation = useMutateEditTicket();

  const editTicket = (recurringStartPeriod, recurringEndPeriod) => {
    editTicketMutation.mutate({
      id: ticket?.data?.id,
      body: {
        recurringStartPeriod: recurringStartPeriod,
        recurringEndPeriod: recurringEndPeriod
      },
    },
    {
      onSuccess: (data) => {
        ticket.refetch();
        handleCloseDialog(data);
      }
    });
  };

  const handleCloseDialog = (data) => {
    setIsPeriodDialogOpen(false);
    setRecurringStartPeriod(data.recurringStartPeriod || ticket?.data?.recurringStartPeriod);
    setRecurringEndPeriod(data.recurringEndPeriod || ticket?.data?.recurringEndPeriod);
  };

  const handleOnSubmit = () => {
    if (formRef.current.reportValidity()) {
      editTicket(recurringStartPeriod, recurringEndPeriod);
    }
  };

  return (
    <Dialog
      fullScreen={isBreakpointsDownSm}
      fullWidth={!isBreakpointsDownSm}
      style={{ minWidth: !isBreakpointsDownSm ? 600 : 200 }}
      open={isPeriodDialogOpen}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Typography component='h1' variant='h4' align='center'>
          New Period
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <form ref={formRef}>
          <Grid container spacing={3}>
            { /* Start date */}
            <Grid item xs={12} sm={6}>
              <FormControl
                variant='outlined'
                fullWidth
              >
                <DateTimePicker
                  inputVariant='outlined'
                  label='Start date'
                  minDateTime={today}
                  variant='dialog'
                  value={recurringStartPeriod}
                  onChange={(event) => { setRecurringStartPeriod(event); }}
                  renderInput={(params) => <TextField required {...params} />}
                />
              </FormControl>
            </Grid>

            { /* End date */}
            <Grid item xs={12} sm={6}>
              <FormControl
                variant='outlined'
                fullWidth
              >
                <DateTimePicker
                  inputVariant='outlined'
                  label='End date'
                  minDateTime={today}
                  variant='dialog'
                  value={recurringEndPeriod}
                  onChange={(event) => { setRecurringEndPeriod(event); }}
                  renderInput={(params) => <TextField required {...params} />}
                />
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          className={classes.button}
          onClick={handleCloseDialog}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          className={classes.button}
          onClick={handleOnSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditPeriod;
