import axiosInstance from '../axios.instance';

export const apiAddTicketPart = async (variables) => {
  let config = {
    method: 'post',
    url: `/ticketparts`,
    data: variables.body
  };
  const response = await axiosInstance(config);
  return response.data;
};

export const apiGetTicketParts = async (variables) => {
  let config = {
    method: 'get',
    url: '/ticketparts',
    params: variables
  };
  const { data, headers } = await axiosInstance(config);
  return {
    data,
    headers
  };
};

export const apiGetTicketPart = async (variables) => {
  let config = {
    method: 'get',
    url: `/ticketparts/${variables.id}`
  };
  const response = await axiosInstance(config);
  return response.data;
};

export const apiDelTicketPart = async (variables) => {
  let config = {
    method: 'delete',
    url: `/ticketparts/${variables.id}`
  }
  const response = await axiosInstance(config);
  return response.data;
};