import axiosInstance from '../axios.instance';

export const apiAddUser = async (variables) => {
  let config = {
    method: 'post',
    url: `/users`,
    data: variables.body
  };
  const response = await axiosInstance(config);
  return response.data;
};

export const apiGetUsers = async (variables) => {
  let config = {
    method: 'get',
    url: '/users',
    params: variables
  };
  const { data, headers } = await axiosInstance(config);
  return {
    data,
    headers
  };
};

export const apiGetUser = async (variables) => {
  let config = {
    method: 'get',
    url: `/users/${variables.id}`
  };
  const response = await axiosInstance(config);
  return response.data;
};

export const apiEditUser = async (variables) => {
  let config = {
    method: 'put',
    url: `/users/${variables.id}`,
    data: variables.body
  };
  const response = await axiosInstance(config);
  return response.data;
};

export const apiDelUser = async (variables) => {
  let config = {
    method: 'delete',
    url: `/users/${variables.id}`
  }
  const response = await axiosInstance(config);
  return response.data;
};

export const apiGetCurrentUser = async (variables) => {
  let config = {
    method: 'get',
    url: '/users/me'
  };
  const response = await axiosInstance(config);
  return response.data;
};