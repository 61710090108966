import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';

import { apiEditNotification } from '../../api/endpoints';

const useMutateEditNotification = () => {

  /* Notification */
  const { enqueueSnackbar } = useSnackbar();
  const notify = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant
    });
  };

  return useMutation((variables) => apiEditNotification(variables), {
    onError: (error) => {
      console.log(error);
      notify('Something went wrong, please try again.', 'error');
    }
  });
};

export default useMutateEditNotification;