import React from 'react';

import {
  formatDistanceToNow,
  parseISO
} from 'date-fns';

import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material'


const TicketParts = (props) => {
  const {
    ticketParts,
    setSelectedTicketPartId,
    isTicketPartChecked,
    setIsTicketPartChecked
  } = props;

  const dateToNow = (date) => {
    const parsed = parseISO(date);
    const toNow = formatDistanceToNow(parsed);
    return toNow;
  };

  const handleChange = (event, id) => {
    if (event.target.checked) {
      setIsTicketPartChecked({
        [id]: true
      });
      setSelectedTicketPartId(id);
    };
    if (!event.target.checked) {
      setIsTicketPartChecked({});
      setSelectedTicketPartId();
    };
  };

  return (
    <>
      <List>
        {ticketParts && ticketParts?.data?.data?.map((ticketPart, index) => (
          <ListItem key={index}>
            <ListItemIcon>
              <Checkbox
                checked={Object.keys(isTicketPartChecked).length > 0 && isTicketPartChecked[ticketPart.id]}
                onChange={event => handleChange(event, ticketPart.id)}
              />
            </ListItemIcon>
            <ListItemText
              primary={`${ticketPart?.quantity}x ${ticketPart?.part?.name}`}
              secondary={`added ${dateToNow(ticketPart.createdAt)} ago`}
            />
          </ListItem>
        ))}
      </List>
    </>
  )
}

export default TicketParts;
