import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

import { apiAddPart } from '../../api/endpoints';

const useMutateAddPart = () => {

  const queryClient = useQueryClient();

  /* Notification */
  const { enqueueSnackbar } = useSnackbar();
  const notify = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant
    });
  };

  return useMutation((variables) => apiAddPart(variables), {
    onSuccess: () => {
      notify('Part added!', 'success');
      queryClient.invalidateQueries('parts');
    },
    onError: (error) => {
      console.log(error);
      notify('Something went wrong, please try again.', 'error');
    }
  });
};

export default useMutateAddPart;