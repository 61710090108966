import React, { useRef, useState } from 'react';

import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Slide,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';

import { useTheme } from '@mui/styles';

import useStyles from './styles';

import {
  useGetCurrentUser,
  useGetAssets,
  useMutateAddComment,
  useMutateEditTicket
} from '../../../hooks';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});


const EditAsset = (props) => {

  const {
    isAssetDialogOpen,
    setIsAssetDialogOpen,
    ticket
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isBreakpointsDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBreakpointsUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const formRef = useRef();

  const [assetId, setAssetId] = useState(ticket?.data?.assetId);

  const currentUser = useGetCurrentUser();
  const assets = useGetAssets();
  const addCommentMutation = useMutateAddComment();
  const editTicketMutation = useMutateEditTicket();

  const editTicket = (assetId) => {
    editTicketMutation.mutate({
      id: ticket?.data?.id,
      body: {
        assetId: assetId
      },
    },
    {
      onSuccess: (data) => {
        ticket.refetch();
        handleCloseDialog(data);
        if (ticket?.data?.type === 'single') {
          // addCommentMutation.mutate({
          //   body: {
          //     text: `Auto-Comment: Ticket updated`,
          //     companyId: currentUser?.data?.companyId,
          //     createdById: currentUser?.data?.id,
          //     ticketId: ticket?.data?.id
          //   }
          // });
        };
      }
    });
  };

  const handleCloseDialog = (data) => {
    setIsAssetDialogOpen(false);
    setAssetId(data.assetId || ticket?.data?.assetId);
  };

  const handleOnSubmit = () => {
    if (formRef.current.reportValidity()) {
      editTicket(assetId);
    }
  }

  return (
    <Dialog
      fullScreen={isBreakpointsDownSm}
      fullWidth={!isBreakpointsDownSm}
      style={{ minWidth: !isBreakpointsDownSm ? 600 : 200 }}
      open={isAssetDialogOpen}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Typography component='h1' variant='h4' align='center'>
          Edit Asset
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <form ref={formRef}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl
                variant='outlined'
                fullWidth
              >
              <Autocomplete
                id='asset-select-recurring-dialog'
                options={assets ? assets?.data?.data?.sort((a, b) => -b.zone.name.localeCompare(a.zone.name)) : []}
                groupBy={(option) => option.zone.name}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => { return (option.id === value.id )}}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    required
                    label='Assets'
                  />
                }
                onChange={(event, value, reason) => {
                  if (reason !== 'clear') {
                    setAssetId(value.id);
                  }
                }}
              />
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          className={classes.button}
          onClick={handleCloseDialog}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          className={classes.button}
          onClick={handleOnSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditAsset;
