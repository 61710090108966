import React, { useState } from 'react';
import { useSnackbar } from 'notistack';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
  Typography,
  useMediaQuery
} from '@mui/material';

import { DropzoneAreaBase } from 'react-mui-dropzone';

import { useTheme } from '@mui/styles';

import useStyles from './styles';

import {
  useGetCurrentUser,
  useMutateAddComment,
  useMutateAddAttachment
} from '../../../hooks';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const NewAttachment = (props) => {

  const {
    isNewAttachmentDialogOpen,
    setIsNewAttachmentDialogOpen,
    ticket
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isBreakpointsDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBreakpointsUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const [fileObjs, setFileObjs] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  const currentUser = useGetCurrentUser();
  const addCommentMutation = useMutateAddComment();
  const addAttachmentMutation = useMutateAddAttachment();

  /* Notification */
  const { enqueueSnackbar } = useSnackbar();
  const notify = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant
    });
  }

  const addAttachment = (data, ticket, user) => {
    data.forEach(f => {
      const formData = new FormData();
      formData.append('companyId', user.companyId);
      formData.append('plantId', ticket?.data?.plantId);
      formData.append('createdById', user.id);
      formData.append('ticketId', ticket?.data?.id);
      formData.append('fileType', f.file.type);
      formData.append('file', f.file);
      addAttachmentMutation.mutate({
        body: formData
      },
      {
        onSuccess: () => {
          // addCommentMutation.mutate({
          //   body: {
          //     text: `Auto-Comment: Attachment added`,
          //     companyId: user.companyId,
          //     createdById: user.id,
          //     ticketId: ticket?.data?.id
          //   }
          // });
        }
      });
    });
  };


  const handleCloseDialog = () => {
    setIsNewAttachmentDialogOpen(false);
    setFileObjs([]);
  };

  const handleOnSubmit = () => {
    if (fileObjs) {
      addAttachment(fileObjs, ticket, currentUser?.data);
      handleCloseDialog();
    };
  };

  return (
    <Dialog
      fullScreen={isBreakpointsDownSm}
      fullWidth={!isBreakpointsDownSm}
      style={{ minWidth: !isBreakpointsDownSm ? 600 : 200 }}
      open={isNewAttachmentDialogOpen}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Typography component='h1' variant='h4' align='center'>
          New Attachment
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography color='textSecondary' className={classes.assetContext}>
              New Attachment File (PNG/JPEG/PDF)
            </Typography>
            <DropzoneAreaBase
              acceptedFiles={['image/png', 'image/jpg', 'image/jpeg', 'application/pdf']}
              fileObjects={fileObjs}
              filesLimit={1}
              maxFileSize={10000000}
              onAlert={(message, variant) => notify(message, variant)}
              showAlerts={false}
              onAdd={(files) => {
                setFileObjs(files);
                setIsUploading(true);
              }}
              onDelete={(files) => {
                setFileObjs([]);
                setIsUploading(false);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          className={classes.button}
          onClick={handleCloseDialog}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          className={classes.button}
          onClick={handleOnSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NewAttachment;
