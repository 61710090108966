import axiosInstance from '../axios.instance';

export const apiGetNotifications = async (variables) => {
  let config = {
    method: 'get',
    url: '/notifications',
    params: variables
  };
  const { data, headers } = await axiosInstance(config);
  return {
    data,
    headers
  };
};

export const apiGetNotification = async (variables) => {
  let config = {
    method: 'get',
    url: `/notifications/${variables.id}`
  };
  const response = await axiosInstance(config);
  return response.data;
};

export const apiEditNotification = async (variables) => {
  let config = {
    method: 'put',
    url: `/notifications/${variables.id}`,
    data: variables.body
  };
  const response = await axiosInstance(config);
  return response.data;
};

export const apiEditManyNotifications = async (variables) => {
  let config = {
    method: 'put',
    url: `/notifications`,
    data: variables.body
  };
  const response = await axiosInstance(config);
  return response.data;
};