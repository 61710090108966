import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import { styled, useTheme } from '@mui/material/styles';

import {
  Drawer as MuiDrawer,
  AppBar as MuiAppBar,
  CssBaseline,
  Badge,
  Box,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  Container,
  Avatar,
  MenuItem,
  Menu,
  Stack,
  Tooltip,
  useMediaQuery
} from '@mui/material';

import {
  ChevronLeft as ChevronLeftIcon,
  CircleNotifications as CircleNotificationsIcon,
  Info as InfoIcon,
  Logout as LogoutIcon,
  Menu as MenuIcon,
  Settings as SettingsIcon,
  ArrowCircleLeft as ArrowCircleLeftIcon,
  ArrowCircleRight as ArrowCircleRightIcon,
  ArrowCircleLeft
} from '@mui/icons-material';

import Copyright from './Copyright';
import MenuListItems from '../../routes/MenuListItems';
import Breadcrumbs from './Breadcrumb';

import NotificationSound from '../../notification-sound.mp3';

import {
  useAuth,
  useGetCurrentUser,
  useGetInfo,
  useGetNotifications
} from '../../hooks';

import ChangeUserPassword from '../users/dialogs/ChangeUserPassword';
import InfoDialog from '../info/dialogs/InfoDialog';

import Notifications from '../notifications/Notifications';

const drawerWidth = 200;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const Navigation = (props) => {

  const theme = useTheme();
  const isBreakpointsDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBreakpointsUpLg = useMediaQuery(theme.breakpoints.up('md'));
 
  const history = useHistory();

  const audioPlayer = useRef(null);

  const [newNotificationsCount, setNewNotificationsCount] = useState();

  const [open, setOpen] = useState(false);
  const [avatarMenuAnchorEl, setAvatarMenuAnchorEl] = useState(null);
  const [notificationsMenuAnchorEl, setNotificationsMenuAnchorEl] = useState(null);
  const notificationsMenuOpen = Boolean(notificationsMenuAnchorEl);
  const avatarMenuOpen = Boolean(avatarMenuAnchorEl);

  const [isChangeUserPasswordDialogOpen, setIsChangeUserPasswordDialogOpen] = useState(false);
  const [isInfoDialogOpen, setIsInfoDialogOpen] = useState(false);

  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const container = window !== undefined ? () => window().document.body : undefined;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const newNotificationsOnSuccess = (data) => {
    setNewNotificationsCount(data.headers['x-total-count'].toString());
  };

  const newNotifications = useGetNotifications({ channel: 'web', status: 'new' }, newNotificationsOnSuccess);

  const auth = useAuth();
  const currentUser = useGetCurrentUser();
  const info = useGetInfo();

  const playAudio = () => {
    audioPlayer.current.play();
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleAvatarMenu = (event) => {
    setAvatarMenuAnchorEl(event.currentTarget);
  };

  const handleAvatarMenuClose = () => {
    setAvatarMenuAnchorEl(null);
  };

  const handleNotificationsMenu = (event) => {
    setNotificationsMenuAnchorEl(event.currentTarget);
  };

  const handleNotificationsMenuClose = () => {
    setNotificationsMenuAnchorEl(null);
  };

  const handleInfoDialog = () => {
    handleAvatarMenuClose();
    setIsInfoDialogOpen(true);
  };

  const handleChangePassword = () => {
    handleAvatarMenuClose();
    setIsChangeUserPasswordDialogOpen(true);
  };

  const renderAvatarMenu = (
    <Menu
      id="menu-appbar"
      anchorEl={avatarMenuAnchorEl}
      open={avatarMenuOpen}
      onClose={handleAvatarMenuClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
    >
      <MenuItem disabled>
        <Typography variant='h6'>
          <b>{currentUser?.data?.firstname} {currentUser?.data?.lastname}</b>
        </Typography>
      </MenuItem>

      <MenuItem disabled>
        <Typography variant='h7'>
          {currentUser?.data?.role}
        </Typography>
      </MenuItem>

      <Divider sx={{ my: 0.5 }} />

      <MenuItem onClick={() => handleChangePassword()}>
        <SettingsIcon />
        Change Password
      </MenuItem>

      <MenuItem onClick={() => handleInfoDialog()}>
        <InfoIcon />
        About
      </MenuItem>

      <Divider sx={{ my: 0.5 }} />

      <MenuItem onClick={() => auth.removeSession()}>
        <LogoutIcon />
        Logout
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <MuiAppBar 
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
        // sx={{
        //   width: { sm: `calc(100% - ${drawerWidth}px)` },
        //   ml: { sm: `${drawerWidth}px` },
        // }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            // sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            color="inherit"
            noWrap
            component="div"
            sx={{ flexGrow: 1 }}
          >
            {isBreakpointsDownSm ? `FMPro` : `Factory Maintenance Pro`}
          </Typography>

          <Stack direction="row" spacing={0}>
            <IconButton
              size="large"
              aria-label="go back"
              onClick={() => history.goBack()}
              color="inherit"
            >
              <ArrowCircleLeftIcon />
            </IconButton>
            <IconButton
              size="large"
              aria-label="go forward"
              onClick={() => history.goForward()}
              color="inherit"
            >
              <ArrowCircleRightIcon />
            </IconButton>
          </Stack>

          <Stack direction="row" spacing={1}>

            {/* Notifications menu */}
            <IconButton
              size="large"
              aria-label="show notifications"
              aria-controls="menu-notifications"
              aria-haspopup="true"
              onClick={handleNotificationsMenu}
              color="inherit"
            >
              <Badge badgeContent={newNotificationsCount > 0 ? newNotificationsCount : null} color="primary">
                <CircleNotificationsIcon color="action" fontSize="large" />
              </Badge>
            </IconButton>

            {/* Profile menu */}
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleAvatarMenu}
              color="inherit"
            >
              <Avatar
                sx={{ width: 32, height: 32, bgcolor: 'white' }}
                alt={`${currentUser?.data?.firstname.charAt(0)} ${currentUser?.data?.lastname.charAt(0)}`}
                src={currentUser.data?.imgUrl}
              >
              </Avatar>
            </IconButton>
          </Stack>

        </Toolbar>
      </MuiAppBar>

      {renderAvatarMenu}
      <Notifications
        anchorEl={notificationsMenuAnchorEl}
        menuOpen={notificationsMenuOpen}
        menuOnClose={handleNotificationsMenuClose}
        newNotifications={newNotifications}
      />
      
      {/* <Box */}
      {/*   component="nav" */}
      {/*   sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} */}
      {/* > */}
      <MuiDrawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {/* <DrawerHeader> */}
        {/*   <IconButton onClick={handleDrawerClose}> */}
        {/*     <ChevronLeftIcon /> */}
        {/*   </IconButton> */}
        {/* </DrawerHeader> */}
        <Toolbar />
        <Divider />
        <List>
          <MenuListItems closeDrawer={handleDrawerToggle} />
        </List>
        <Divider />
      </MuiDrawer>
      {/* <MuiDrawer */}
      {/*   variant="permanent" */}
      {/*   sx={{ */}
      {/*     display: { xs: 'none', sm: 'block' }, */}
      {/*     '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }, */}
      {/*   }} */}
      {/*   open */}
      {/* > */}
      {/*   <Toolbar /> */}
      {/*   <Divider /> */}
      {/*   <List>{menuListItems}</List> */}
      {/*   <Divider /> */}
      {/* </MuiDrawer> */}

      <Drawer 
        variant="permanent"
        open={mobileOpen}
        sx={{
          display: { xs: 'none', sm: 'block'}
        }}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>

        <Divider />
        <List>
          <MenuListItems closeDrawer={handleDrawerClose} />
        </List>
        <Divider />

      </Drawer>
      {/* </Box> */}

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Container maxWidth="xl">
          <Breadcrumbs />
            <Box pt={3}>
              {props.children}
            </Box>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </Box>

      <ChangeUserPassword
        isDialogOpen={isChangeUserPasswordDialogOpen}
        setIsDialogOpen={setIsChangeUserPasswordDialogOpen}
        user={currentUser?.data}
      />

      <InfoDialog
        isDialogOpen={isInfoDialogOpen}
        setIsDialogOpen={setIsInfoDialogOpen}
        info={info}
      />

    </Box>
  );
}

Navigation.propTypes = {
  children: PropTypes.node,
};

export default Navigation;
