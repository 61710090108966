import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';

import { apiGetTicketParts } from '../../api/endpoints';

const useGetTicketParts = (variables, onSuccess) => {

  /* Notification */
  const { enqueueSnackbar } = useSnackbar();
  const notify = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant
    });
  };

  return useQuery(['parts', variables], () => apiGetTicketParts(variables), {
    onSuccess: onSuccess,
    onError: (error) => {
      notify('Something went wrong, please try again.', 'error');
    }
  });
};

export default useGetTicketParts;
