import React from 'react';
import { Grid } from '@mui/material';
import PlantsTable from '../../components/plants/PlantsTable';

const PlantsPage = () => {

  return (
    <Grid container spacing={3}>

      <Grid item xs>
        <PlantsTable
          tableHeight={650} />
      </Grid>
    </Grid>
  )
}

export default PlantsPage;
