import React, { useRef, useState } from 'react';

import {
  Autocomplete,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Paper,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';

import { useTheme } from '@mui/styles';

import { Pannellum } from 'pannellum-react';

import {
  useGetZone,
  useMutateEditAsset
} from '../../hooks';

const PlantMapPano = (props) => {
  const {
    assets,
    dialogMode,
    setDialogMode,
    selectedZone,
    setSelectedZone,
    zoneId,
    isAuthorized
  } = props;

  const theme = useTheme();
  const isBreakpointsDownXs = useMediaQuery(theme.breakpoints.down('xs'));

  const [yaw, setYaw] = useState(0);
  const [pitch, setPitch] = useState(0);
  const [selectedAsset, setSelectedAsset] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const panoRef = useRef(null);

  const zoneOnSuccess = (data) => {
    setSelectedZone(data);
  }

  const zone = useGetZone({ id: zoneId }, zoneOnSuccess);
  const editAssetMutation = useMutateEditAsset();

  const onPanoramaLoaded = () => {
    console.log('*** Panorama loaded ***');
  };

/*
  const handleChange = (event) => {
    setSelectedAsset(Number(event.target.value) || '');
  };
*/

  const handleClickOpen = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

/*
  const drawPoint = () => {
    const canvas = document.querySelector('#pannellum');
    console.log(canvas);
    if (canvas.getContext) {
      var ctx = canvas.getContext('2d');
      ctx.beginPath();
      ctx.arc(75, 75, 50, 0, Math.PI * 2, true); // Outer circle
      ctx.moveTo(110, 75);
      ctx.arc(75, 75, 35, 0, Math.PI, false);  // Mouth (clockwise)
      ctx.moveTo(65, 65);
      ctx.arc(60, 65, 5, 0, Math.PI * 2, true);  // Left eye
      ctx.moveTo(95, 65);
      ctx.arc(90, 65, 5, 0, Math.PI * 2, true);  // Right eye
      console.log(ctx);
    }
  }

  let customTooltip = (hotSpotDiv, args) => {
    hotSpotDiv.classList.add('custom-tooltip');
    var span = document.createElement('span');
    span.innerHTML = args;
    hotSpotDiv.appendChild(span);
    span.style.color = 'white';
    span.style.width = span.scrollWidth - 20 + 'px';
  }
*/

  const renderHotspots = (zone) => {
    if (JSON.stringify(zone) !== '{}') {
      return (
        zone?.assets?.map((asset, index) => (
          <Pannellum.Hotspot
            type='info'
            pitch={asset.pitch}
            yaw={asset.yaw}
            text={asset.name}
            URL={`/assets/${asset.id}`}
            key={index}
          />
        ))
      )
    }
  }

  const options = assets && assets.map((option) => {
    const firstLetter = option.name[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });


  return (
    <>
      <Pannellum
        ref={panoRef}
        id='pannellum'
        image={zone?.data?.imgUrl}
        autoRotate={-2}
        autoLoad={true}
        pitch={0}
        yaw={0}
        hfov={90}
        title={zone?.data?.name}
        style={{
          width: '100%',
          height: '600px',
        }}
        onLoad={onPanoramaLoaded}
        onMouseup={(event)=> {
          setPitch(panoRef.current.getViewer().mouseEventToCoords(event)[0]);
          setYaw(panoRef.current.getViewer().mouseEventToCoords(event)[1]);
        }}
      >
        {renderHotspots(zone.data)}
      </Pannellum>

      {isAuthorized && dialogMode === 'hotspot' && (
        <>
          <Box display='flex' p={1}>
            <Box p={1} flexGrow={1}>
              <Typography gutterBottom>
                Click on the photo to choose the hotspot position, then press ADD.
              </Typography>
              <Paper variant='outlined'>
                <Box display='flex' p={1}>
                  <Box p={1} flexGrow={1}>
                    <Typography variant='body2' gutterBottom>
                      New Hotspot position:
                    </Typography>
                  </Box>
                  <Box p={1}>
                    <Typography variant='body2' gutterBottom>
                      {pitch !== 0 ? `Pitch: ${pitch}` : null}
                    </Typography>
                    <Typography variant='body2' gutterBottom>
                      {yaw !== 0 ? `Yaw: ${yaw}` : null}
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Box>
            <Box p={1}>
              <Button
                variant='outlined'
                size='small'
                onClick={() => {
                  setDialogMode(null);
                  setPitch(0);
                  setYaw(0);
                }}
              >
                cancel
              </Button>
            </Box>
            {pitch !== 0 && yaw !== 0 &&
              <Box p={1}>
                <Button
                  variant='outlined'
                  size='small'
                  onClick={handleClickOpen}
                >
                  Add
                </Button>
              </Box>
            }
          </Box>
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            open={isDialogOpen}
            onClose={handleClose}
          >
            <DialogTitle>
              <Typography component='h1' variant='h4' align='center'>
                Add Hotspot to {zone?.data?.name}
              </Typography>
            </DialogTitle>
            <DialogContent style={{ minWidth: isBreakpointsDownXs ? 800 : theme.breakpoints.values.sm }}>
              <Box display='flex' p={1}>
                <Box p={1} flexGrow={1}>
                  <Card variant='outlined'>
                    <Typography variant='h6' gutterBottom>
                      Position:
                    </Typography>
                    <Typography variant='subtitle1' gutterBottom>
                      {pitch !== 0 ? `Pitch: ${pitch}` : null}
                    </Typography>
                    <Typography variant='subtitle1' gutterBottom>
                      {yaw !== 0 ? `Yaw: ${yaw}` : null}
                    </Typography>
                  </Card>
                </Box>
                <Box p={1}>
                  <Card variant='outlined'>
                    <Typography variant='h6' gutterBottom>
                      Associate a asset:
                    </Typography>
                    <FormControl
                      variant='outlined'
                      style={{ minWidth: 280, maxWidth: 400 }}
                    >
                      <Autocomplete
                        id='asset-select-helper-complete'
                        options={assets ? options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter)) : []}
                        groupBy={(option) => option.firstLetter}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) => { return (option.id === value.id )}}
                        renderInput={(params) =>
                          <TextField
                            {...params}
                            required
                            label='Plant Assets'
                          />
                        }
                        onChange={(event, value, reason) => {
                          if (reason !== 'clear') {
                            setSelectedAsset(value);
                          }
                        }}
                      />
                    </FormControl>
                  </Card>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions style={{ padding: '8px 24px 24px' }}>
              <Button
                onClick={() => {
                  setSelectedAsset('');
                  setIsDialogOpen(false)
                }}
              >
                cancel
              </Button>
              <Button
                type='submit'
                variant='contained'
                onClick={() => {
                  editAssetMutation.mutate({
                    id: selectedAsset.id,
                    body: {
                      zoneId: zoneId,
                      pitch: pitch,
                      yaw: yaw
                    }
                  },
                  {
                    onSuccess: () => {
                     zone.refetch();
                     setIsDialogOpen(false);
                     setSelectedAsset('');
                     setDialogMode(null);
                     setPitch(0);
                     setYaw(0);
                    }
                  });
                }}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}

export default PlantMapPano;
