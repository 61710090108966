import axios from 'axios';

const getLocalAccessToken = () => {
  const accessToken = localStorage.getItem('accessToken');
  return accessToken;
};

const getLocalRefreshToken = () => {
  const refreshToken = localStorage.getItem('refreshToken');
  return refreshToken;
};

const baseURL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: baseURL
});


axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = getLocalAccessToken();
    console.log(`*** ${JSON.stringify(config.method, null, 2).toUpperCase()} ${JSON.stringify(config.url, null, 2)} ***`);
    if (accessToken) {
      return {
        ...config,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalConfig = error.config;
    if (error.response) {
      // Access Token expired
      const accessToken = getLocalAccessToken();
      const refreshToken = getLocalRefreshToken();
      if (error.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        if (accessToken && refreshToken) {
          fetch(`${baseURL}/refresh`, {
            method: 'POST',
            cache: 'no-cache',
            mode: 'cors',
            headers: {
              'Authorization': `Bearer ${refreshToken}`
            }
          })
          .then(res => {
            console.log('*** Refreshing token ***');
            if (!res.ok) {
              return res.json().then(json => { throw json; });
            };
            return res.json()
          })
          .then(res => {
            localStorage.setItem('accessToken', res.accessToken);
            axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${res.accessToken}`;
            console.log('*** Done ***');
            return Promise.resolve((resolve) => {
              resolve(axiosInstance(originalConfig));
            });
          })
          .catch(err => {
            console.log('*** Refreshing not authorized. Redirecting to /login ***');
            localStorage.setItem('isLoggedIn', false);
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            window.location = '/login';
          });
        };
      // } else {
      //   if (error.response.config.url !== '/api/v1/login') {
      //     console.log('*** Redirecting to /login ***');
      //     localStorage.setItem('isLoggedIn', false);
      //     localStorage.removeItem('accessToken');
      //     localStorage.removeItem('refreshToken');
      //     window.location = '/login';
      //   };
      };
    };
  }
);

export default axiosInstance;