import React, { useRef, useState } from 'react';

import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Slide,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';

import { useTheme } from '@mui/styles';

import useStyles from './styles';

import {
  useGetCurrentUser,
  useGetUsers,
  useMutateAddComment,
  useMutateEditTicket
} from '../../../hooks';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});


const EditAssignee = (props) => {

  const {
    isAssigneeDialogOpen,
    setIsAssigneeDialogOpen,
    ticket
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isBreakpointsDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBreakpointsUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const formRef = useRef();

  const [assignedToId, setAssignedToId] = useState(ticket?.data?.assignedToId);

  const currentUser = useGetCurrentUser();
  const users = useGetUsers();
  const addCommentMutation = useMutateAddComment();
  const editTicketMutation = useMutateEditTicket();

  const editTicket = (assignedToId) => {
    editTicketMutation.mutate({
      id: ticket?.data?.id,
      body: {
        assignedToId: assignedToId
      },
    },
    {
      onSuccess: (data) => {
        ticket.refetch();
        handleCloseDialog(data);
        if (ticket?.data?.type === 'single') {
          // addCommentMutation.mutate({
          //   body: {
          //     text: `Auto-Comment: Ticket updated`,
          //     companyId: currentUser?.data?.companyId,
          //     createdById: currentUser?.data?.id,
          //     ticketId: ticket?.data?.id
          //   }
          // });
        };
      }
    });
  };

  const handleCloseDialog = (data) => {
    setIsAssigneeDialogOpen(false);
    setAssignedToId(data.assignedToId || ticket?.data?.assignedToId);
  };

  const handleOnSubmit = () => {
    if (formRef.current.reportValidity()) {
      editTicket(assignedToId);
    }
  }

  return (
    <Dialog
      fullScreen={isBreakpointsDownSm}
      fullWidth={!isBreakpointsDownSm}
      style={{ minWidth: !isBreakpointsDownSm ? 600 : 200 }}
      open={isAssigneeDialogOpen}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Typography component='h1' variant='h4' align='center'>
          New Assignee
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <form ref={formRef}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl
                variant='outlined'
                fullWidth
              >
                <Autocomplete
                  id='user-assignee-ticket-dialog'
                  options={users ? users.data?.data : []}
                  getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
                  getOptionSelected={(option, value) => { return (option.id === value.id )}}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      required
                      label='Assigned To'
                    />
                  }
                  onChange={(event, value, reason) => {
                    if (reason !== 'clear') {
                      setAssignedToId(value);
                    }
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          className={classes.button}
          onClick={handleCloseDialog}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          className={classes.button}
          onClick={handleOnSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditAssignee;
