import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide
} from '@mui/material'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InfoDialog = (props) => {
  const {
    isDialogOpen,
    setIsDialogOpen,
    info
  } = props;

  return (
    <div>
      <Dialog
        open={isDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => { setIsDialogOpen(false) }}
        aria-describedby="info-dialog-description"
      >
        <DialogTitle>About</DialogTitle>
        <DialogContent>
          <DialogContentText id="info-dialog-description">
            API service version {info?.data?.version}
          </DialogContentText>
          <DialogContentText id="info-dialog-description">
            UI version {process.env.REACT_APP_VERSION}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setIsDialogOpen(false) }}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InfoDialog;