import axiosInstance from '../axios.instance';

export const apiAddPart = async (variables) => {
  let config = {
    method: 'post',
    url: `/parts`,
    data: variables.body
  };
  const response = await axiosInstance(config);
  return response.data;
};

export const apiGetParts = async (variables) => {
  let config = {
    method: 'get',
    url: '/parts',
    params: variables
  };
  const { data, headers } = await axiosInstance(config);
  return {
    data,
    headers
  };
};

export const apiGetPart = async (variables) => {
  let config = {
    method: 'get',
    url: `/parts/${variables.id}`
  };
  const response = await axiosInstance(config);
  return response.data;
};

export const apiEditPart = async (variables) => {
  let config = {
    method: 'put',
    url: `/parts/${variables.id}`,
    data: variables.body
  };
  const response = await axiosInstance(config);
  return response.data;
};

export const apiDelPart = async (variables) => {
  let config = {
    method: 'delete',
    url: `/parts/${variables.id}`
  }
  const response = await axiosInstance(config);
  return response.data;
};