import React, { useState } from 'react';
import {
  Autocomplete,
  FormControl,
  Grid,
  TextField,
  Typography
} from '@mui/material';

const DetailsForm = (props) => {
  const {
    plants,
    zones,
    plantId,
    setPlantId,
    zoneId,
    setZoneId,
  } = props;

  const options = (array) => {
    if (array) {
      const mappedData = array.map((option) => {
        const firstLetter = option.name[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
          ...option,
        };
      });
      return mappedData.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter));
    };
  };

  return (
    <>
      <Typography variant='h6' gutterBottom>
        Asset associations
      </Typography>
      <Grid container spacing={3}>

        {/* Plant */}
        <Grid item xs={12} sm={12}>
          <FormControl
            variant='outlined'
            fullWidth
          >
            <Autocomplete
              id='plant-select-asset-wizard'
              options={plants ? options(plants) : []}
              groupBy={(option) => option.firstLetter}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => { return (option.id === value.id )}}
              renderInput={(params) =>
                <TextField
                  {...params}
                  required
                  label='Plant'
                />
              }
              onChange={(event, value, reason) => {
                if (reason !== 'clear') {
                  setPlantId(value.id);
                }
              }}
            />
          </FormControl>
        </Grid>

        {/* Zone */}
        <Grid item xs={12} sm={12}>
          <FormControl
            variant='outlined'
            fullWidth
          >
            <Autocomplete
              id='zone-select-asset-wizard'
              options={zones ? options(zones) : []}
              groupBy={(option) => option.firstLetter}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => { return (option.id === value.id )}}
              renderInput={(params) =>
                <TextField
                  {...params}
                  required
                  label='Zone'
                />
              }
              onChange={(event, value, reason) => {
                if (reason !== 'clear') {
                  setZoneId(value.id);
                }
              }}
            />
          </FormControl>
        </Grid>

      </Grid>
    </>
  );
}

export default DetailsForm;
