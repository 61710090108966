import React, { useEffect, useRef, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';

import { useTheme } from '@mui/styles';

import useStyles from './styles';

import {
  useGetCurrentUser,
  useMutateEditPart
} from '../../../hooks';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});


const EditPart = (props) => {

  const {
    isDialogOpen,
    setIsDialogOpen,
    setSelectedItem,
    setSelectionModel,
    part
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isBreakpointsDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBreakpointsUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const formRef = useRef();

  const [name, setName] = useState(part?.name);
  const [description, setDescription] = useState(part?.description);
  const [quantity, setQuantity] = useState(part?.quantity);
  const [cost, setCost] = useState(part?.cost);

  const editPartMutation = useMutateEditPart();

  const editPart = (name, description, quantity, cost) => {
    editPartMutation.mutate({
      id: part?.id,
      body: {
        name: name,
        description: description,
        quantity: quantity,
        cost: cost
      }
    },
    {
      onSuccess: (data) => {
        handleCloseDialog(data);
      }
    });
  };

  const handleCloseDialog = (data) => {
    setIsDialogOpen(false);
    setSelectedItem();
    setSelectionModel([]);
    setName(data.name || part?.name);
    setDescription(data.description || part?.description);
    setQuantity(data.quantity || part?.quantity);
    setCost(data.cost || part?.cost)
  };

  const handleOnSubmit = () => {
    if (formRef.current.reportValidity()) {
      editPart(name, description, quantity, cost);
    }
  };

  useEffect(() => {
    setName(part?.name);
    setDescription(part?.description);
    setQuantity(part?.quantity);
    setCost(part?.cost);
  }, [part]);

  return (
    <Dialog
      fullScreen={isBreakpointsDownSm}
      fullWidth={!isBreakpointsDownSm}
      style={{ minWidth: !isBreakpointsDownSm ? 600 : 200 }}
      open={isDialogOpen}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Typography component='h1' variant='h4' align='center'>
          Edit Part
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <form ref={formRef}>
          <Grid container spacing={3}>

            {/* name */}
            <Grid item xs={12}>
              <TextField
                required
                id='name'
                label='Name'
                variant='outlined'
                fullWidth
                value={name ? name : ''}
                onChange={(event) => { setName(event.target.value) }}
              />
            </Grid>

            {/* description */}
            <Grid item xs={12}>
              <TextField
                required
                multiline
                rows={6}
                id='description'
                label='Description'
                variant='outlined'
                fullWidth
                value={description ? description : ''}
                onChange={(event) => { setDescription(event.target.value) }}
              />
            </Grid>

            {/* quantity */}
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                id="quantity"
                label="Quantity"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                value={quantity > -1 ? quantity : ''}
                onChange={(event) => { setQuantity(event.target.value) }}
              />
            </Grid>

            {/* cost */}
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                id="cost"
                label="Cost"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                value={cost ? cost : ''}
                onChange={(event) => { setCost(event.target.value) }}
              />
            </Grid>

          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          className={classes.button}
          onClick={handleCloseDialog}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          className={classes.button}
          onClick={handleOnSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditPart;
