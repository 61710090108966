import React from 'react';
import {
  Autocomplete,
  FormControl,
  Grid,
  TextField,
  Typography
} from '@mui/material';

import useStyles from './styles';

const DetailsForm = (props) => {
  const {
    users,
    setAssignedToId,
  } = props;

  return (
    <>
      <Typography variant='h6' gutterBottom>
        Assignee details
      </Typography>
      <Grid container spacing={3}>

        { /* Assignee */}
        <Grid item xs={12} sm={12}>
        <FormControl
          variant='outlined'
          fullWidth
        >
          <Autocomplete
            id='user-select-ticket-wizard'
            options={users ? users.data?.data : []}
            getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
            getOptionSelected={(option, value) => { return (option.id === value.id )}}
            renderInput={(params) =>
              <TextField
                {...params}
                required
                label='Assigned To'
              />
            }
            onChange={(event, value, reason) => {
              if (reason !== 'clear') {
                setAssignedToId(value.id);
              }
            }}
          />
        </FormControl>
        </Grid>

      </Grid>
    </>
  );
}

export default DetailsForm;
