import React, { useEffect, useRef, useState } from 'react';

import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Slide,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';

import { useTheme } from '@mui/styles';

import useStyles from './styles';

import {
  useGetPlants,
  useMutateEditZone
} from '../../../hooks';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});


const EditZone = (props) => {

  const {
    isDialogOpen,
    setIsDialogOpen,
    setSelectedItem,
    setSelectionModel,
    zone
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isBreakpointsDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBreakpointsUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const formRef = useRef();

  const [name, setName] = useState(zone?.name);
  const [description, setDescription] = useState(zone?.description);
  const [plantId, setPlantId] = useState(zone?.plantId);

  const plants = useGetPlants();
  const editZoneMutation = useMutateEditZone();

  const options = (array) => {
    if (array) {
      const mappedData = array.map((option) => {
        const firstLetter = option.name[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
          ...option,
        };
      });
      return mappedData.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter));
    };
  };

  const editZone = (zoneId) => {
    editZoneMutation.mutate({
      id: zoneId,
      body: {
        name: name,
        description: description,
        plantId: plantId
      },
    },
    {
      onSuccess: () => {
        handleCloseDialog();
      }
    });
  };

  const handleCloseDialog = (data) => {
    setIsDialogOpen(false);
    setName();
    setDescription();
    setPlantId();
    setSelectedItem();
    setSelectionModel([])
  };

  const handleOnSubmit = () => {
    if (formRef.current.reportValidity()) {
      editZone(zone?.id);
    };
  };

  useEffect(() => {
    setName(zone?.name);
    setDescription(zone?.description);
  }, [zone]);

  return (
    <Dialog
      fullScreen={isBreakpointsDownSm}
      fullWidth={!isBreakpointsDownSm}
      style={{ minWidth: !isBreakpointsDownSm ? 600 : 200 }}
      open={isDialogOpen}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Typography component='h1' variant='h4' align='center'>
          Edit Zone
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <form ref={formRef}>
          <Grid container spacing={3}>

            {/* Name */}
            <Grid item xs={12}>
              <TextField
                required
                id='name'
                label='Name'
                variant='outlined'
                fullWidth
                value={name ? name : ''}
                onChange={(event) => { setName(event.target.value) }}
              />
            </Grid>

            {/* Description */}
            <Grid item xs={12}>
              <TextField
                required
                multiline
                rows={6}
                id='description'
                label='Description'
                variant='outlined'
                fullWidth
                value={description ? description : ''}
                onChange={(event) => { setDescription(event.target.value) }}
              />
            </Grid>

            {/* Plant */}
            <Grid item xs={12} sm={12}>
              <FormControl
                variant='outlined'
                fullWidth
              >
                <Autocomplete
                  id='plant-select-zone-wizard'
                  options={plants ? options(plants?.data?.data) : []}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => { return (option.id === value.id )}}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      required
                      label='Plant'
                    />
                  }
                  onChange={(event, value, reason) => {
                    if (reason !== 'clear') {
                      setPlantId(value.id);
                    }
                  }}
                />
              </FormControl>
            </Grid>

          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          className={classes.button}
          onClick={handleCloseDialog}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          className={classes.button}
          onClick={handleOnSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditZone;
