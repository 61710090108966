import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  formatDistanceToNow,
  parseISO
} from 'date-fns';

import {
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip
} from '@mui/material';

import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';

const dateToNow = (date) => {
  const parsed = parseISO(date);
  const toNow = formatDistanceToNow(parsed);
  return toNow;
};

const Item = (props) => {
  const {
    id,
    index,
    setSelectedExtId,
    isExtChecked,
    setIsExtChecked,
    extOperator,
    ticket
  } = props;

  const [createdAt, setCreatedAt] = useState();

  const handleChange = (event) => {
    if (event.target.checked) {
      setIsExtChecked({
        [id]: true
      });
      setSelectedExtId(id);
    };
    if (!event.target.checked) {
      setIsExtChecked({});
      setSelectedExtId();
    };
  };


  return (
    <>
      <ListItem
        key={index}
      >
        <ListItemIcon>
          <Checkbox
            checked={Object.keys(isExtChecked).length > 0 && isExtChecked[id]}
            onChange={handleChange}
            disabled={ticket?.data?.status === 'solved'}
          />
        </ListItemIcon>
        <ListItemText
          primary={extOperator?.phoneNumber ? `${extOperator?.firstname} ${extOperator?.lastname} (${extOperator?.phoneNumber})`
            : `${extOperator?.firstname} ${extOperator?.lastname}`}
          secondary={extOperator?.organization}
        />
        {extOperator?.phoneNumber && (
          <ListItemSecondaryAction>
            <Tooltip title='Call number' placement='bottom' arrow>
              <IconButton
                edge='end'
                href={`tel:${extOperator?.phoneNumber.replace(/[+\s-]/g, '')}`}
                aria-label='Call'
              >
                <PhoneIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Open with WhatsApp' placement='bottom' arrow>
              <IconButton
                edge='end'
                href={`https://wa.me/${extOperator?.phoneNumber.replace(/[+\s-]/g, '')}`}
                target='_blank'
                rel='noopener noreferrer'
                aria-label='WhatsApp'
              >
                <WhatsAppIcon />
              </IconButton>
            </Tooltip>
            {/* <Tooltip title='Open with Telegram' placement='bottom' arrow> */}
            {/*   <IconButton */}
            {/*     edge='end' */}
            {/*     href={`https://t.me/${extOperator?.phoneNumber.replace(/[+\s-]/g, '')}`} */}
            {/*     target='_blank' */}
            {/*     rel='noopener noreferrer' */}
            {/*     aria-label='Telegram' */}
            {/*   > */}
            {/*     <TelegramIcon /> */}
            {/*   </IconButton> */}
            {/* </Tooltip> */}
          </ListItemSecondaryAction>
        )}
      </ListItem>
    </>
  )
}

const ExtOperators = (props) => {
  const {
    setSelectedExtId,
    isExtChecked,
    setIsExtChecked,
    ticket
  } = props;

  return (
    <>
      {ticket && ticket?.data?.extOperators?.map((extOperator, index) => (
        <List dense={true}>
          <Item
            id={extOperator.id}
            index={index}
            setSelectedExtId={setSelectedExtId}
            isExtChecked={isExtChecked}
            setIsExtChecked={setIsExtChecked}
            extOperator={extOperator}
            ticket={ticket}
          />
        </List>
      ))}
    </>
  );
}

export default ExtOperators;
