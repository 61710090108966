import React from 'react';

import { useHistory } from 'react-router-dom';

import {
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
  MenuItem,
  Menu
} from '@mui/material';

import {
  formatDistanceToNow,
  parseISO
} from 'date-fns';

import {
  Article as ArticleIcon
} from '@mui/icons-material';

import {
  useGetNotifications,
  useMutateEditNotification,
  useMutateEditManyNotifications
} from '../../hooks';

const Notifications = (props) => {

  const {
    anchorEl,
    menuOpen,
    menuOnClose,
    newNotifications
  } = props;

  const history = useHistory();

  const notifications = useGetNotifications();
  const editNotificationMutation = useMutateEditNotification();
  const editManyNotificationsMutation = useMutateEditManyNotifications();

  const editNotification = (id) => {
    editNotificationMutation.mutate({
      id: id,
      body: {
        status: 'read'
      }
    },
    {
      onSuccess: () => {
        newNotifications.remove();
        notifications.remove();
      }
    })
  };

  const markAllAsRead = () => {
    editManyNotificationsMutation.mutate({
      body: {
        status: 'read'
      }
    },
    {
      onSuccess: () => {
        newNotifications.remove();
        notifications.remove();
      }
    })
  };


  return (
    <Menu
      id="menu-notifications"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          width: 460,
          maxHeight: 560,
          //overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      keepMounted
      open={menuOpen}
      onClose={menuOnClose}
    >
      <MenuItem disabled>
        <Typography variant="h6">
          <b>Notifications</b>
        </Typography>
      </MenuItem>

      {notifications && newNotifications?.data?.data.length > 0 &&
        <Box
          m={1}
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Button
            size="small"
            variant="text"
            sx={{ color: '#1876f2' }}
            onClick={markAllAsRead}
          >
            Mark all as read
          </Button>
        </Box>
      }
      <Divider sx={{ my: 0.5 }} />

      {notifications && notifications?.data?.data?.map((notification, index) => (
        notification.channel === 'web' ? (
          <div>
            <MenuItem
              key={notification.id}
              onClick={() => {
                history.push(`/tickets/${notification.ticketId}`);
                editNotification(notification.id);
                menuOnClose();
              }}
            >

              {notification.type === 'ticket' &&
                <ListItemIcon>
                  <ArticleIcon fontSize="small" />
                </ListItemIcon>
              }

              <ListItemText sx={{ whiteSpace: 'normal' }}>
                {notification.text}
                {notification.status === 'new' ?
                  <Typography variant="body2" sx={{ color: '#1876f2' }}>
                    {`${formatDistanceToNow(parseISO(notification.createdAt))} ago `}
                  </Typography>
                :
                  <Typography variant="body2">
                    {`${formatDistanceToNow(parseISO(notification.createdAt))} ago `}
                  </Typography>
                }
              </ListItemText>

              {notification.status === 'new' &&
                <Typography variant="body2" sx={{ color: '#1876f2' }}>
                  {notification.status}
                </Typography>
              }
            </MenuItem>
            <Divider sx={{ my: 0.5 }} />
          </div>
        ) : null
      ))}

    </Menu>
  );
};

export default Notifications;
