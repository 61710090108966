import React, { useRef, useState, useEffect } from 'react';

import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Slide,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';

import { useTheme } from '@mui/styles';

import useStyles from './styles';

import {
  useMutateEditAsset
} from '../../../hooks';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});


const EditUsage = (props) => {

  const {
    isDialogOpen,
    setIsDialogOpen,
    asset
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isBreakpointsDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBreakpointsUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const formRef = useRef();

  const [workingHours, setWorkingHours] = useState(asset?.workingHours);

  const editAssetMutation = useMutateEditAsset();

  const editAsset = (workingHours) => {
    editAssetMutation.mutate({
      id: asset?.id,
      body: {
        workingHours: workingHours,
      }
    },
    {
      onSuccess: (data) => {
        handleCloseDialog(data);
        //asset.refetch();
      }
    });
  };


  const handleCloseDialog = (data) => {
    setIsDialogOpen(false);
    setWorkingHours(data.workingHours || asset?.workingHours);
  };

  const handleOnSubmit = () => {
    if (formRef.current.reportValidity()) {
      editAsset(workingHours);
    }
  };

  useEffect(() => {
    setWorkingHours(asset?.workingHours);
  }, [asset]);

  return (
    <Dialog
      fullScreen={isBreakpointsDownSm}
      fullWidth={!isBreakpointsDownSm}
      style={{ minWidth: !isBreakpointsDownSm ? 600 : 200 }}
      open={isDialogOpen}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Typography component='h1' variant='h4' align='center'>
          Edit Usage
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <form ref={formRef}>
          <Grid container spacing={3}>

            {/* Working Hours */}
            <Grid item xs={12}>
              <TextField
                required
                id='workingHours'
                label='Working Hours'
                variant='outlined'
                fullWidth
                value={workingHours}
                onChange={(event) => { setWorkingHours(event.target.value) }}
              />
            </Grid>

          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          className={classes.button}
          onClick={handleCloseDialog}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          className={classes.button}
          onClick={handleOnSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditUsage;
