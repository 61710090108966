import React, { useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Slide,
  Typography,
  useMediaQuery
} from '@mui/material';

import { useTheme } from '@mui/styles';

import useStyles from './styles';

import AlertDialogSlide from '../../alertdialogs/AlertDialogSlide';

import {
  useMutateEditTicket
} from '../../../hooks';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});


const DelExtOperator = (props) => {

  const {
    isDelExtDialogOpen,
    setIsDelExtDialogOpen,
    selectedExtId,
    setSelectedExtId,
    setIsExtChecked,
    ticket
  } = props;


  const [dialogTitle,] = useState('Do you want to remove the operator?');
  const [dialogText,] = useState('This operation will remove the selected external operator from the work order.');

  const editTicketMutation = useMutateEditTicket();

  const delExt  = (extId) => {
    let newArray = ticket?.data?.extOperIds.filter(function (id) {
      return id !== extId;
    });
    editTicketMutation.mutate({
      id: ticket?.data?.id,
      body: {
        extOperIds: newArray
      },
    },
    {
      onSuccess: (data) => {
        ticket.refetch();
        if (ticket?.data?.type === 'single') {
          // addCommentMutation.mutate({
          //   body: {
          //     text: `Auto-Comment: Ticket updated`,
          //     companyId: currentUser?.data?.companyId,
          //     createdById: currentUser?.data?.id,
          //     ticketId: ticket?.data?.id
          //   }
          // });
        };
      }
    });
  };


  const handleDialogAgree = () => {
    if (ticket && selectedExtId) {
      delExt(selectedExtId)
      setIsDelExtDialogOpen(false);
      setSelectedExtId();
      setIsExtChecked({});
    };
  };

  const handleDialogDisagree = () => {
    setIsDelExtDialogOpen(false);
    setSelectedExtId();
    setIsExtChecked({});
  };

  return (
    <AlertDialogSlide
      title={dialogTitle}
      text={dialogText}
      isDialogOpen={isDelExtDialogOpen}
      setIsDialogOpen={setIsDelExtDialogOpen}
      handleAgree={handleDialogAgree}
      handleDisagree={handleDialogDisagree}
    />
  )
}

export default DelExtOperator;
