import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';

import { apiAuth } from '../api/endpoints';
import { useAuth } from '../hooks';

const useMutateLogin = () => {
  const auth = useAuth();

  /* Notification */
  const { enqueueSnackbar } = useSnackbar();
  const notify = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant
    });
  };

  return useMutation((credentials) => apiAuth(credentials), {
    onSuccess: (data) => {
      auth.setSession(data.accessToken, data.refreshToken);
    },
    onError: (error) => {
      notify('Wrong credentials, please try again.', 'error');
    }
  });
};

export default useMutateLogin;