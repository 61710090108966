import React, {
  useState
} from 'react';

import { useHistory, useParams } from 'react-router-dom';
import {
  MapContainer,
  Marker,
  Tooltip,
} from "react-leaflet";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css'
import { PlantMapContainer, StyledImgOverlay } from "../../styles";
import {
  useGetAssets,
  useGetPlant
} from '../../hooks';

/*
const AddMarkerToClick = () => {

  const [markers, setMarkers] = useState([]);

  const map = useMapEvents({
    click(e) {
      const newMarker = e.latlng
      setMarkers([...markers, newMarker]);
    },
  })

  return (
    <>
      {markers.map(marker =>
        <Marker position={marker}>
          <Popup>Marker is at {marker}</Popup>
        </Marker>
      )}
    </>
  )
}

*/

const PlantMapPlanimetry = () => {

  const history = useHistory();
  const routerParams = useParams();

  const plant = useGetPlant({ id: routerParams.id });
  const assets = useGetAssets({ plantId: routerParams.id });

  const [plantUrl,] = useState(plant?.data?.imgUrl);

  const bounds = [[0, 0], [1200, 1200]];

  const handleClick = (assetId) => {
    history.push(`/assets/${assetId}/view`);
  };

  return (
    <>
      <PlantMapContainer>
        <MapContainer
          id='plant-map'
          crs={L.CRS.Simple}
          bounds={bounds}
          zoom={23}
        >
          <StyledImgOverlay bounds={bounds} url={plantUrl}/>
          {assets && assets?.data?.data?.map((asset, key) => (
              asset.posX && asset.posY &&
              <Marker
                position={[asset.posX, asset.posY]}
                eventHandlers={{
                  click: () => {
                    handleClick(asset.id)
                  },
                }}
                key={key}
              >
                <Tooltip>
                  {asset.name}
                </Tooltip>
              </Marker>
          ))}
        </MapContainer>
      </PlantMapContainer>
    </>
  )
}

export default PlantMapPlanimetry;
