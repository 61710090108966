import React from 'react';
import { useParams } from 'react-router-dom';

import L from 'leaflet';
import {
  CircleMarker,
  MapContainer,
  Marker,
  TileLayer,
  useMap
} from 'react-leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import './map.css';
import { PlantMapContainer } from '../../styles';

import {
  useGetPlant
} from '../../hooks';


const PlantMap = () => {

  const routerParams = useParams();
  const plant = useGetPlant({ id: routerParams.id });

  const MarkerIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconAnchor: [12.5, 40],
    shadowAnchor: [12.5, 40]
  });
  L.Marker.prototype.options.icon = MarkerIcon;

  const ChangeView = ({ center, zoom }) => {
    const map = useMap();
    map.setView(center, zoom);
    return null;
  }

  return (
    plant && plant?.data?.location?.coordinates[1] && plant?.data?.location?.coordinates[0] ? (
      <PlantMapContainer>
        <MapContainer
          id={'plantmap-map'}
          center={[plant.data.location.coordinates[1], plant.data.location.coordinates[0]]}
          zoom={18}
          attributionControl={false}
        >
          <ChangeView center={[plant.data.location.coordinates[1], plant.data.location.coordinates[0]]} zoom={18} />
          <CircleMarker center={[plant.data.location.coordinates[1], plant.data.location.coordinates[0]]} radius={50} />
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            className="map-tiles"
          />
          <Marker
            position={[plant.data.location.coordinates[1], plant.data.location.coordinates[0]]}
          />
        </MapContainer>
      </PlantMapContainer>
    ) : null
  );
}

export default PlantMap;
