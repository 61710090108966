import React, { useRef, useState } from 'react';
import { useSnackbar } from 'notistack';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
  Typography,
  useMediaQuery
} from '@mui/material';

import { DropzoneAreaBase } from 'react-mui-dropzone';

import { useTheme } from '@mui/styles';

import useStyles from './styles';

import {
  useMutateUploadZoneImage
} from '../../../hooks';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const UploadZoneImage = (props) => {
  const {
    isDialogOpen,
    setIsDialogOpen,
    setSelectedItem,
    setSelectionModel,
    zone
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isBreakpointsDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBreakpointsUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const formRef = useRef();

  const [isUploading, setIsUploading] = useState(false);
  const [fileObjs, setFileObjs] = useState([]);

  const uploadZoneImageMutation = useMutateUploadZoneImage();

  /* Notification */
  const { enqueueSnackbar } = useSnackbar();
  const notify = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant
    });
  };

  const uploadZoneImage = (id, data) => {
    data.forEach(f => {
      const formData = new FormData();
      formData.append('file', f.file);
      uploadZoneImageMutation.mutate({
        id: id,
        body: formData
      },
      {
        onSuccess: () => {
          handleCloseDialog();
        }
      });
    });
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setFileObjs([]);
    setSelectedItem();
    setSelectionModel([]);
  };

  const handleOnSubmit = () => {
    if (formRef.current.reportValidity()) {
      uploadZoneImage(zone?.id, fileObjs);
    };
  };

  return (
    <>
      <Dialog
        fullScreen={isBreakpointsDownSm}
        fullWidth={!isBreakpointsDownSm}
        style={{ minWidth: !isBreakpointsDownSm ? 600 : 200 }}
        open={isDialogOpen}
        onClose={handleCloseDialog}
        TransitionComponent={Transition}
      >
        <DialogTitle>
          <Typography component='h1' variant='h4' align='center'>
            Change Zone Photo
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <form ref={formRef}>

            <Grid container spacing={3}>

              {/* Zone image */}
              <Grid item xs={12}>
                <Typography color='textSecondary' className={classes.assetContext}>
                  Upload 360 picture (PNG/JPEG)
                </Typography>
                <DropzoneAreaBase
                  acceptedFiles={['image/png', 'image/jpg', 'image/jpeg']}
                  fileObjects={fileObjs}
                  filesLimit={1}
                  maxFileSize={10000000}
                  onAlert={(message, variant) => notify(message, variant)}
                  showAlerts={false}
                  onAdd={(files) => {
                    setFileObjs(files);
                    setIsUploading(true);
                  }}
                  onDelete={(files) => {
                    setFileObjs([]);
                    setIsUploading(false);
                  }}
                />
              </Grid>

            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            className={classes.button}
            onClick={handleCloseDialog}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            className={classes.button}
            onClick={handleOnSubmit}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UploadZoneImage;
