import axiosInstance from '../axios.instance';

export const apiAddComment = async (variables) => {
  let config = {
    method: 'post',
    url: `/comments`,
    data: variables.body
  };
  const response = await axiosInstance(config);
  return response.data;
};

export const apiGetComments = async (variables) => {
  let config = {
    method: 'get',
    url: '/comments',
    params: variables
  };
  const { data, headers } = await axiosInstance(config);
  return {
    data,
    headers
  };
};

export const apiGetComment = async (variables) => {
  let config = {
    method: 'get',
    url: `/comments/${variables.id}`
  };
  const response = await axiosInstance(config);
  return response.data;
};

export const apiEditComment = async (variables) => {
  let config = {
    method: 'put',
    url: `/comments/${variables.id}`,
    data: variables.body
  };
  const response = await axiosInstance(config);
  return response.data;
};

export const apiDelComment = async (variables) => {
  let config = {
    method: 'delete',
    url: `/comments/${variables.id}`
  }
  const response = await axiosInstance(config);
  return response.data;
};