import React, { useEffect, useRef, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Slide,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
  FormGroup
} from '@mui/material';

import { useTheme } from '@mui/styles';

import useStyles from './styles';

import {
  useGetCurrentUser,
  useMutateEditUser
} from '../../../hooks';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});


const EditUser = (props) => {

  const {
    isDialogOpen,
    setIsDialogOpen,
    setSelectedItem,
    setSelectionModel,
    user
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isBreakpointsDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBreakpointsUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const formRef = useRef();

  const [firstname, setFirstname] = useState(user?.firstname);
  const [lastname, setLastname] = useState(user?.lastname);
  const [email, setEmail] = useState(user?.email);
  const [role, setRole] = useState(user?.role);
  const [active, setActive] = useState(user?.active);
  const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber);
  const [organization, setOrganization] = useState(user?.organization);
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const [isNotValid, setIsNotValid] = useState(false);

  const currentUser = useGetCurrentUser();
  const editUserMutation = useMutateEditUser();

  const editUser = (userId) => {
    if (password && confirmPassword && !isNotValid) {
      editUserMutation.mutate({
        id: userId,
        body: {
          firstname: firstname,
          lastname: lastname,
          email: email,
          password: password,
          companyId: currentUser.data.companyId,
          createdById: currentUser.data.id,
          phoneNumber: phoneNumber,
          organization: organization,
          role: role,
          active: active
        },
      },
      {
        onSuccess: () => {
          handleCloseDialog();
        }
      });
    } else {
      editUserMutation.mutate({
        id: userId,
        body: {
          firstname: firstname,
          lastname: lastname,
          email: email,
          companyId: currentUser.data.companyId,
          createdById: currentUser.data.id,
          phoneNumber: phoneNumber,
          organization: organization,
          role: role,
          active: active
        },
      },
      {
        onSuccess: () => {
          handleCloseDialog();
        }
      });
    }

  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setFirstname();
    setLastname();
    setRole();
    setActive();
    setEmail();
    setPhoneNumber();
    setOrganization();
    setPassword();
    setConfirmPassword();
    setSelectedItem();
    setSelectionModel([]);
  };

  const handleOnSubmit = () => {
    if (formRef.current.reportValidity()) {
      editUser(user?.id);
    };
  };

  useEffect(() => {
    setFirstname(user?.firstname);
    setLastname(user?.lastname);
    setRole(user?.role);
    setActive(user?.active);
    setEmail(user?.email);
    setPhoneNumber(user?.phoneNumber);
    setOrganization(user?.organization);
    setPassword();
    setConfirmPassword();
  }, [user]);

  useEffect(() => {
    if (password && confirmPassword) {
      if (password !== confirmPassword) {
        setIsNotValid(true);
      } else {
        setIsNotValid(false);
      };
    };
  }, [password, confirmPassword])

  return (
    <Dialog
      fullScreen={isBreakpointsDownSm}
      fullWidth={!isBreakpointsDownSm}
      style={{ minWidth: !isBreakpointsDownSm ? 600 : 200 }}
      open={isDialogOpen}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Typography component='h1' variant='h4' align='center'>
          {role && role === 'external' ? 'Edit External User' : 'Edit User'}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <form ref={formRef}>
          <Grid container spacing={3}>

            {/* active */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={active}
                        onChange={(event) => { setActive(event.target.checked) }}
                      />
                    }
                    label="Enable/Disable User"
                  />
                </FormGroup>
              </FormControl>
            </Grid>

            {role && role !== 'external' && (
              <>
                {/* role */}
                <Grid item xs={12}>
                  <FormControl required fullWidth>
                    <FormLabel id="role-controlled-radio-buttons-group">Role</FormLabel>
                    <RadioGroup
                      required
                      row
                      aria-labelledby="role-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={role}
                      onChange={(event) => { setRole(event.target.value); }}
                    >
                      {(currentUser?.data?.role === 'admin') ? (
                        <FormControlLabel value="admin" control={<Radio />} label="Admin" />
                      ) : (currentUser?.data?.role === 'superadmin') ? (
                        <>
                          <FormControlLabel value="superadmin" control={<Radio />} label="SuperAdmin" />
                          <FormControlLabel value="admin" control={<Radio />} label="Admin" />
                        </>
                      ) : null}
                      <FormControlLabel value="manager" control={<Radio />} label="Manager" />
                      <FormControlLabel value="engineer" control={<Radio />} label="Engineer" />
                      <FormControlLabel value="external" control={<Radio />} label="External" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </>
            )}

            {/* firstname */}
            <Grid item xs={12}>
              <TextField
                required
                id='firstname'
                label='First name'
                variant='outlined'
                fullWidth
                value={firstname ? firstname : ''}
                onChange={(event) => { setFirstname(event.target.value) }}
              />
            </Grid>

            {/* lastname */}
            <Grid item xs={12}>
              <TextField
                required
                id='lastname'
                label='Last name'
                variant='outlined'
                fullWidth
                value={lastname ? lastname : ''}
                onChange={(event) => { setLastname(event.target.value) }}
              />
            </Grid>

            {/* phoneNumber */}
            <Grid item xs={12}>
              <TextField
                id='phoneNumber'
                label='Phone Number'
                variant='outlined'
                fullWidth
                value={phoneNumber ? phoneNumber : ''}
                onChange={(event) => { setPhoneNumber(event.target.value) }}
              />
            </Grid>

            {role && role === 'external' && (
              <>
                {/* organization */}
                <Grid item xs={12}>
                  <TextField
                    id='organization'
                    label='Organization name'
                    variant='outlined'
                    fullWidth
                    value={organization ? organization : ''}
                    onChange={(event) => { setOrganization(event.target.value) }}
                  />
                </Grid>
              </>
            )}

            {role && role !== 'external' && (
              <>
                {/* email */}
                <Grid item xs={12}>
                  <TextField
                    required
                    id='email'
                    label='E-Mail'
                    variant='outlined'
                    fullWidth
                    value={email ? email : ''}
                    onChange={(event) => { setEmail(event.target.value) }}
                  />
                </Grid>

                {/* password */}
                <Grid item xs={12}>
                  <TextField
                    required={password}
                    error={isNotValid}
                    id="password"
                    label="Password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    value={password ? password : ''}
                    onChange={(event) => { setPassword(event.target.value) }}
                  />
                </Grid>

                {/* confirm password */}
                <Grid item xs={12}>
                  <TextField
                    required={password}
                    error={isNotValid}
                    id="confirm-password"
                    label="Confirm Password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    value={confirmPassword ? confirmPassword : ''}
                    onChange={(event) => { setConfirmPassword(event.target.value) }}
                    helperText={ isNotValid ? `Passwords do not match` : null}
                  />
                </Grid>
              </>
            )}

          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          className={classes.button}
          onClick={handleCloseDialog}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          className={classes.button}
          onClick={handleOnSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditUser;
