export const ticketPriority = [
  'critical',
  'high',
  'normal',
  'low'
];

export const ticketStatus = [
  'new',
  'open',
  'pending',
  'solved'
];

export const recurringFrequency = [
  'none',
  'daily',
  'weekly',
  'monthly',
  'yearly'
];
