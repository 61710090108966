import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { deepOrange, blueGrey } from '@mui/material/colors';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: deepOrange,
    secondary: blueGrey
  },
  status: {
    danger: 'red',
  },
});

export const dark = responsiveFontSizes(darkTheme);
