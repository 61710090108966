import React from 'react';

import {
  Grid,
  TextField,
  Typography
} from '@mui/material';

const DetailsForm = (props) => {
  const {
    quantity,
    setQuantity,
    cost,
    setCost
  } = props;


  return (
    <>
      <Typography variant='h6' gutterBottom>
        Part Details
      </Typography>
      <Grid container spacing={3}>

        {/* quantity */}
        <Grid item xs={6}>
          <TextField
            required
            fullWidth
            id="part-wizard-quantity"
            label="Quantity"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            value={quantity ? quantity : ''}
            onChange={(event) => { setQuantity(event.target.value) }}
          />
        </Grid>

        {/* cost */}
        <Grid item xs={6}>
          <TextField
            required
            fullWidth
            id="part-wizard-cost"
            label="Cost"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            value={cost ? cost : ''}
            onChange={(event) => { setCost(event.target.value) }}
          />
        </Grid>

      </Grid>
    </>
  );
}

export default DetailsForm;
