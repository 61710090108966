import React, { useRef, useState } from 'react';

import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Slide,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';

import { useTheme } from '@mui/styles';

import useStyles from './styles';

import {
  useGetCurrentUser,
  useGetUsers,
  useMutateAddComment,
  useMutateEditTicket
} from '../../../hooks';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});


const NewExtOperator = (props) => {

  const {
    isNewExtDialogOpen,
    setIsNewExtDialogOpen,
    ticket
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isBreakpointsDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBreakpointsUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const formRef = useRef();

  const [newExtOper, setNewExtOper] = useState();
  
  const currentUser = useGetCurrentUser();
  const users = useGetUsers({ role: 'external'});
  const addCommentMutation = useMutateAddComment();
  const editTicketMutation = useMutateEditTicket();

  const editTicket = (operId) => {
    let extOperIds = [...ticket?.data?.extOperIds, operId];
    editTicketMutation.mutate({
      id: ticket?.data?.id,
      body: {
        extOperIds: extOperIds
      },
    },
    {
      onSuccess: (data) => {
        ticket.refetch();
        handleCloseDialog(data);
        if (ticket?.data?.type === 'single') {
          // addCommentMutation.mutate({
          //   body: {
          //     text: `Auto-Comment: Ticket updated`,
          //     companyId: currentUser?.data?.companyId,
          //     createdById: currentUser?.data?.id,
          //     ticketId: ticket?.data?.id
          //   }
          // });
        };
      }
    });
  };

  const handleCloseDialog = (data) => {
    setIsNewExtDialogOpen(false);
    setNewExtOper();
  };

  const handleOnSubmit = () => {
    if (formRef.current.reportValidity()) {
      console.log(newExtOper);
      editTicket(newExtOper);
    }
  }

  const filteredUsers = users?.data?.data?.filter(user => !ticket?.data?.extOperIds?.includes(user.id));

  return (
    <Dialog
      fullScreen={isBreakpointsDownSm}
      fullWidth={!isBreakpointsDownSm}
      style={{ minWidth: !isBreakpointsDownSm ? 600 : 200 }}
      open={isNewExtDialogOpen}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Typography component='h1' variant='h4' align='center'>
          New External Operator
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <form ref={formRef}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl
                variant='outlined'
                fullWidth
              >
                <Autocomplete
                  id='user-assignee-ticket-dialog'
                  options={users ? filteredUsers : []}
                  getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
                  getOptionSelected={(option, value) => { return (option.id === value.id )}}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      required
                      label='External User'
                    />
                  }
                  noOptionsText={filteredUsers?.length === 0 ? "No external users available" : "No options"}
                  onChange={(event, value, reason) => {
                    if (reason !== 'clear') {
                      console.log(value.id);
                      setNewExtOper(value.id);
                    }
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          className={classes.button}
          onClick={handleCloseDialog}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          className={classes.button}
          onClick={handleOnSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NewExtOperator;
