import React from 'react';
import {
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const MainForm = (props) => {
  const {
    title,
    setTitle,
    description,
    setDescription,
    estimatedDuration,
    setEstimatedDuration,
    singleStartDate,
    setSingleStartDate
  } = props;

  return (
    <>
      <Typography variant='h6' gutterBottom>
        Main info
      </Typography>
      { /* Title */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            required
            id='title'
            label='Title'
            variant='outlined'
            fullWidth
            value={title ? title : ''}
            onChange={(event) => { setTitle(event.target.value) }}
          />
        </Grid>
        { /* Description */}
        <Grid item xs={12}>
          <TextField
            required
            multiline
            rows={6}
            id='description'
            label='Description'
            variant='outlined'
            fullWidth
            value={description ? description : ''}
            onChange={(event) => { setDescription(event.target.value) }}
          />
        </Grid>
        { /* Start date */}
        <Grid item xs={12} sm={6}>
          <FormControl variant='outlined'>
            <DateTimePicker
              inputVariant='outlined'
              label='Start date'
              variant='dialog'
              value={singleStartDate}
              onChange={(event) => { setSingleStartDate(event); }}
              renderInput={(params) => <TextField required {...params} />}
            />
          </FormControl>
        </Grid>
        { /* Estimated duration */}
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id='estimatedDuration'
            label='Estimated Duration'
            variant='outlined'
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">hours</InputAdornment>,
            }}
            value={estimatedDuration ? estimatedDuration : ''}
            onChange={(event) => { setEstimatedDuration(event.target.value) }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default MainForm;
