import axiosInstance from '../axios.instance';

export const apiAddZone = async (variables) => {
  let config = {
    method: 'post',
    url: `/zones`,
    data: variables.body
  };
  const response = await axiosInstance(config);
  return response.data;
};

export const apiGetZones = async (variables) => {
  let config = {
    method: 'get',
    url: '/zones',
    params: variables
  };
  const { data, headers } = await axiosInstance(config);
  return {
    data,
    headers
  };
};

export const apiGetZone = async (variables) => {
  let config = {
    method: 'get',
    url: `/zones/${variables.id}`
  };
  const response = await axiosInstance(config);
  return response.data;
};

export const apiEditZone = async (variables) => {
  let config = {
    method: 'put',
    url: `/zones/${variables.id}`,
    data: variables.body
  };
  const response = await axiosInstance(config);
  return response.data;
};

export const apiDelZone = async (variables) => {
  let config = {
    method: 'delete',
    url: `/zones/${variables.id}`
  }
  const response = await axiosInstance(config);
  return response.data;
};

export const apiUploadZoneImage = async (variables) => {
  let config = {
    method: 'post',
    url: `/zones/${variables.id}`,
    data: variables.body
  };
  const response = await axiosInstance(config);
  return response.data;
};
