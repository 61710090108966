import React, { useState } from 'react';

import {
  Grid,
  TextField,
  Typography
} from '@mui/material';

import useStyles from './styles';

const DetailsForm = (props) => {
  const {
    firstname,
    setFirstname,
    lastname,
    setLastname,
    phoneNumber,
    setPhoneNumber,
    organization,
    setOrganization,
    role
  } = props;

  const classes = useStyles();


  return (
    <>
      <Typography variant='h6' align='center' gutterBottom>
        {role && role === 'external' ? 'External User Details' : 'User Details'}
      </Typography>
      <Grid container spacing={3}>

        {/* firstname */}
        <Grid item xs={12}>
          <TextField
            required
            id='firstname'
            label='First name'
            variant='outlined'
            fullWidth
            value={firstname ? firstname : ''}
            onChange={(event) => { setFirstname(event.target.value) }}
          />
        </Grid>

        {/* lastname */}
        <Grid item xs={12}>
          <TextField
            required
            id='lastname'
            label='Last name'
            variant='outlined'
            fullWidth
            value={lastname ? lastname : ''}
            onChange={(event) => { setLastname(event.target.value) }}
          />
        </Grid>

        {/* phoneNumber */}
        <Grid item xs={12}>
          <TextField
            id='phoneNumber'
            label='Phone Number'
            variant='outlined'
            fullWidth
            value={phoneNumber ? phoneNumber : ''}
            onChange={(event) => { setPhoneNumber(event.target.value) }}
          />
        </Grid>

        {role && role === 'external' && (
          <>
            {/* organization */}
            <Grid item xs={12}>
              <TextField
                id='organization'
                label='Organization name'
                variant='outlined'
                fullWidth
                value={organization ? organization : ''}
                onChange={(event) => { setOrganization(event.target.value) }}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}

export default DetailsForm;
