import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TableIcons from '../layout/TableIcons';
import Title from '../../components/layout/Title';

import { Calendar, Views, dateFnsLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import { format, parse, startOfWeek, getDay } from "date-fns";
import enGB from 'date-fns/locale/en-GB'

import {
  useGetCalendarEvents,
  useGetCurrentUser
} from '../../hooks';

import TicketWizard from './wizards/TicketWizard';

const locales = {
  'en-GB': enGB,
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 1 }),
  getDay,
  locales,
})

const StyledCalendar = styled(Calendar)(({theme}) => ({
  '& .rbc-event': {
    minHeight: 30,
    backgroundColor: theme.palette.primary.main,
  },
  '.rbc-overlay': {
    backgroundColor: 'red',
  },
  '& .cr-calendar .react-daypicker-root .day.today, & .cr-calendar .react-daypicker-root .day.today:hover ':
    {
      color: theme.palette.primary.main,
    },
  '& .rbc-event, & .rbc-event.rbc-selected': {
    backgroundColor: theme.palette.primary.main,
  },
  '& .rbc-slot-selection': {
    backgroundColor: theme.palette.primary.main,
  },
  '& rbc-event, & .rbc-day-slot, & .rbc-background-event': {
    backgroundColor: 'transparent',
  },
  '& .rbc-toolbar button': {
    cursor: 'pointer',
    color: theme.palette.text.secondary,
    fontSize: '100%',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '&:active, &.rbc-active': {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover, &:focus': {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
  },
  '& .cr-calendar-color .header': {
    backgroundColor: theme.palette.primary.main,
  },
  '& .rbc-off-range-bg': {
    backgroundColor: theme.palette.background.paper,
  },
  '& .rbc-row-segment a': {
    color: theme.palette.primary.main,
  },
  '& .cr-calendar table': {
    color: '#a8aaad',
    '& th': {
      color: '#b1b5b8',
    },
  },
  '& .cr-calendar .react-daypicker-root .day:hover:not(.empty), & .cr-calendar .react-daypicker-root .day.active':
    {
      backgroundColor: 'transparent',
      color: '#888888',
    },
  '& .cr-calendar-color .react-daypicker-root .previous-month, & .cr-calendar-color .react-daypicker-root .next-month, & .cr-calendar-color .react-daypicker-root .previous-month:hover, & .cr-calendar-color .react-daypicker-root .next-month:hover':
    {
      color: theme.palette.primary.contrastText,
    },
  '& .cr-calendar-color .header .month-year': {
    color: theme.palette.primary.contrastText,
  },
  '& .cr-calendar-color table thead th': {
    color: '#313541',
  },
  '& .rbc-today': {
    backgroundColor: theme.palette.background.paper,
  },
  '& .rbc-show-more': {
    backgroundColor: 'transparent',
  },
}));

const TicketsCalendar = (props) => {

  const currentUser = useGetCurrentUser();
  const isAuthorized = true ? (currentUser?.data?.role === 'manager'
    || currentUser?.data?.role === 'admin'
    || currentUser?.data?.role === 'superadmin') : false;
  const history = useHistory();
  const today = new Date();
  const events = useGetCalendarEvents();
  const formattedEvents = events?.data?.data?.map(({start, end, ...rest}) => (
    {
      start: new Date(Date.parse(start)),
      end: new Date(Date.parse(end)),
      ...rest
    }
  ));

  const [activeStep, setActiveStep] = useState(0);
  const [dialogMode, setDialogMode] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleClickView = (event) => {
    history.push(`/tickets/${event.resource}`);
  };

  const handleClickNew = () => {
    setDialogMode('new');
    setIsDialogOpen(true);
    setActiveStep(0);
  };

  return (
    <>
      <Card>
        <CardHeader
          title={
            <>
              <Title>Calendar</Title>
            </>
          }
          action={
            <>
              <Tooltip title="Refresh" placement="bottom" arrow>
                <IconButton
                  onClick={() => {
                    events.refetch();
                  }}
                >
                  <TableIcons.Sync />
                </IconButton>
              </Tooltip>
              {isAuthorized &&
                <>
                  <Tooltip title="Add new Work Order" placement="bottom" arrow>
                    <IconButton
                      onClick={() => {
                        handleClickNew();
                      }}
                    >
                      <TableIcons.Add />
                    </IconButton>
                  </Tooltip>
                </>
              }
            </>
          }
        />
        <CardContent>
          <StyledCalendar
            localizer={localizer}
            events={formattedEvents}
            startAccessor="start"
            endAccessor="end"
            defaultView={Views.WEEK}
            min={
              new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate(),
                6
              )
            }
            max={
              new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate(),
                20
              )
            }
            style={{ height: 500 }}
            onSelectEvent={(event) => handleClickView(event) }
          />
        </CardContent>
      </Card>
      <TicketWizard
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        dialogMode={dialogMode}
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
      />
    </>
  )
}

export default TicketsCalendar;