import React from 'react';
import {
  Autocomplete,
  FormControl,
  Grid,
  TextField,
  Typography
} from '@mui/material';


const AssetForm = (props) => {
  const {
    assets,
    setPlantId,
    setZoneId,
    setAssetId
  } = props;

  return (
    <>
      <Typography variant='h6' gutterBottom>
        Target Asset
      </Typography>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <FormControl
            variant='outlined'
            fullWidth
          >
            <Autocomplete
              id='asset-select-ticket-wizard'
              options={assets ? assets?.data?.data?.sort((a, b) => -b.zone.name.localeCompare(a.zone.name)) : []}
              groupBy={(option) => option.zone.name}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => { return (option.id === value.id )}}
              renderInput={(params) =>
                <TextField
                  {...params}
                  required
                  label='Assets'
                />
              }
              onChange={(event, value, reason) => {
                if (reason !== 'clear') {
                  setPlantId(value.plantId);
                  setZoneId(value.zoneId);
                  setAssetId(value.id);
                }
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}

export default AssetForm;
