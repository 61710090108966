import React, { useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';

import { useTheme } from '@mui/styles';

import useStyles from './styles';

import {
  useMutateEditUser
} from '../../../hooks';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});


const ChangeUserPassword = (props) => {

  const {
    isDialogOpen,
    setIsDialogOpen,
    user
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isBreakpointsDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBreakpointsUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const formRef = useRef();

  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const [isNotValid, setIsNotValid] = useState(false);

  /* Notification */
  const { enqueueSnackbar } = useSnackbar();
  const notify = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant
    });
  };

  const editUserMutation = useMutateEditUser();

  const editUser = (userId) => {
    if (password && confirmPassword && !isNotValid) {
      editUserMutation.mutate({
        id: userId,
        body: {
          password: password
        },
      },
      {
        onSuccess: () => {
          handleCloseDialog();
          notify('Password changed!', 'success');
        }
      });
    } else {
      handleCloseDialog();
      notify('Password not changed.', 'info');
    }
  };

  const handleCloseDialog = (data) => {
    setIsDialogOpen(false);
    setPassword();
    setConfirmPassword();
    setIsNotValid(false);
  };

  const handleOnSubmit = () => {
    if (formRef.current.reportValidity()) {
      editUser(user?.id);
    };
  };

  useEffect(() => {
    setPassword();
    setConfirmPassword();
  }, [user]);

  useEffect(() => {
    if (password && confirmPassword) {
      if (password !== confirmPassword) {
        setIsNotValid(true);
      } else {
        setIsNotValid(false);
      };
    };
  }, [password, confirmPassword])

  return (
    <Dialog
      fullScreen={isBreakpointsDownSm}
      fullWidth={!isBreakpointsDownSm}
      style={{ minWidth: !isBreakpointsDownSm ? 600 : 200 }}
      open={isDialogOpen}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Typography component='h1' variant='h4' align='center'>
          Change Password
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <form ref={formRef}>
          <Grid container spacing={3}>

            {/* password */}
            <Grid item xs={12}>
              <TextField
                required={password}
                error={isNotValid}
                id="password"
                label="Password"
                type="password"
                variant="outlined"
                fullWidth
                value={password ? password : ''}
                onChange={(event) => { setPassword(event.target.value) }}
              />
            </Grid>

            {/* confirm password */}
            <Grid item xs={12}>
              <TextField
                required={password}
                error={isNotValid}
                id="confirm-password"
                label="Confirm Password"
                type="password"
                variant="outlined"
                fullWidth
                value={confirmPassword ? confirmPassword : ''}
                onChange={(event) => { setConfirmPassword(event.target.value) }}
                helperText={ isNotValid ? `Passwords do not match` : null}
              />
            </Grid>

          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          className={classes.button}
          onClick={handleCloseDialog}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          className={classes.button}
          onClick={handleOnSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ChangeUserPassword;
