import React, { forwardRef, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Step,
  Stepper,
  StepLabel,
  Typography,
  useMediaQuery } from '@mui/material';

import { useTheme } from '@mui/styles';
import useStyles from './styles';

import {
  useMutateAddAsset,
  useGetCurrentUser,
  useGetPlants,
  useGetZones
} from '../../../../hooks';

import MainForm from './MainForm';
import DetailsForm from './DetailsForm';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const AssetWizard = (props) => {

  const {
    activeStep,
    setActiveStep,
    dialogMode,
    isDialogOpen,
    setIsDialogOpen
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isBreakpointsDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBreakpointsUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const routerParams = useParams();

  const currentUser = useGetCurrentUser();
  const addAssetMutation = useMutateAddAsset();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [plantId, setPlantId] = useState();
  const [zoneId, setZoneId] = useState();
  const [customId, setCustomId] = useState();

  const plants = useGetPlants();
  const zones = useGetZones({ plantId: plantId });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const formRef = useRef();

  const steps = ['Main info', 'Asset details'];

  const isLastStep = activeStep === steps.length - 1;

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <MainForm
            name={name}
            setName={setName}
            description={description}
            setDescription={setDescription}
            customId={customId}
            setCustomId={setCustomId}
          />
        )
      case 1:
        return (
          <DetailsForm
            routerParams={routerParams}
            plants={plants?.data?.data}
            zones={zones?.data?.data}
            plantId={plantId}
            setPlantId={setPlantId}
            zoneId={zoneId}
            setZoneId={setZoneId}
          />
        )
      default:
        return <div>Not Found</div>;
    }
  }

  const submitForm = () => {
    if (dialogMode === 'new' && formRef.current.reportValidity()) {
      addAssetMutation.mutate({
        body: {
          name: name,
          description: description,
          companyId: currentUser.data.companyId,
          plantId: plantId,
          zoneId: zoneId,
          customId: customId,
          createdById: currentUser.data.id
        }
      })
      setIsSubmitting(false);
      setActiveStep(activeStep + 1);
    }
  }

  const handleNewOne = () => {
    setActiveStep(0);
    setName('');
    setDescription('');
    setPlantId();
    setZoneId();
    setCustomId();
  }

  const handleNext = () => {
    if (formRef.current.reportValidity()) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  const handleReset = () => {
    setActiveStep(0);
    setName('');
    setDescription('');
    setPlantId();
    setZoneId();
    setCustomId();
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setActiveStep(0);
    setName('');
    setDescription('');
    setPlantId();
    setZoneId();
    setCustomId();
  }

  if (dialogMode === 'new') {
    return (
      <>
        <Dialog
          fullScreen={isBreakpointsDownSm}
          style={{ minWidth: !isBreakpointsDownSm ? 600 : 200 }}
          open={isDialogOpen}
          onClose={handleReset}
          TransitionComponent={Transition}
        >
          <DialogTitle>
            <Typography variant='h4' align='center'>
              Asset creation
            </Typography>
          </DialogTitle>
          <DialogContent
            style={{ minWidth: !isBreakpointsDownSm ? 600 : 200 }}
            dividers
          >
            <Stepper
              activeStep={activeStep}
              className={classes.stepper}
              alternativeLabel
            >
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <>
              {activeStep === steps.length ? (
                <>
                  <Typography variant='h5' align='center' gutterBottom>
                    Operations completed.
                  </Typography>
                  <Typography align='center' variant='subtitle1'>
                    You successfully submitted a new asset.
                  </Typography>
                  <div className={classes.buttons}>
                    <Button
                      onClick={handleCloseDialog}
                      className={classes.button}
                    >
                      Close
                    </Button>
                    <div className={classes.wrapper}>
                      <Button
                        onClick={handleNewOne}
                        type='submit'
                        variant='contained'
                        color='primary'
                        className={classes.button}
                      >
                        Create a new one
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <form ref={formRef}>

                    {renderStepContent(activeStep)}

                    <div className={classes.buttons}>
                      {activeStep !== 0 && (
                        <Button
                          className={classes.button}
                          type='submit'
                          onClick={handleBack}
                        >
                          Back
                        </Button>
                      )}
                      <div className={classes.wrapper}>
                        <Button
                          className={classes.button}
                          onClick={handleCloseDialog}
                        >
                          Cancel
                        </Button>

                        {(isLastStep) ? (
                          <Button
                            type='submit'
                            variant='contained'
                            color='primary'
                            className={classes.button}
                            onClick={submitForm}
                          >
                            Save
                          </Button>
                        ) : (
                          <Button
                            disabled={isSubmitting}
                            type='submit'
                            variant='contained'
                            color='primary'
                            className={classes.button}
                            onClick={handleNext}
                          >
                            Next
                          </Button>
                        )}
                        {isSubmitting && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </div>
                    </div>
                  </form>
                </>
              )}
            </>
          </DialogContent>
        </Dialog>
      </>
    );
  } else {
    return null;
  }
}

export default AssetWizard;
