import { atom } from 'recoil';

const userState = atom({
  key: 'user',
  default: {
    id: '',
    firstname: '',
    lastname: '',
    email: '',
    role: '',
    companyId: '',
    phoneNumber: '',
    description: '',
    imgUrl: ''
  }
})

export default userState;