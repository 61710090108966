import React from 'react';
import { Grid } from '@mui/material';
import ZonesTable from '../../components/zones/ZonesTable';

const ZonesPage = () => {

  return (
    <Grid container spacing={3}>

      <Grid item xs>
        <ZonesTable tableHeight={650} />
      </Grid>
    </Grid>
  )
}

export default ZonesPage;
