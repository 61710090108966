import React from 'react';
import { useHistory } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import {
  Article as ArticleIcon,
  DeviceHub as DeviceHubIcon,
  Factory as FactoryIcon,
  People as PeopleIcon,
  PrecisionManufacturing as PrecisionManufacturingIcon,
  Public as PublicIcon,
  ConfirmationNumber as ConfirmationNumberIcon,
  LocalConvenienceStore as LocalConvenienceStoreIcon,
  CropFree as CropFreeIcon,
  ViewCarousel as ViewCarouselIcon
} from '@mui/icons-material';
import { Menu } from '@mui/material';


const MenuListItems = (props) => {

  const {
    closeDrawer
  } = props;

  const history = useHistory();

  const handleListItemClick = (path) => {
    history.push(path);
    closeDrawer();
  }

  return (
    <>
      <Tooltip title='Home' placement="right" arrow>
        <ListItem button onClick={() => handleListItemClick('/')}>
          <ListItemIcon>
            <PublicIcon />
          </ListItemIcon>
          <ListItemText primary='Home' />
        </ListItem>
      </Tooltip>
      <Tooltip title='Work Orders' placement="right" arrow>
        <ListItem button onClick={() => handleListItemClick('/tickets')}>
          <ListItemIcon>
            <ArticleIcon />
          </ListItemIcon>
          <ListItemText primary='Work Orders' />
        </ListItem>
      </Tooltip>
      <Tooltip title='Plants' placement="right" arrow>
        <ListItem button onClick={() => handleListItemClick('/plants')}>
          <ListItemIcon>
            <FactoryIcon />
          </ListItemIcon>
          <ListItemText primary='Plants' />
        </ListItem>
      </Tooltip>
      <Tooltip title='Zones' placement="right" arrow>
        <ListItem button onClick={() => handleListItemClick('/zones')}>
          <ListItemIcon>
            <ViewCarouselIcon />
          </ListItemIcon>
          <ListItemText primary='Zones' />
        </ListItem>
      </Tooltip>
      <Tooltip title='Assets' placement="right" arrow>
        <ListItem button onClick={() => handleListItemClick('/assets')}>
          <ListItemIcon>
            <PrecisionManufacturingIcon />
          </ListItemIcon>
          <ListItemText primary='Assets' />
        </ListItem>
      </Tooltip>
      <Tooltip title='Users' placement="right" arrow>
        <ListItem button onClick={() => handleListItemClick('/users')}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary='Users' />
        </ListItem>
      </Tooltip>
      <Tooltip title='Warehouse' placement="right" arrow>
        <ListItem button onClick={() => handleListItemClick('/warehouse')}>
          <ListItemIcon>
            <LocalConvenienceStoreIcon />
          </ListItemIcon>
          <ListItemText primary='Warehouse' />
        </ListItem>
      </Tooltip>
      <Tooltip title='QR Scanner' placement="right" arrow>
        <ListItem button onClick={() => handleListItemClick('/qr')}>
          <ListItemIcon>
            <CropFreeIcon />
          </ListItemIcon>
          <ListItemText primary='QR' />
        </ListItem>
      </Tooltip>
    </>
  )
};

export default MenuListItems;
