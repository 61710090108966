import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  AppBar,
  Box,
  Card,
  Grid,
  Tabs,
  Tab,
  Typography,
  useMediaQuery
} from '@mui/material';

import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/styles';
import {
  Article as ArticleIcon,
  CalendarMonth as CalendarMonthIcon
} from '@mui/icons-material';
import { ImInsertTemplate } from 'react-icons/im';

import TicketsTable from '../../components/tickets/TicketsTable';
import RecurringTemplatesTable from '../../components/tickets/RecurringTemplatesTable';
import TicketsCalendar from '../../components/tickets/TicketsCalendar';

import {
  useGetCurrentUser
} from '../../hooks';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`action-asset-tabpanel-${index}`}
      aria-labelledby={`action-asset-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `action-asset-tab-${index}`,
    'aria-controls': `action-asset-tabpanel-${index}`,
  };
}


const TicketsPage = () => {
  const theme = useTheme();
  const isBreakpointsUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const currentUser = useGetCurrentUser();
  const isAuthorized = true ? (currentUser?.data?.role === 'manager'
    || currentUser?.data?.role === 'admin'
    || currentUser?.data?.role === 'superadmin') : false;

  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setTabValue(index);
  };

  return (
    <Grid container spacing={3}>

      {/* Tabs */}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Card>
          <AppBar position='static'>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              indicatorColor='primary'
              textColor='primary'
              aria-label='viewer tab actions'
              variant='fullWidth'
            >
              <Tab label='Planner' icon={<ArticleIcon />} {...a11yProps(0)} />
              {isAuthorized && isBreakpointsUpLg &&
                <Tab label='Scheduler' icon={<CalendarMonthIcon />} {...a11yProps(1)} />
              }
              {/* isAuthorized &&
                <Tab label='Recurring Templates' icon={<ImInsertTemplate />} {...a11yProps(2)} />
              */}
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={tabValue}
            onChangeIndex={handleChangeIndex}
            disabled={true}
          >
            {/* Planner */}
            <TabPanel value={tabValue} index={0} dir={theme.direction}>
              <Card
                variant='outlined'
              >
                {isBreakpointsUpLg ?
                  <TicketsTable rowsPerPage={10} tableHeight={650} />
                : <TicketsTable rowsPerPage={5} tableHeight={372} />
                }
              </Card>
            </TabPanel>

            {/* Calendar */}
            <TabPanel value={tabValue} index={1} dir={theme.direction}>
              <Card
                variant='outlined'
              >
                <TicketsCalendar />
              </Card>
            </TabPanel>

            {/* Recurring Templates */}
            <TabPanel value={tabValue} index={2} dir={theme.direction}>
              <Card
                variant='outlined'
              >
                <RecurringTemplatesTable rowsPerPage={10} tableHeight={650} />
              </Card>
            </TabPanel>

          </SwipeableViews>
        </Card>
      </Grid>
    </Grid>
  )
}

export default TicketsPage;
