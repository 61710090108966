import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Slide,
  Typography,
  useMediaQuery
} from '@mui/material';

import { useTheme } from '@mui/styles';

import useStyles from './styles';

import {
  useMutateDelTicket
} from '../../../hooks';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});


const DelTicket = (props) => {

  const {
    selectedItemId,
    setSelectedItem,
    setSelectionModel,
    isDelItemDialogOpen,
    setIsDelItemDialogOpen,
    text
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isBreakpointsDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBreakpointsUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const delTicketMutation = useMutateDelTicket();

  const handleCloseDialog = () => {
    setIsDelItemDialogOpen(false);
    setSelectedItem();
    setSelectionModel([]);
  };

  const delTicket = (ticketId) => {
    if (ticketId) {
      console.log(ticketId);
      delTicketMutation.mutate({ id: ticketId });
    };
  };

  const handleOnSubmit = () => {
    delTicket(selectedItemId);
    handleCloseDialog();
  };

  return (
    <Dialog
      fullScreen={isBreakpointsDownSm}
      style={{ minWidth: !isBreakpointsDownSm ? 600 : 200 }}
      open={typeof isDelItemDialogOpen !== 'undefined' ? isDelItemDialogOpen : null}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Typography component='h1' variant='h5' align='center'>
          {text}
        </Typography>
      </DialogTitle>
      <DialogActions>
        <Button
          variant='contained'
          className={classes.button}
          onClick={handleCloseDialog}
        >
          No
        </Button>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          className={classes.button}
          onClick={handleOnSubmit}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DelTicket;
