import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  formatDistanceToNow,
  parseISO
} from 'date-fns';

import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';

//import { ImAttachment } from 'react-icons/im';
import pdfIcon from '../../images/pdficon.svg';

import { useGetAttachment } from '../../hooks';

const dateToNow = (date) => {
  const parsed = parseISO(date);
  const toNow = formatDistanceToNow(parsed);
  return toNow;
};

const Item = (props) => {
  const {
    id,
    index,
    setSelectedAttachmentId,
    isAttachmentChecked,
    setIsAttachmentChecked,
    ticket
  } = props;

  const [createdAt, setCreatedAt] = useState();

  const handleChange = (event) => {
    if (event.target.checked) {
      setIsAttachmentChecked({
        [id]: true
      });
      setSelectedAttachmentId(id);
    };
    if (!event.target.checked) {
      setIsAttachmentChecked({});
      setSelectedAttachmentId();
    };
  };

  const attachmentOnSuccess = (data) => {
    setCreatedAt(dateToNow(data.createdAt));
  };

  const attachment = useGetAttachment({ id: id }, attachmentOnSuccess)

  return (
    <>
      <ListItem
        key={index}
        button
        component={RouterLink}
        to={{pathname: attachment?.data?.url}}
        target='_blank'
      >
        <ListItemIcon>
          <Checkbox
            checked={Object.keys(isAttachmentChecked).length > 0 && isAttachmentChecked[id]}
            onChange={handleChange}
            disabled={ticket?.data?.status === 'solved'}
          />
        </ListItemIcon>
        <ListItemText
          primary={attachment?.data?.filename}
          secondary={`added ${createdAt} ago`}
        />
        {attachment?.data?.fileType === 'application/pdf' ? (
          <img src={pdfIcon} height='10%' width='10%' alt={attachment?.data?.filename} />
        ) : (
          <img src={attachment?.data?.url} height='10%' width='10%' alt={attachment?.data?.filename} />
        )}
      </ListItem>
    </>
  )
}

const Attachments = (props) => {
  const {
    attachments,
    setSelectedAttachmentId,
    isAttachmentChecked,
    setIsAttachmentChecked,
    ticket
  } = props;

  return (
    <>
      {attachments && attachments.map((attachment, index) => (
        <List>
          <Item
            id={attachment.id}
            index={index}
            setSelectedAttachmentId={setSelectedAttachmentId}
            isAttachmentChecked={isAttachmentChecked}
            setIsAttachmentChecked={setIsAttachmentChecked}
            ticket={ticket}
          />
        </List>
      ))}
    </>
  );
}

export default Attachments;
