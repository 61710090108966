import axiosInstance from '../axios.instance';

export const apiAddTicket = async (variables) => {
  let config = {
    method: 'post',
    url: `/tickets`,
    data: variables.body
  };
  const response = await axiosInstance(config);
  return response.data;
};

export const apiGetTickets = async (variables) => {
  let config = {
    method: 'get',
    url: '/tickets',
    params: variables
  };
  const { data, headers } = await axiosInstance(config);
  return {
    data,
    headers
  };
};

export const apiGetTicket = async (variables) => {
  let config = {
    method: 'get',
    url: `/tickets/${variables.id}`
  };
  const response = await axiosInstance(config);
  return response.data;
};

export const apiEditTicket = async (variables) => {
  let config = {
    method: 'put',
    url: `/tickets/${variables.id}`,
    data: variables.body
  };
  const response = await axiosInstance(config);
  return response.data;
};

export const apiDelTicket = async (variables) => {
  let config = {
    method: 'delete',
    url: `/tickets/${variables.id}`
  }
  const response = await axiosInstance(config);
  return response.data;
};

export const apiGetCalendarEvents = async (variables) => {
  let config = {
    method: 'get',
    url: '/tickets/calendar',
    params: variables
  };
  const { data, headers } = await axiosInstance(config);
  return {
    data,
    headers
  };
};