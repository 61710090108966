import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Slide,
  Typography,
  useMediaQuery
} from '@mui/material';

import { useTheme } from '@mui/styles';

import useStyles from './styles';

import {
  useGetCurrentUser,
  useMutateAddComment,
  useMutateEditTicket
} from '../../../hooks';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});


const TicketDone = (props) => {

  const {
    isDoneDialogOpen,
    setIsDoneDialogOpen,
    ticket
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isBreakpointsDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBreakpointsUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const currentUser = useGetCurrentUser();
  const addCommentMutation = useMutateAddComment();
  const editTicketMutation = useMutateEditTicket();

  const editTicket = (status) => {
    editTicketMutation.mutate({
      id: ticket?.data?.id,
      body: {
        status: status
      },
    },
    {
      // onSuccess: () => addCommentMutation.mutate({
      //   body: {
      //     text: `Auto-Comment: Ticket solved`,
      //     companyId: currentUser?.data?.companyId,
      //     createdById: currentUser?.data?.id,
      //     ticketId: ticket?.data?.id
      //   }
      // })
    });
  };

  const handleCloseDialog = () => {
    setIsDoneDialogOpen(false);
  };

  const handleOnSubmit = () => {
    editTicket('solved');
    setIsDoneDialogOpen(false);
  }

  return (
    <Dialog
      fullScreen={isBreakpointsDownSm}
      style={{ minWidth: !isBreakpointsDownSm ? 600 : 200 }}
      open={isDoneDialogOpen}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Typography component='h1' variant='h5' align='center'>
          Do you want to change the status to DONE?
        </Typography>
      </DialogTitle>
      <DialogActions>
        <Button
          variant='contained'
          className={classes.button}
          onClick={handleCloseDialog}
        >
          No
        </Button>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          className={classes.button}
          onClick={handleOnSubmit}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TicketDone;
