import axiosInstance from '../axios.instance';

export const apiAddAsset = async (variables) => {
  let config = {
    method: 'post',
    url: `/assets`,
    data: variables.body
  };
  const response = await axiosInstance(config);
  return response.data;
};

export const apiGetAssets = async (variables) => {
  let config = {
    method: 'get',
    url: '/assets',
    params: variables
  };
  const { data, headers } = await axiosInstance(config);
  return {
    data,
    headers
  };
};

export const apiGetAsset = async (variables) => {
  let config = {
    method: 'get',
    url: `/assets/${variables.id}`
  };
  const response = await axiosInstance(config);
  return response.data;
};

export const apiEditAsset = async (variables) => {
  let config = {
    method: 'put',
    url: `/assets/${variables.id}`,
    data: variables.body
  };
  const response = await axiosInstance(config);
  return response.data;
};

export const apiDelAsset = async (variables) => {
  let config = {
    method: 'delete',
    url: `/assets/${variables.id}`
  }
  const response = await axiosInstance(config);
  return response.data;
};

export const apiUploadAssetImage = async (variables) => {
  let config = {
    method: 'post',
    url: `/assets/${variables.id}`,
    data: variables.body
  };
  const response = await axiosInstance(config);
  return response.data;
};
