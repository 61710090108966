import axiosInstance from '../axios.instance';

export const apiAddCompany = async (variables) => {
  let config = {
    method: 'post',
    url: `/companies`,
    data: variables.body
  };
  const response = await axiosInstance(config);
  return response.data;
};

export const apiGetCompanies = async (variables) => {
  let config = {
    method: 'get',
    url: '/companies',
    params: variables
  };
  const { data, headers } = await axiosInstance(config);
  return {
    data,
    headers
  };
};

export const apiGetCompany = async (variables) => {
  let config = {
    method: 'get',
    url: `/companies/${variables.id}`
  };
  const response = await axiosInstance(config);
  return response.data;
};

export const apiEditCompany = async (variables) => {
  let config = {
    method: 'put',
    url: `/companies/${variables.id}`,
    data: variables.body
  };
  const response = await axiosInstance(config);
  return response.data;
};

export const apiDelCompany = async (variables) => {
  let config = {
    method: 'delete',
    url: `/companies/${variables.id}`
  }
  const response = await axiosInstance(config);
  return response.data;
};