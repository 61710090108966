import React, { useRef, useState } from 'react';

import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Slide,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';

import { useTheme } from '@mui/styles';

import useStyles from './styles';

import {
  useGetCurrentUser,
  useMutateAddTicketPart,
  useGetParts
} from '../../../hooks';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const NewTicketPart = (props) => {

  const {
    isNewTicketPartDialogOpen,
    setIsNewTicketPartDialogOpen,
    ticket
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isBreakpointsDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBreakpointsUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const formRef = useRef();

  const [partId, setPartId] = useState();
  const [quantity, setQuantity] = useState();

  const currentUser = useGetCurrentUser();
  const parts = useGetParts();
  const addTicketPartMutation = useMutateAddTicketPart();

  const options = parts && parts.data?.data?.map((option) => {
    const firstLetter = option.name[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

  const addTicketPart = () => {
    addTicketPartMutation.mutate({
      body: {
        partId: partId,
        quantity: quantity,
        companyId: currentUser?.data?.companyId,
        plantId: currentUser?.data?.plantId,
        createdById: currentUser?.data?.id,
        ticketId: ticket?.data?.id
      }
    });
  };

  const handleCloseDialog = () => {
    setPartId();
    setQuantity();
    setIsNewTicketPartDialogOpen(false);
  };

  const handleOnSubmit = () => {
    if (formRef.current.reportValidity()) {
      addTicketPart();
      setPartId();
      setQuantity();
      setIsNewTicketPartDialogOpen(false);
    }
  }

  return (
    <Dialog
      fullScreen={isBreakpointsDownSm}
      fullWidth={!isBreakpointsDownSm}
      style={{ minWidth: !isBreakpointsDownSm ? 600 : 200 }}
      open={isNewTicketPartDialogOpen}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Typography component='h1' variant='h4' align='center'>
          Add Part
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <form ref={formRef}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl
                variant='outlined'
                fullWidth
              >
                <Autocomplete
                  id='part-ticket-dialog'
                  options={parts ? options?.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter)) : []}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => `${option.name} (qt: ${option.quantity}, cost: ${option.cost})`}
                  getOptionSelected={(option, value) => { return (option.id === value.id )}}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      required
                      label='Part'
                    />
                  }
                  onChange={(event, value, reason) => {
                    if (reason !== 'clear') {
                      setPartId(value);
                    }
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                id='quantity'
                label='Quantity'
                variant='outlined'
                type='number'
                fullWidth
                value={quantity}
                onChange={(event) => { setQuantity(event.target.value) }}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          className={classes.button}
          onClick={handleCloseDialog}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          className={classes.button}
          onClick={handleOnSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NewTicketPart;
