import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Card,
  CardHeader,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip
} from '@mui/material';

import { DataGrid } from '@mui/x-data-grid';

import { format, parseISO } from 'date-fns';

import jsPDF from 'jspdf';
import 'jspdf-autotable';

import Title from '../layout/Title';
import TableIcons from '../layout/TableIcons';

import {
  useGetCurrentUser,
  useGetTickets
} from '../../hooks';

import RecurringWizard from './wizards/RecurringWizard';
import DelTicket from './dialogs/DelTicket';

const RecurringTemplatesTable = (props) => {

  const {
    plantId,
    tableHeight,
    rowsPerPage
  } = props;

  const history = useHistory();
  const currentUser = useGetCurrentUser();
  const isAuthorized = true ? (currentUser?.data?.role === 'manager'
    || currentUser?.data?.role === 'admin'
    || currentUser?.data?.role === 'superadmin') : false;

  const [activeStep, setActiveStep] = useState(0);
  const [dialogMode, setDialogMode] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDelItemDialogOpen, setIsDelItemDialogOpen] = useState(false)

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(rowsPerPage || 5);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('updatedAt');
  const [search, setSearch] = useState();
  const [zoneId,] = useState();
  const [type,] = useState('recurring');

  const [selectedItem, setSelectedItem] = useState();
  const [selectionModel, setSelectionModel] = useState([]);

  const [sortModel, setSortModel] = useState([{ field: orderBy, sort: order }]);

  const handleSortModelChange = (newModel) => {
    setOrderBy(newModel[0]?.field);
    setOrder(newModel[0]?.sort);
    setSortModel(newModel);
  };

  const tickets = useGetTickets({
    type: type,
    page: page,
    limit: limit,
    orderBy: orderBy,
    order: order,
    search: search,
    plantId: plantId,
    zoneId: zoneId
  });

  const dataForPDF = useGetTickets({
    type: type,
    orderBy: orderBy,
    order: order
  });

  const [rowCountState, setRowCountState] = useState(parseInt(tickets?.data?.headers['x-total-count']));

  const handleClickView = (event) => {
    history.push(`/tickets/${event.row.id}`);
  };

  const handleClickNew = () => {
    setDialogMode('new');
    setIsDialogOpen(true);
    setActiveStep(0);
  };

  const handleDelItem = () => {
    setIsDelItemDialogOpen(true);
  };

  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      type: 'string',
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        let end = params.row.id.length;
        return <div className="rowitem">{params.row.id.slice(18,end)}</div>; // nested structure
      }
    },
    {
      field: 'title',
      headerName: 'Title',
      type: 'string',
      flex: 1,
      sortable: true
    },
    {
      field: 'asset',
      headerName: 'Asset',
      type: 'string',
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        if (params.row.asset != null && params.row.asset.name) {
          return <div className="rowitem">{params.row.asset.name}</div>; // nested structure
        } else {
          return <div className="rowitem">Asset deleted</div>
        }
      }
    },
    {
      field: 'createdBy',
      headerName: 'Author',
      type: 'string',
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return <div className="rowitem">{params.row.createdBy.firstname} {params.row.createdBy.lastname}</div>; // nested structure
      }
    },
    {
      field: 'assignedTo',
      headerName: 'Assignee',
      type: 'string',
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return <div className="rowitem">{params.row.assignedTo.firstname} {params.row.assignedTo.lastname}</div>; // nested structure
      }
    },
    {
      field: 'frequency',
      headerName: 'Frequency',
      type: 'string',
      flex: 0,
      width: 100,
      sortable: true,
      renderCell: (params) => {
        return <div className="rowitem">{params.row.frequency.toUpperCase()}</div>;
      }
    },
    {
      field: 'period',
      headerName: 'Trigger Period',
      type: 'string',
      flex: 1,
      width: 100,
      sortable: true,
      renderCell: (params) => {
        if (params.row.recurringStartPeriod && params.row.recurringEndPeriod) {
          let startDate = parseISO(params.row.recurringStartPeriod);
          let endDate = parseISO(params.row.recurringEndPeriod);
          return <div className="rowitem">{format(startDate, 'dd MMM yyyy')} - {format(endDate, 'dd MMM yyyy')}</div>
        }
      }
    },
    {
      field: 'updatedAt',
      headerName: 'Date Modified',
      type: 'string',
      flex: 1,
      width: 100,
      sortable: true,
      renderCell: (params) => {
        let date = parseISO(params.row.updatedAt);
        return <div className="rowitem">{format(date, 'dd MMM yyyy, h:mm bbb')}</div>
      }
    },
  ];

  const exportPDF = (data) => {
    const doc = new jsPDF('l', 'mm', 'a4');
    if (data) {
      let tableData = data.data.data.map(ticket => {
        let creationDate = parseISO(ticket.createdAt);
        let startDate = ticket?.recurringStartPeriod && parseISO(ticket.recurringStartPeriod);
        let endDate = ticket?.recurringEndPeriod && parseISO(ticket.recurringEndPeriod);
        let props = {
          id: ticket.id,
          title: ticket.title,
          asset: ticket.asset.name,
          createdBy: `${ticket.createdBy.firstname} ${ticket.createdBy.lastname}`,
          assignedTo: `${ticket.assignedTo.firstname} ${ticket.assignedTo.lastname}`,
          createdAt: format(creationDate, 'dd MMM yyyy, h:mm bbb'),
          frequency: ticket.frequency,
          startDate: ticket?.recurringStartPeriod && format(startDate, 'dd MMM yyyy, h:mm bbb'),
          endDate: ticket?.recurringEndPeriod && format(endDate, 'dd MMM yyyy, h:mm bbb')
        }
        return props;
      })
      let mappedData = tableData.map(Object.values);

      doc.autoTable({
        head: [['Id', 'Title', 'Asset', 'Author', 'Assignee', 'Created', 'Frequency', 'Start Date', 'End Date']],
        body: mappedData,
        margin: { top: 25 },
        theme: 'striped',
        didDrawPage: function (data) {
          // Header
          doc.setFontSize(20)
          doc.setTextColor(40)
          doc.text(`Factory Maintenance Pro - Recurring Templates`, 14, 20);

          // Footer
          let str = 'Page ' + doc.internal.getNumberOfPages()
          // Total page number plugin only available in jspdf v1.0+
          doc.setFontSize(10)

          // jsPDF 1.4+ uses getWidth, <1.4 uses .width
          var pageSize = doc.internal.pageSize
          var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
          doc.text(str, data.settings.margin.left, pageHeight - 10)
          doc.text(`Generated ${format(Date.now(), 'dd MMM yyyy, h:mm bbb')} at ${format(Date.now(), 'pp')}`, 215, pageHeight - 10);
        },
      });
      doc.save(`Factory Maintenance Pro - Recurring Templates - ${format(Date.now(), 'dd MMM yyyy')}.pdf`);
    }
  };

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      tickets?.data?.headers['x-total-count'].toString() !== undefined
        ? tickets?.data?.headers['x-total-count'].toString()
        : prevRowCountState,
    );
  }, [tickets, setRowCountState]);

  return (
    <Grid container spacing={3}>

      <Grid item xs>
        <Card>
          <CardHeader
            title={
              <>
                <Title>Recurring Templates</Title>
              </>
            }
            action={
              <>
                {!selectedItem &&
                  <>
                    <TextField
                      id="tickets-table-search-field"
                      label="Search"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <TableIcons.Search />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <TableIcons.Tune />
                          </InputAdornment>
                        )
                      }}
                      value={search}
                      onChange={(event) => {
                        setSearch(event.target.value);
                      }}
                    />
                    <Tooltip title="Refresh" placement="bottom" arrow>
                      <IconButton
                        onClick={() => {
                          tickets.refetch();
                        }}
                      >
                        <TableIcons.Sync />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Export all Work Orders to PDF" placement="bottom" arrow>
                      <IconButton
                        onClick={() => {
                          exportPDF(dataForPDF);
                        }}
                      >
                        <TableIcons.ExportToPdf />
                      </IconButton>
                    </Tooltip>
                  </>
                }
                {isAuthorized && !selectedItem &&
                  <>
                    <Tooltip title="Add new Work Order" placement="bottom" arrow>
                      <IconButton
                        onClick={() => {
                          handleClickNew();
                        }}
                      >
                        <TableIcons.Add />
                      </IconButton>
                    </Tooltip>
                  </>
                }
                {isAuthorized && selectedItem &&
                  <>
                    <Tooltip title="Delete selected work order" placement="bottom" arrow>
                      <IconButton
                        onClick={() => {
                          handleDelItem();
                        }}
                      >
                        <TableIcons.Delete />
                      </IconButton>
                    </Tooltip>
                  </>
                }
              </>
            }
          />
          <div style={{ height: tableHeight, width: '100%' }}>
            <DataGrid
              columns={columns}
              rows={tickets?.data?.data || []}
              disableColumnMenu
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={(ids) => {
                setSelectionModel((prevModel) =>
                  ids.filter((newId) => !prevModel.includes(newId))
                );
                if (ids.length === 0) {
                  setSelectedItem();
                };
                if (ids.length > 0 && ids.length < 3) {
                  setSelectedItem(tickets?.data?.data.find((row) => row.id === ids[ids.length - 1]));
                };
              }}
              selectionModel={selectionModel}
              loading={tickets.isLoading}
              pagination
              paginationMode="server"
              sortingMode="server"
              sortModel={sortModel}
              onSortModelChange={handleSortModelChange}
              rowsPerPageOptions={[5, 10, 15]}
              rowCount={rowCountState || 0}
              pageSize={limit}
              onPageSizeChange={(newPageSize) => {
                setLimit(newPageSize);
              }}
              onPageChange={(newPage) => {
                setPage(newPage);
              }}
              onRowClick={(event) => handleClickView(event)}
            />
          </div>
        </Card>
      </Grid>
      <RecurringWizard
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        dialogMode={dialogMode}
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
      />
      <DelTicket
        isDelItemDialogOpen={isDelItemDialogOpen}
        setIsDelItemDialogOpen={setIsDelItemDialogOpen}
        selectedItemId={selectedItem?.id}
        text="Do you want to delete this recurring template?"
      />
    </Grid>
  )
}

export default RecurringTemplatesTable;