import React from 'react';
import {
  Autocomplete,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';

import { ticketPriority } from '../../ticket.enums';

const DetailsForm = (props) => {
  const {
    users,
    setAssignedToId,
    setPriority
  } = props;

  return (
    <>
      <Typography variant='h6' gutterBottom>
        Assignee details
      </Typography>
      <Grid container spacing={3}>

        {/* Assignee */}
        <Grid item xs={12} sm={6}>
        <FormControl
          variant='outlined'
          fullWidth
        >
          <Autocomplete
            id='user-select-ticket-wizard'
            options={users ? users.data?.data : []}
            getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
            getOptionSelected={(option, value) => { return (option.id === value.id )}}
            renderInput={(params) =>
              <TextField
                {...params}
                required
                label='Assigned To'
              />
            }
            onChange={(event, value, reason) => {
              if (reason !== 'clear') {
                setAssignedToId(value.id);
              }
            }}
          />
        </FormControl>
        </Grid>

        { /* Priority */}
        <Grid item xs={12} sm={6}>
          <FormControl
            variant='outlined'
            fullWidth
          >
            <InputLabel id='priority-select-label'>Priority</InputLabel>
            <Select
              required
              labelId='priority-select-label'
              id='priority-select'
              onChange={(event) => { setPriority(event.target.value); }}
              label='Priority'
            >
              {
                ticketPriority.map(key => (
                  <MenuItem key={key} value={key}>{key.toUpperCase()}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}

export default DetailsForm;
