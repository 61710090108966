import React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      {new Date().getFullYear()}
      {' '}
      <Link color="inherit" href="https://technosec.net" target="_blank">
        TechnoSec Srls
      </Link>
      {'.'}
    </Typography>
  );
}

export default Copyright;
