import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import L from 'leaflet';
import 'leaflet/dist/leaflet.css'
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import {
  MapContainer,
  Marker,
  TileLayer,
  Tooltip as LeafTooltip,
  useMap
} from "react-leaflet";

import { makeStyles, useTheme } from '@mui/styles';

import {
  Box,
  Card,
  CardHeader,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';

import { BsArrowsFullscreen } from 'react-icons/bs';
import EditIcon from '@mui/icons-material/Edit';
import FactoryIcon from '@mui/icons-material/Factory';

import clsx from 'clsx';
import './map.css';
import styles from '../../styles';
import Title from '../../components/layout/Title';

import {
  useGetCompany,
  useGetCurrentUser,
  useGetPlants,
  useGetAssets,
  useGetTickets
} from '../../hooks';

import onFullScreen from '../../utils/onFullScreen';
import { randStr } from '../../utils/stringUtils';

const useStyles = makeStyles(styles);

const MarkerIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconAnchor: [12.5, 40],
  shadowAnchor: [12.5, 40]
});
L.Marker.prototype.options.icon = MarkerIcon;

const ChangeView = ({ center, zoom }) => {
  const map = useMap();
  map.setView(center, zoom);
  return null;
};

const checkForValidCoordinates = (plant) => {
  return !isNaN(plant.location.coordinates[1]) && !isNaN(plant.location.coordinates[0])
      && parseFloat(plant.location.coordinates[1]) !== 0 && parseFloat(plant.location.coordinates[0]) !== 0;
};

const Home = () => {

  const history = useHistory();

  const classes = useStyles();
  const theme = useTheme();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const isBreakpointsDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBreakpointsUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const [totalAssetsCount, setTotalAssetsCount] = useState();
  const [totalTicketsCount, setTotalTicketsCount] = useState();
  const [openTicketsCount, setOpenTicketsCount] = useState();
  const [newTicketsCount, setNewTicketsCount] = useState();
  const [notCompletedTicketsCount,] = useState(openTicketsCount && !newTicketsCount ?
      openTicketsCount
    : openTicketsCount && newTicketsCount ?
      (parseInt(openTicketsCount)+parseInt(newTicketsCount)).toString()
    : !openTicketsCount && newTicketsCount ?
      newTicketsCount
    : null
  );
  const [totalPlantsCount, setTotalPlantsCount] = useState();

  const assetsOnSuccess = (data) => {
    setTotalAssetsCount(data.headers['x-total-count'].toString());
  };

  const ticketsOnSuccess = (data) => {
    setTotalTicketsCount(data.headers['x-total-count'].toString());
  };

  const openTicketsOnSuccess = (data) => {
    setOpenTicketsCount(data.headers['x-total-count'].toString());
  };

  const newTicketsOnSuccess = (data) => {
    setNewTicketsCount(data.headers['x-total-count'].toString());
  };

  const plantsOnSuccess = (data) => {
    setTotalPlantsCount(data.headers['x-total-count'].toString());
  };

  const currentUser = useGetCurrentUser();
  const isAuthorized = true ? currentUser?.data?.role === 'admin' : false;

  const company = useGetCompany({ id: currentUser?.data?.companyId });
  const plants = useGetPlants({}, plantsOnSuccess);
  const assets = useGetAssets({}, assetsOnSuccess);
  const tickets = useGetTickets({ type: 'single' }, ticketsOnSuccess);
  const openTickets = useGetTickets({ type: 'single', status: 'open' }, openTicketsOnSuccess);
  const newTickets = useGetTickets({ type: 'single', status: 'new' }, newTicketsOnSuccess);

  const [mapCenter,] = useState([0, 0]);

  const [open, setOpen] = useState({});

  const handleClickAsset = (plantId) => {
    history.push(`/plants/${plantId}`);
  };

  const getMapCenter = useCallback(() => {
    const plantWithValidCoordinates = plants && plants?.data?.data?.find(checkForValidCoordinates);
    return plantWithValidCoordinates
        ? [plantWithValidCoordinates.location.coordinates[1], plantWithValidCoordinates.location.coordinates[0]]
        : [0, 0];
  }, [plants])

  const isLoading = (currentUser.isLoading
    && assets.isLoading
    && tickets.isLoading
    && openTickets.isLoading
  );


  return (
    isLoading ? (
      <LinearProgress />
    ) : (
      <Grid container spacing={3}>

        {/* Company info */}
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Card>
            <CardHeader
              title={
                <>
                  <Title>Company</Title>
                  <Typography component='p' variant='h4'>
                    {company?.data?.name || null}
                  </Typography>
                  <Typography color='textSecondary' className={classes.assetContext}>
                    {company?.data?.description || null}
                  </Typography>
                </>
              }
              action={
                <>
                  {isAuthorized &&
                  <Tooltip title='Edit organisation' placement='bottom' arrow>
                    <IconButton
                      variant='outlined'
                      //color='textSecondary'
                      //onClick={(event, row) => history.push(`/assets/${routerParams.id}/edit`)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  }
                </>
              }
            />
          </Card>
        </Grid>

        {/* Total Plants number */}
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <Card>
            <CardHeader
              title={
                <>
                  <Title>Plants</Title>
                  <Typography component='p' variant='h4'>
                    {totalPlantsCount || 0}
                  </Typography>
                  <Typography color='textSecondary' className={classes.assetContext}>
                    Total
                  </Typography>
                </>
              }
            />
          </Card>
        </Grid>

        {/* Assets number */}
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <Card>
            <CardHeader
              title={
                <>
                  <Title>Assets</Title>
                  <Typography component='p' variant='h4'>
                    {totalAssetsCount || 0}
                  </Typography>
                  <Typography color='textSecondary' className={classes.assetContext}>
                    Total
                  </Typography>
                </>
              }
            />
          </Card>
        </Grid>

        {/* Total Work Orders number */}
        <Grid item xs={12} sm={12} md={4} lg={2}>
          <Card>
            <CardHeader
              title={
                <>
                  <Title>Work Orders</Title>
                  <Typography component='p' variant='h4'>
                    {openTicketsCount && newTicketsCount ?
                      (parseInt(openTicketsCount)+parseInt(newTicketsCount)).toString()
                    : openTicketsCount && !newTicketsCount ?
                      openTicketsCount
                    : !openTicketsCount && newTicketsCount ?
                      newTicketsCount
                    : null} / {totalTicketsCount}
                  </Typography>
                  <Typography color='textSecondary' className={classes.assetContext}>
                    Open / Total
                  </Typography>
                </>
              }
            />
          </Card>
        </Grid>

        {/* Map */}
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Card
            className={!isBreakpointsDownSm && fixedHeightPaper}
            style={!isBreakpointsDownSm ? { height: '600px', width: '100%', margin: 'auto' } : null}
          >
            <CardHeader
              title={
                <Title>General Map</Title>
              }
              action={
                <>
                  <Box sx={{ display: 'flex', p:1 }}>
                    <Box sx={{ p: 1, flexGrow: 1 }}>
                      <Tooltip title='Fullscreen' placement='bottom' arrow>
                        <IconButton
                          variant='outlined'
                          size='small'
                          onClick={() => {
                            onFullScreen('#worldmap-container')
                          }}
                        >
                          <BsArrowsFullscreen />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </>
              }
            />
            <MapContainer
              id='worldmap-container'
              center={mapCenter}
              zoom={4}
              attributionControl={false}
              style={!isBreakpointsDownSm ? {
                height: '790px', width: '100%', margin: 'auto'
              } : {
                height: '200px', width: '100%', margin: 'auto'
              }}
            >
              <ChangeView center={getMapCenter()} zoom={4} />
              {plants && plants?.data?.data?.map(plant => (
                checkForValidCoordinates(plant) &&
                <Marker
                  key={randStr('key_')}
                  position={[plant.location.coordinates[1], plant.location.coordinates[0]]}
                  eventHandlers={{
                    click: () => {
                      handleClickAsset(plant.id);
                    },
                  }}
                >
                  <LeafTooltip>{plant.name}</LeafTooltip>
                </Marker>
              ))}
              <TileLayer
              url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
              className='map-tiles'
              />
            </MapContainer>
          </Card>
        </Grid>

        {/* Plants List */}
        <Grid item xs={12} sm={12} md={4} lg={4}>
            <Card
              className={!isBreakpointsDownSm && fixedHeightPaper}
              style={isBreakpointsUpLg ? { height: '600px', width: '100%', margin: 'auto' } : null}
            >
              <CardHeader
                title={
                  <>
                    <Title>Plants</Title>
                  </>
                }
              />
              <List component="div" disablePadding>
                {plants?.data?.data?.map(plant => (
                  <ListItem
                    key={randStr('key_')}
                    className={classes.nested}
                    button
                    onClick={() => handleClickAsset(plant.id)}
                  >
                    <ListItemIcon>
                      <FactoryIcon />
                    </ListItemIcon>
                    <ListItemText primary={plant.name} />
                  </ListItem>
                ))}
              </List>
            </Card>
          </Grid>

      </Grid>
    )
  )
}

export default Home;
