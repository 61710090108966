import React, { useRef, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';

import { useTheme } from '@mui/styles';

import useStyles from './styles';

import {
  useGetCurrentUser,
  useMutateAddComment,
  useMutateEditTicket
} from '../../../hooks';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});


const EditSpecialTools = (props) => {

  const {
    isSpecialToolsDialogOpen,
    setIsSpecialToolsDialogOpen,
    ticket
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isBreakpointsDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBreakpointsUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const formRef = useRef();

  const [specialTools, setSpecialTools] = useState(ticket?.data?.jobPlan);

  const currentUser = useGetCurrentUser();
  const addCommentMutation = useMutateAddComment();
  const editTicketMutation = useMutateEditTicket();

  const editTicket = (tools) => {
    editTicketMutation.mutate({
      id: ticket?.data?.id,
      body: {
        specialTools: tools
      },
    },
    {
      onSuccess: (data) => {
        ticket.refetch();
        handleCloseDialog(data);
        if (ticket?.data?.type === 'single') {
          // addCommentMutation.mutate({
          //   body: {
          //     text: `Auto-Comment: Ticket updated`,
          //     companyId: currentUser?.data?.companyId,
          //     createdById: currentUser?.data?.id,
          //     ticketId: ticket?.data?.id
          //   }
          // });
        };
      }
    });
  };

  const handleCloseDialog = (data) => {
    setIsSpecialToolsDialogOpen(false);
    setSpecialTools(data.specialTools || ticket?.data?.specialTools);
  };

  const handleOnSubmit = () => {
    if (formRef.current.reportValidity()) {
      editTicket(specialTools);
    }
  };

  return (
    <Dialog
      fullScreen={isBreakpointsDownSm}
      fullWidth={!isBreakpointsDownSm}
      style={{ minWidth: !isBreakpointsDownSm ? 600 : 200 }}
      open={isSpecialToolsDialogOpen}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Typography component='h1' variant='h4' align='center'>
          Edit Special Tools
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <form ref={formRef}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                required
                multiline
                rows={8}
                id="jobplan"
                label='Add special tools required for the planned operations'
                variant='outlined'
                fullWidth
                value={specialTools}
                onChange={(event) => { setSpecialTools(event.target.value) }}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          className={classes.button}
          onClick={handleCloseDialog}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          className={classes.button}
          onClick={handleOnSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditSpecialTools;
