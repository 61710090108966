// Protected Components
import {
  Home,
  AssetsPage,
  AssetPage,
  TicketsPage,
  TicketPage,
  PlantsPage,
  PlantPage,
  ZonesPage,
  ZonePage,
  UsersPage,
  PartsPage,
  QRScannerPage
} from '../pages';

// Protected Routes
const protectedRoutes = [
  {
    path: '/warehouse',
    exact: true,
    breadcrumb: 'Warehouse',
    component: PartsPage
  },
  {
    path: '/users',
    exact: true,
    breadcrumb: 'Users',
    component: UsersPage
  },
  {
    path: '/plants/:id',
    component: PlantPage
  },
  {
    path: '/plants',
    exact: true,
    breadcrumb: 'Plants',
    component: PlantsPage
  },
  {
    path: '/zones',
    exact: true,
    breadcrumb: 'Zones',
    component: ZonesPage
  },
  {
    path: '/tickets/:id',
    component: TicketPage
  },
  {
    path: '/tickets',
    exact: true,
    breadcrumb: 'Work Orders',
    component: TicketsPage
  },
  {
    path: '/assets/:id',
    component: AssetPage
  },
  {
    path: '/assets',
    exact: true,
    breadcrumb: 'Assets',
    component: AssetsPage
  },
  {
    path: '/plants/:id',
    component: PlantPage
  },
  {
    path: '/zones/:id',
    component: ZonePage
  },
  {
    path: ['/qr'],
    exact: true,
    breadcrumb: 'QR Scanner',
    component: QRScannerPage
  },
  {
    path: '/',
    exact: true,
    breadcrumb: 'Home',
    component: Home
  }
];

export default protectedRoutes;
