import axiosInstance from '../axios.instance';

export const apiAuth = async (variables) => {
  let config = {
    method: 'post',
    url: '/login',
    data: {
      email: variables.email,
      password: variables.password
    },
  };
  const response = await axiosInstance(config);
  return response.data;
};