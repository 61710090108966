import axiosInstance from '../axios.instance';

export const apiAddAttachment = async (variables) => {
  let config = {
    method: 'post',
    url: `/attachments`,
    data: variables.body
  };
  const response = await axiosInstance(config);
  return response.data;
};

export const apiGetAttachments = async (variables) => {
  let config = {
    method: 'get',
    url: '/attachments',
    params: variables
  };
  const { data, headers } = await axiosInstance(config);
  return {
    data,
    headers
  };
};

export const apiGetAttachment = async (variables) => {
  let config = {
    method: 'get',
    url: `/attachments/${variables.id}`
  };
  const response = await axiosInstance(config);
  return response.data;
};

export const apiEditAttachment = async (variables) => {
  let config = {
    method: 'put',
    url: `/attachments/${variables.id}`,
    data: variables.body
  };
  const response = await axiosInstance(config);
  return response.data;
};

export const apiDelAttachment = async (variables) => {
  let config = {
    method: 'delete',
    url: `/attachments/${variables.id}`
  }
  const response = await axiosInstance(config);
  return response.data;
};