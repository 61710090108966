import React, { useRef, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';

import { useTheme } from '@mui/styles';

import useStyles from './styles';

import {
  useGetCurrentUser,
  useMutateAddComment,
  useMutateEditTicket
} from '../../../hooks';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});


const EditDuration = (props) => {

  const {
    isDurationDialogOpen,
    setIsDurationDialogOpen,
    ticket
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isBreakpointsDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBreakpointsUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const formRef = useRef();

  const [estimatedDuration, setEstimatedDuration] = useState(ticket?.data?.estimatedDuration);

  const currentUser = useGetCurrentUser();
  const addCommentMutation = useMutateAddComment();
  const editTicketMutation = useMutateEditTicket();

  const editTicket = (estimatedDuration) => {
    editTicketMutation.mutate({
      id: ticket?.data?.id,
      body: {
        estimatedDuration: estimatedDuration
      },
    },
    {
      onSuccess: (data) => {
        ticket.refetch();
        handleCloseDialog(data);
        if (ticket?.data?.type === 'single') {
          // addCommentMutation.mutate({
          //   body: {
          //     text: `Auto-Comment: Ticket updated`,
          //     companyId: currentUser?.data?.companyId,
          //     createdById: currentUser?.data?.id,
          //     ticketId: ticket?.data?.id
          //   }
          // });
        };
      }
    });
  };

  const handleCloseDialog = (data) => {
    setIsDurationDialogOpen(false);
    setEstimatedDuration(data.estimatedDuration || ticket?.data?.estimatedDuration);
  };

  const handleOnSubmit = () => {
    if (formRef.current.reportValidity()) {
      editTicket(estimatedDuration);
    }
  };

  return (
    <Dialog
      fullScreen={isBreakpointsDownSm}
      fullWidth={!isBreakpointsDownSm}
      style={{ minWidth: !isBreakpointsDownSm ? 600 : 200 }}
      open={isDurationDialogOpen}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Typography component='h1' variant='h4' align='center'>
          Edit Duration
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <form ref={formRef}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                required
                id='duration'
                label='Duration'
                variant='outlined'
                type='number'
                fullWidth
                value={estimatedDuration}
                onChange={(event) => { setEstimatedDuration(event.target.value) }}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          className={classes.button}
          onClick={handleCloseDialog}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          className={classes.button}
          onClick={handleOnSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditDuration;
