import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { makeStyles } from '@mui/styles';

import {
  Card,
  CardHeader,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent
} from '@mui/lab';

import {
  formatDistanceToNow,
  parseISO
} from 'date-fns';

import styles from '../layout/styles';
import TableIcons from '../../components/layout/TableIcons';

import {
  useGetCurrentUser,
  useGetComments
} from '../../hooks';
import DelComment from './dialogs/DelComment';

const useStyles = makeStyles(styles)

const Comments = () => {

  const classes = useStyles();

  const routerParams = useParams();
  const currentUser = useGetCurrentUser();

  const comments = useGetComments({ ticketId: routerParams.id });

  const [selectedCommentId, setSelectedCommentId] = useState();
  const [isDelCommentDialogOpen, setIsDelCommentDialogOpen] = useState(false);

  const dateToNow = (date) => {
    const parsed = parseISO(date);
    const toNow = formatDistanceToNow(parsed);
    return toNow;
  };

  const handleDelComment = (id) => {
    setIsDelCommentDialogOpen(true);
    setSelectedCommentId(id);
  };

  return (
    <>
      <Timeline>
        {comments && comments?.data?.data?.map(comment => (
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography color="textSecondary">comment added</Typography>
              <Typography color="textSecondary">{dateToNow(comment.createdAt)} ago</Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
              <TimelineContent>
                <Card elevation={3} className={classes.paper} sx={{ userSelect: 'text' }}>
                  <CardHeader
                    title={
                      <>
                        <Typography variant="h6" component="h1">
                          {comment?.createdBy?.firstname} {comment?.createdBy?.lastname}
                        </Typography>
                        <Typography color='textSecondary'>
                          {comment?.text}
                        </Typography>
                      </>
                    }
                    action={
                      comment?.createdById === currentUser?.data?.id && (
                        <>
                          <Tooltip title="Delete feedback" placement="bottom" arrow>
                            <IconButton
                              variant="outlined"
                              onClick={() => handleDelComment(comment?.id)}
                            >
                              <TableIcons.Delete />
                            </IconButton>
                          </Tooltip>
                        </>
                      )
                    }
                  />
                </Card>
              </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
      <DelComment
        isDelCommentDialogOpen={isDelCommentDialogOpen}
        setIsDelCommentDialogOpen={setIsDelCommentDialogOpen}
        selectedCommentId={selectedCommentId}
        setSelectedCommentId={setSelectedCommentId}
      />
    </>
  );
}

export default Comments;
