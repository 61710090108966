import React, { useRef, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';

import { useTheme } from '@mui/styles';

import useStyles from './styles';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const NewSearch = (props) => {

  const {
    label,
    isNewSearchDialogOpen,
    setIsNewSearchDialogOpen,
    search,
    setSearch
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isBreakpointsDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBreakpointsUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const [text, setText] = useState()

  const formRef = useRef();

  const handleCloseDialog = () => {
    setIsNewSearchDialogOpen(false);
  };

  const handleOnSubmit = () => {
    if (formRef.current.reportValidity()) {
      setSearch(text);
      setIsNewSearchDialogOpen(false);
    }
  }

  return (
    <Dialog
      fullScreen={isBreakpointsDownSm}
      fullWidth={!isBreakpointsDownSm}
      style={{ minWidth: !isBreakpointsDownSm ? 600 : 200 }}
      open={isNewSearchDialogOpen}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Typography component='h1' variant='h4' align='center'>
          New Search
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <form ref={formRef}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                rows={1}
                id="new-search"
                label={label}
                variant="outlined"
                fullWidth
                value={text ? text : ''}
                onChange={(event) => { setText(event.target.value) }}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          className={classes.button}
          onClick={handleCloseDialog}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          className={classes.button}
          onfocusVisible
          focusRipple={true}
          onClick={handleOnSubmit}
        >
          Search
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NewSearch;
