const onFullScreen = (el) => {
  let element = document.querySelector(el);
  if (element !== null) {
    let requestFullScreen = element.requestFullscreen
      || element.mozRequestFullScreen
      || element.webkitRequestFullScreen
      || element.msRequestFullscreen;
    let cancelFullScreen = document.exitFullscreen
      || document.mozCancelFullScreen
      || document.webkitExitFullscreen
      || document.msExitFullscreen;

    if(!document.fullscreenElement
      && !document.mozFullScreenElement
      && !document.webkitFullscreenElement
      && !document.msFullscreenElement) {
        requestFullScreen.call(element);
    }
    else {
      cancelFullScreen.call(document);
    }
  }
}

export default onFullScreen;
