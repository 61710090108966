import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';

import { apiGetInfo } from '../../api/endpoints';

const useGetInfo = (onSuccess) => {

  /* Info */
  const { enqueueSnackbar } = useSnackbar();
  const notify = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant
    });
  };

  return useQuery(['notification'], () => apiGetInfo(), {
    onSuccess: onSuccess,
    onError: (error) => {
      notify('Something went wrong, please try again.', 'error');
    }
  });
};

export default useGetInfo;