import React, { useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { QrReader } from 'react-qr-reader';
import { Base64 } from 'js-base64';
import { useSnackbar } from 'notistack';

//import useSound from 'use-sound';
//import toasty from '../../sounds/toasty.mp3';

import {
  Box,
  Button,
  Grid,
  Paper,
  Typography
} from '@mui/material';

import { makeStyles } from '@mui/styles';
import styles from '../../components/layout/styles';
import Title from '../../components/layout/Title';

import {
  useGetAsset
} from '../../hooks';

const useStyles = makeStyles(styles);


const QRScannerPage = () => {

  const classes = useStyles();

  const previewStyle = {
    height: '100%',
    width: '100%',
  }

  const history = useHistory();

  const [data, setData] = useState();

  /* Notification */
  const { enqueueSnackbar } = useSnackbar();
  const notify = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant
    });
  };

  const assetOnSuccess = (data) => {
    notify('QR decoded.', 'success');
  };

  const asset = useGetAsset({ id: data }, assetOnSuccess);

  const handleScan = (result) => {
    if (!!result?.text) {
      try {
        let decodedData = Base64.decode(result?.text);
        let parsedData = JSON.parse(decodedData)
        if (parsedData?.assetId) {
          setData(parsedData?.assetId);
        } else {
          console.log('Not a valid JSON');
          setData();
          notify('Not a valid QR.', 'error');
        }
      } catch(err) {
        console.log('Error: Not a valid JSON');
        setData();
        notify('Error: Not a valid QR.', 'error');
      }
    }
  };

  return (
    <Grid container spacing={3}>

      {/* QR Scanner */}
      <Grid item xs={12} sm={5}>
        <Paper className={classes.paper}>
          <Title>QR Scanner</Title>

          <QrReader
            delay={300}
            constraints={{ facingMode: 'environment' }}
            onResult={(result) => { handleScan(result); }}
            style={previewStyle}
          />

        </Paper>
      </Grid>
      {/* Decoded data */}
      <Grid item xs={12} sm={7}>
        <Paper className={classes.paper}>
          <Title>Decoded Data</Title>
          {asset?.data && asset?.isSuccess ? (
            <>
              <Typography component='p' variant='h4'>
                {asset?.data?.name}
              </Typography>
              <Typography color='textSecondary' className={classes.assetContext}>
                {asset?.data?.plant?.name} / {asset?.data?.zone?.name}
              </Typography>
              <Box display='flex' p={1}>
                <Box p={1}>
                  <Button
                    variant='outlined'
                    component={RouterLink}
                    to={{pathname: `/assets/${asset?.data.id}`}}
                  >
                    Open Asset
                  </Button>
                </Box>
                {asset?.hasAr && (
                  <Box p={1} flexGrow={1}>
                    <Button
                      variant='outlined'
                      onClick={() => {
                        history.push('/');
                      }}
                      component={RouterLink}
                      to={{pathname: `/ar/index.html?project=${asset.project_id}&asset=${asset.asset_id}&asset=${asset.id}`}}
                      target='_blank'
                    >
                      Open in AR
                    </Button>
                  </Box>
                )}
              </Box>
            </>
          ) : (
            <Typography component='p' variant='h4'>
              No result
            </Typography>
          )}
        </Paper>
      </Grid>
    </Grid>
  )
}

export default QRScannerPage;
