import React from 'react';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { ticketPriority, recurringFrequency } from '../../ticket.enums';


const MainForm = (props) => {
  const {
    title,
    setTitle,
    description,
    setDescription,
    recurringStartPeriod,
    setRecurringStartPeriod,
    recurringEndPeriod,
    setRecurringEndPeriod,
    estimatedDuration,
    setEstimatedDuration,
    priority,
    setPriority,
    frequency,
    setFrequency
  } = props;

  return (
    <>
      <Typography variant='h6' gutterBottom>
        Main info
      </Typography>

      { /* Title */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            required
            id='title'
            label='Title'
            variant='outlined'
            fullWidth
            value={title ? title : ''}
            onChange={(event) => { setTitle(event.target.value) }}
          />
        </Grid>

        { /* Description */}
        <Grid item xs={12}>
          <TextField
            required
            multiline
            rows={6}
            id='description'
            label='Description'
            variant='outlined'
            fullWidth
            value={description ? description : ''}
            onChange={(event) => { setDescription(event.target.value) }}
          />
        </Grid>

        { /* Start date */}
        <Grid item xs={12} sm={6}>
          <FormControl variant='outlined' fullWidth>
            <DateTimePicker
              inputVariant='outlined'
              label='Start date'
              variant='dialog'
              value={recurringStartPeriod}
              onChange={(event) => { setRecurringStartPeriod(event); }}
              renderInput={(params) => <TextField required {...params} />}
            />
          </FormControl>
        </Grid>

        { /* End date */}
        <Grid item xs={12} sm={6}>
          <FormControl variant='outlined' fullWidth>
            <DateTimePicker
              inputVariant='outlined'
              label='End date'
              variant='dialog'
              value={recurringEndPeriod}
              onChange={(event) => { setRecurringEndPeriod(event); }}
              renderInput={(params) => <TextField required {...params} />}
            />
          </FormControl>
        </Grid>

        { /* Estimated duration */}
        <Grid item xs={12}>
          <TextField
            required
            id='estimatedDuration'
            label='Estimated Duration'
            variant='outlined'
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">hours</InputAdornment>,
            }}
            value={estimatedDuration ? estimatedDuration : ''}
            onChange={(event) => { setEstimatedDuration(event.target.value) }}
          />
        </Grid>

        { /* Priority */}
        <Grid item xs={12} sm={6}>
          <FormControl
            variant='outlined'
            style={{ minWidth: 264, maxWidth: 400 }}
          >
            <InputLabel id='priority-select-label'>Priority</InputLabel>
            <Select
              required
              labelId='priority-select-label'
              id='priority-select'
              onChange={(event) => { setPriority(event.target.value); }}
              label='Priority'
            >
              {
                ticketPriority.map(key => (
                  <MenuItem key={key} value={key}>{key.toUpperCase()}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>

        { /* Frequency */}
        <Grid item xs={12} sm={6}>
          <FormControl
            variant='outlined'
            style={{ minWidth: 264, maxWidth: 400 }}
          >
            <InputLabel id='frequency-select-label'>Frequency</InputLabel>
            <Select
              required
              labelId='frequency-select-label'
              id='frequency-select'
              onChange={(event) => { setFrequency(event.target.value); }}
              label='Frequency'
            >
              {
                recurringFrequency.map(key => (
                  <MenuItem key={key} value={key}>{key.toUpperCase()}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>

      </Grid>
    </>
  );
}

export default MainForm;
