import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';


const exportQRtoPDF = (element, asset) => {
  const qrCodeCanvas = document.getElementById(element);
  // Default export is a4 paper, portrait, using milimeters for units
  const doc = new jsPDF();

  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  const imgSquareSide = 120;
  const marginX = (pageWidth - imgSquareSide) / 2;
  const marginY = 90;

  html2canvas(qrCodeCanvas, {
    scale: 5,
    useCORS: true,
    allowTaint: true,
    logging: true,
  }).then(canvas => {
    let svgFromCanvas = canvas.toDataURL('image/png');

    doc.rect(marginX - 8, 19, 120, 24)
    doc.setFontSize(14);
    doc.setFont('', 'bold', '');
    doc.text(15, 25, 'Asset:');
    doc.text(15, 30, 'Zone:');
    doc.text(15, 35, 'Plant:');
    doc.setFont('', 'normal', '');
    doc.text(marginX, 25, asset.name);
    doc.text(marginX, 30, asset.zone.name);
    doc.text(marginX, 35, asset.plant.name);

    doc.setFontSize(10);
    doc.text(145, 275, 'Factory Maintenance Pro');
    doc.text(145, 280, `Copyright © ${new Date().getFullYear()} TechnoSec Srls`);

    doc.addImage(svgFromCanvas, 'PNG', marginX, marginY, imgSquareSide, imgSquareSide);
    doc.save('Factory Maintenance Pro - QR - ' + asset.name + '.pdf');

  });
}

export default exportQRtoPDF;
