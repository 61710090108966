import React from 'react';

import {
  Box,
  FormControl,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  NativeSelect,
  Tooltip
} from '@mui/material';

import { MdSettings } from 'react-icons/md';
import { BsArrowsFullscreen } from 'react-icons/bs';
import { BiMapPin } from 'react-icons/bi';

import { withStyles } from '@mui/styles';

import onFullScreen from '../../utils/onFullScreen';


const PlantMapPanoActions = (props) => {

  const {
    setDialogMode,
    anchorEl,
    setAnchorEl,
    zoneId,
    setZoneId,
    zones,
    isAuthorized
  } = props;

  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));

  /* Check zone presence */
  if (zones && zones.length > 0) {
    return (
      <>
        <Box display='flex' p={1}>

          {/* Zone selection */}
          <Box p={1} flexGrow={1}>
            <FormControl
              variant='outlined'
              style={{ minWidth: 120, maxWidth: 300 }}
            >
              <NativeSelect
                id='zone-select-helper'
                value={zoneId ? zoneId : ''}
                onChange={(event) => {
                  setZoneId(event.target.value);
                }}
              >
                <option value='' disabled>
                  Select zone
                </option>
                {
                  zones.map(zone => (
                    <option key={zone.id} value={zone.id}>{zone.name}</option>
                  ))
                }
              </NativeSelect>
            </FormControl>
          </Box>

          {/* Fullscreen button */}
          <Box p={1}>
            <Tooltip title='Fullscreen' placement='bottom' arrow>
              <IconButton
                variant='outlined'
                size='small'
                onClick={() => {
                  onFullScreen('#pannellum')
                }}
              >
                <BsArrowsFullscreen />
              </IconButton>
            </Tooltip>
          </Box>

          {isAuthorized ?
            <Box p={1}>
              {/* Configure button menu */}
              <Tooltip title='Settings' placement='bottom' arrow>
                <IconButton
                  id='button-conf'
                  variant='outlined'
                  size='small'
                  aria-haspopup='true'
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                  }}
                >
                  <MdSettings />
                </IconButton>
              </Tooltip>
              <StyledMenu
                id='menu-conf-plant-viewer'
                anchorEl={anchorEl}
                keepMounted
                open={anchorEl}
                onClose={() => {
                  setAnchorEl(false);
                }}
              >
                <MenuItem>
                  <ListItemIcon
                    onClick={() => {
                      setDialogMode('hotpost');
                      setAnchorEl(false);
                    }}
                  >
                    <BiMapPin />
                  </ListItemIcon>
                  <ListItemText
                    primary='Manage Hotspots'
                    onClick={() => {
                      setDialogMode('hotspot');
                      setAnchorEl(false);
                    }}
                  />
                </MenuItem>

              </StyledMenu>
            </Box>
          : null}
        </Box>
      </>
    );
  } else {
    return null;
  };
};

export default PlantMapPanoActions;
