import React, { useRef, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Typography,
  useMediaQuery
} from '@mui/material';

import { useTheme } from '@mui/styles';

import useStyles from './styles';

import {
  useGetCurrentUser,
  useMutateAddComment,
  useMutateEditTicket
} from '../../../hooks';

import { recurringFrequency } from '../ticket.enums';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});


const EditFrequency = (props) => {

  const {
    isFrequencyDialogOpen,
    setIsFrequencyDialogOpen,
    ticket
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isBreakpointsDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBreakpointsUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const formRef = useRef();

  const [frequency, setFrequency] = useState(ticket?.data?.frequency);

  const currentUser = useGetCurrentUser();
  const addCommentMutation = useMutateAddComment();
  const editTicketMutation = useMutateEditTicket();

  const editTicket = (frequency) => {
    editTicketMutation.mutate({
      id: ticket?.data?.id,
      body: {
        frequency: frequency
      },
    },
    {
      onSuccess: (data) => {
        ticket.refetch();
        handleCloseDialog(data);
      }
    });
  };

  const handleCloseDialog = (data) => {
    setIsFrequencyDialogOpen(false);
    setFrequency(data.frequency || ticket?.data?.frequency);
  };

  const handleOnSubmit = () => {
    if (formRef.current.reportValidity()) {
      editTicket(frequency);
    }
  }

  return (
    <Dialog
      fullScreen={isBreakpointsDownSm}
      fullWidth={!isBreakpointsDownSm}
      style={{ minWidth: !isBreakpointsDownSm ? 600 : 200 }}
      open={isFrequencyDialogOpen}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Typography component='h1' variant='h4' align='center'>
          New Frequency
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <form ref={formRef}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl
                variant='outlined'
                className={classes.formControl}
                fullWidth
              >
                <InputLabel id='ticket-frequency-select-label'>Frequency</InputLabel>
                <Select
                  required
                  labelId='ticket-frequency-select-label'
                  id='ticket-frequency-select'
                  value={frequency}
                  onChange={(event) => { setFrequency(event.target.value); }}
                  label='Frequency'
                >
                  {
                    recurringFrequency.map(key => (
                      <MenuItem key={key} value={key}>{key.toUpperCase()}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          className={classes.button}
          onClick={handleCloseDialog}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          className={classes.button}
          onClick={handleOnSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditFrequency;
