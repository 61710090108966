import React, { useEffect, useState } from 'react';
import {
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField,
  Typography
} from '@mui/material';

const MainForm = (props) => {
  const {
    currentUser,
    email,
    setEmail,
    role,
    setRole,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword
  } = props;

  const [isNotValid, setIsNotValid] = useState(false);

  useEffect(() => {
    if (password && confirmPassword) {
      if (password !== confirmPassword) {
        setIsNotValid(true);
      } else {
        setIsNotValid(false);
      };
    };
  }, [password, confirmPassword])

  return (
    <>
      <Typography variant='h6' align='center' gutterBottom>
        Main info
      </Typography>
      <Grid container spacing={3}>

        {/* role */}
        <Grid item xs={12}>
          <FormControl required fullWidth>
            <FormLabel id="role-controlled-radio-buttons-group">Role</FormLabel>
            <RadioGroup
              required
              row
              aria-labelledby="role-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={role}
              onChange={(event) => { setRole(event.target.value); }}
            >
              {(currentUser?.data?.role === 'admin') ? (
                <FormControlLabel value="admin" control={<Radio />} label="Admin" />
              ) : (currentUser?.data?.role === 'superadmin') ? (
                <>
                  <FormControlLabel value="superadmin" control={<Radio />} label="SuperAdmin" />
                  <FormControlLabel value="admin" control={<Radio />} label="Admin" />
                </>
              ) : null}
              <FormControlLabel value="manager" control={<Radio />} label="Manager" />
              <FormControlLabel value="engineer" control={<Radio />} label="Engineer" />
              <FormControlLabel value="external" control={<Radio />} label="External" />
            </RadioGroup>
          </FormControl>
        </Grid>

        {role && role !== 'external' && (
          <>
            {/* email */}
            <Grid item xs={12}>
              <TextField
                required
                id='email'
                label='E-Mail'
                variant='outlined'
                fullWidth
                value={email ? email : ''}
                onChange={(event) => { setEmail(event.target.value) }}
              />
            </Grid>

            {/* password */}
            <Grid item xs={12}>
              <TextField
                error={isNotValid}
                required
                id="password"
                label="Password"
                type="password"
                variant="outlined"
                fullWidth
                value={password ? password : ''}
                onChange={(event) => { setPassword(event.target.value) }}
              />
            </Grid>

            {/* confirm password */}
            <Grid item xs={12}>
              <TextField
                error={isNotValid}
                required
                id="confirm-password"
                label="Confirm Password"
                type="password"
                variant="outlined"
                fullWidth
                value={confirmPassword ? confirmPassword : ''}
                onChange={(event) => { setConfirmPassword(event.target.value) }}
                helperText={ isNotValid ? `Passwords do not match` : null}
              />
            </Grid>
          </>
        )}

      </Grid>
    </>
  );
}

export default MainForm;
