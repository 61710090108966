import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';

import { apiGetCompany } from '../../api/endpoints';

const useGetCompany = (variables, onSuccess) => {

  /* Notification */
  const { enqueueSnackbar } = useSnackbar();
  const notify = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant
    });
  };

  return useQuery(['company', variables], () => apiGetCompany(variables), {
    enabled: !!variables.id,
    onSuccess: onSuccess,
    onError: (error) => {
      notify('error', 'Something went wrong, please try again.');
    }
  });
};

export default useGetCompany;