import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import { useSnackbar } from 'notistack';

import { userState } from '../../atoms';
import { apiGetCurrentUser } from '../../api/endpoints';

const useGetCurrentUser = (variables) => {
  const [user, setUser] = useRecoilState(userState);

  /* Notification */
  const { enqueueSnackbar } = useSnackbar();
  const notify = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant
    });
  };

  return useQuery(['currentUser', variables], () => apiGetCurrentUser(variables), {
    onSuccess: (data) => {
      setUser({
        ...user,
        id: data?.id,
        firstname: data?.firstname,
        lastname: data?.lastname,
        email: data?.email,
        role: data?.role,
        companyId: data?.companyId,
        phoneNumber: data?.phoneNumber,
        description: data?.description,
        imgUrl: data?.imgUrl
      });
    },
    onError: (error) => {
      notify('Something went wrong, please try again.', 'error');
    }
  });
};

export default useGetCurrentUser;