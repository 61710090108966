import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';

import { apiGetAttachment } from '../../api/endpoints';

const useGetAttachment = (variables, onSuccess) => {

  /* Notification */
  const { enqueueSnackbar } = useSnackbar();
  const notify = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant
    });
  };

  return useQuery(['attachment', variables], () => apiGetAttachment(variables), {
    enabled: !!variables.id,
    onSuccess: onSuccess,
    onError: (error) => {
      notify('Something went wrong, please try again.', 'error');
    }
  });
};

export default useGetAttachment;