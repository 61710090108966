import React from 'react';
import {
  Grid,
  TextField,
  Typography
} from '@mui/material';

const MainForm = (props) => {
  const {
    name,
    setName,
    description,
    setDescription,
    customId,
    setCustomId
  } = props;

  return (
    <>
      <Typography variant='h6' gutterBottom>
        Main info
      </Typography>
      <Grid container spacing={3}>
        {/* Name */}
        <Grid item xs={12}>
          <TextField
            required
            id='name'
            label='Name'
            variant='outlined'
            fullWidth
            value={name ? name : ''}
            onChange={(event) => { setName(event.target.value) }}
          />
        </Grid>
        {/* Description */}
        <Grid item xs={12}>
          <TextField
            required
            multiline
            rows={6}
            id='description'
            label='Description'
            variant='outlined'
            fullWidth
            value={description ? description : ''}
            onChange={(event) => { setDescription(event.target.value) }}
          />
        </Grid>
        {/* Custom Id */}
        <Grid item xs={12}>
          <TextField
            id='customId'
            label='Custom Id'
            variant='outlined'
            fullWidth
            value={customId}
            onChange={(event) => { setCustomId(event.target.value) }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default MainForm;
