import React, { useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Typography,
  useMediaQuery
} from '@mui/material';

import { useTheme } from '@mui/styles';

import useStyles from './styles';

import {
  useGetCurrentUser,
  useMutateAddComment,
  useMutateEditTicket
} from '../../../hooks';

import { ticketPriority } from '../ticket.enums';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});


const EditPriority = (props) => {

  const {
    isPriorityDialogOpen,
    setIsPriorityDialogOpen,
    ticket
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isBreakpointsDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBreakpointsUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const formRef = React.useRef();

  const [priority, setPriority] = useState(ticket?.data?.priority);

  const currentUser = useGetCurrentUser();
  const addCommentMutation = useMutateAddComment();
  const editTicketMutation = useMutateEditTicket();

  const editTicket = (priority) => {
    editTicketMutation.mutate({
      id: ticket?.data?.id,
      body: {
        priority: priority
      },
    },
    {
      onSuccess: (data) => {
        ticket.refetch();
        handleCloseDialog(data);
        if (ticket?.data?.type === 'single') {
          // addCommentMutation.mutate({
          //   body: {
          //     text: `Auto-Comment: Ticket updated`,
          //     companyId: currentUser?.data?.companyId,
          //     createdById: currentUser?.data?.id,
          //     ticketId: ticket?.data?.id
          //   }
          // });
        };
      }
    });
  };

  const handleCloseDialog = (data) => {
    setIsPriorityDialogOpen(false);
    setPriority(data.priority || ticket?.data?.priority);
  };

  const handleOnSubmit = () => {
    if (formRef.current.reportValidity()) {
      editTicket(priority);
    }
  }

  return (
    <Dialog
      fullScreen={isBreakpointsDownSm}
      fullWidth={!isBreakpointsDownSm}
      style={{ minWidth: !isBreakpointsDownSm ? 600 : 200 }}
      open={isPriorityDialogOpen}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Typography component='h1' variant='h4' align='center'>
          Edit Priority
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <form ref={formRef}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl variant='outlined' fullWidth>
                <InputLabel id='ticket-priority-select-label'>Priority</InputLabel>
                <Select
                  required
                  fullWidth
                  labelId='ticket-priority-select-label'
                  id='ticket-priority-select'
                  value={priority}
                  onChange={(event) => { setPriority(event.target.value); }}
                  label='Priority'
                >
                  {
                    ticketPriority.map(key => (
                      <MenuItem key={key} value={key}>{key.toUpperCase()}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          className={classes.button}
          onClick={handleCloseDialog}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          className={classes.button}
          onClick={handleOnSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditPriority;
