import React from 'react';
import {
  Autocomplete,
  FormControl,
  Grid,
  TextField,
  Typography
} from '@mui/material';


const AssetForm = (props) => {
  const {
    assets,
    setPlantId,
    setZoneId,
    setAssetId
  } = props;

  const options = assets && assets.data?.data?.map((option) => {
    const firstLetter = option.name[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

  return (
    <>
      <Typography variant='h6' gutterBottom>
        Target Asset
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormControl
            variant='outlined'
            fullWidth
          >
            <Autocomplete
              id='asset-select-ticket-wizard'
              options={assets ? options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter)) : []}
              groupBy={(option) => option.firstLetter}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => { return (option.id === value.id )}}
              renderInput={(params) =>
                <TextField
                  {...params}
                  required
                  label='Assets'
                />
              }
              onChange={(event, value, reason) => {
                if (reason !== 'clear') {
                  setPlantId(value.plantId);
                  setZoneId(value.zoneId);
                  setAssetId(value.id);
                }
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}

export default AssetForm;
