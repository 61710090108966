import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { useAuth } from '../hooks';

import protectedRoutes from './protectedRoutes';
import Navigation from '../components/layout/Navigation';
import { Login } from '../pages';

const AppRouter = () => {
  const auth = useAuth();
  const { isLoggedIn } = auth.getSession();

  const location = useLocation();

  return (
    <>
      <Switch>
        <Route path='/login' component={Login} />
          <Navigation>
            <Switch>
                {protectedRoutes.map((route) => {
                  return (
                    <Route
                      path={route.path}
                      exact={route.exact}
                      key={Math.random().toString(36).substr(2, 9)}
                      render={(props) => isLoggedIn ? (
                        <route.component {...props} {...route.props} />
                      ) : (
                        <Redirect
                          to={{
                            pathname: '/login',
                            state: { from: location }
                          }}
                        />
                      )}
                    />
                  )
                })}
              </Switch>
          </Navigation>
      </Switch>
    </>
  );
}

export default AppRouter;
