import React from 'react';
import { Grid } from '@mui/material';
import PartsTable from '../../components/parts/PartsTable';

const PartsPage = () => {

  return (
    <Grid container spacing={3}>

      <Grid item xs>
        <PartsTable tableHeight={650} />
      </Grid>
    </Grid>
  )
}

export default PartsPage;
