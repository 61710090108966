import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Step,
  Stepper,
  StepLabel,
  Typography,
  useMediaQuery } from '@mui/material';

import { useTheme } from '@mui/styles';
import useStyles from './styles';

import {
  useMutateAddTicket,
  useGetCurrentUser,
  useGetAssets,
  useGetUsers
} from '../../../../hooks';

import MainForm from './MainForm';
import AssetForm from './AssetForm';
import DetailsForm from './DetailsForm';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const RecurringWizard = (props) => {

  const {
    activeStep,
    setActiveStep,
    dialogMode,
    isDialogOpen,
    setIsDialogOpen
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isBreakpointsDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBreakpointsUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const routerParams = useParams();

  const currentUser = useGetCurrentUser();
  const assets = useGetAssets();
  const users = useGetUsers();
  const addTicketMutation = useMutateAddTicket();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [assignedToId, setAssignedToId] = useState();
  const [priority, setPriority] = useState();
  const [plantId, setPlantId] = useState();
  const [zoneId, setZoneId] = useState();
  const [assetId, setAssetId] = useState();
  const [frequency, setFrequency] = useState();
  const [recurringStartPeriod, setRecurringStartPeriod] = useState(new Date());
  const [recurringEndPeriod, setRecurringEndPeriod] = useState(new Date());
  const [estimatedDuration, setEstimatedDuration] = useState();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const formRef = useRef();

  const steps = ['Main info', 'Target asset', 'Assignee details'];

  const isLastStep = activeStep === steps.length - 1;

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <MainForm
            title={title}
            setTitle={setTitle}
            description={description}
            setDescription={setDescription}
            recurringStartPeriod={recurringStartPeriod}
            setRecurringStartPeriod={setRecurringStartPeriod}
            recurringEndPeriod={recurringEndPeriod}
            setRecurringEndPeriod={setRecurringEndPeriod}
            priority={priority}
            setPriority={setPriority}
            frequency={frequency}
            setFrequency={setFrequency}
            estimatedDuration={estimatedDuration}
            setEstimatedDuration={setEstimatedDuration}
          />
        )
      case 1:
        return (
          <AssetForm
            assets={assets}
            routerParams={routerParams}
            setPlantId={setPlantId}
            setZoneId={setZoneId}
            setAssetId={setAssetId}
          />
        )
      case 2:
        return (
          <DetailsForm
            users={users}
            assignedToId={assignedToId}
            setAssignedToId={setAssignedToId}
          />
        )
      default:
        return <div>Not Found</div>;
    }
  }

  const submitForm = () => {
    if (dialogMode === 'new' && formRef.current.reportValidity()) {
      addTicketMutation.mutate({
        body: {
          title: title,
          description: description,
          companyId: currentUser.data.companyId,
          plantId: plantId,
          zoneId: zoneId,
          assetId: assetId,
          createdById: currentUser.data.id,
          assignedToId: assignedToId,
          priority: priority,
          type: 'recurring',
          frequency: frequency,
          recurringStartPeriod: recurringStartPeriod,
          recurringEndPeriod: recurringEndPeriod,
          estimatedDuration: estimatedDuration
        }
      })
      setIsSubmitting(false);
      setActiveStep(activeStep + 1);
    }
  }

  const handleNewOne = () => {
    setActiveStep(0);
    setTitle('');
    setDescription('');
    setPlantId();
    setZoneId();
    setAssetId();
    setAssignedToId();
    setPriority();
    setFrequency();
    setRecurringStartPeriod();
    setRecurringEndPeriod();
    setEstimatedDuration();
  }

  const handleNext = () => {
    if (formRef.current.reportValidity()) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  const handleReset = () => {
    setActiveStep(0);
    setTitle('');
    setDescription('');
    setPlantId();
    setZoneId();
    setAssetId();
    setAssignedToId();
    setPriority();
    setFrequency();
    setRecurringStartPeriod();
    setRecurringEndPeriod();
    setEstimatedDuration();
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setActiveStep(0);
    setTitle('');
    setDescription('');
    setPlantId();
    setZoneId();
    setAssetId();
    setAssignedToId();
    setPriority();
    setFrequency();
    setRecurringStartPeriod();
    setRecurringEndPeriod();
    setEstimatedDuration();
  }

  if (dialogMode === 'new') {
    return (
      <>
        <Dialog
          fullScreen={isBreakpointsDownSm}
          style={{ minWidth: !isBreakpointsDownSm ? 600 : 200 }}
          open={isDialogOpen}
          onClose={handleReset}
          TransitionComponent={Transition}
        >
          <DialogTitle>
            <Typography component='h1' variant='h4' align='center'>
              Recurring Template creation
            </Typography>
          </DialogTitle>
          <DialogContent
            style={{ minWidth: !isBreakpointsDownSm ? 600 : 200 }}
            dividers
          >
            <Stepper
              activeStep={activeStep}
              className={classes.stepper}
              alternativeLabel
            >
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <>
              {activeStep === steps.length ? (
                <>
                  <Typography variant='h5' align='center' gutterBottom>
                    Operations completed.
                  </Typography>
                  <Typography align='center' variant='subtitle1'>
                    You successfully submitted a new ticket.
                  </Typography>
                  <div className={classes.buttons}>
                    <Button
                      onClick={handleCloseDialog}
                      className={classes.button}
                    >
                      Close
                    </Button>
                    <div className={classes.wrapper}>
                      <Button
                        onClick={handleNewOne}
                        type='submit'
                        variant='contained'
                        color='primary'
                        className={classes.button}
                      >
                        Create a new one
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <form ref={formRef}>

                    {renderStepContent(activeStep)}

                    <div className={classes.buttons}>
                      {activeStep !== 0 && (
                        <Button
                          className={classes.button}
                          type='submit'
                          onClick={handleBack}
                        >
                          Back
                        </Button>
                      )}
                      <div className={classes.wrapper}>
                        <Button
                          className={classes.button}
                          onClick={handleCloseDialog}
                        >
                          Cancel
                        </Button>

                        {(isLastStep) ? (
                          <Button
                            type='submit'
                            variant='contained'
                            color='primary'
                            className={classes.button}
                            onClick={submitForm}
                          >
                            Save
                          </Button>
                        ) : (
                          <Button
                            disabled={isSubmitting}
                            type='submit'
                            variant='contained'
                            color='primary'
                            className={classes.button}
                            onClick={handleNext}
                          >
                            Next
                          </Button>
                        )}
                        {isSubmitting && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </div>
                    </div>
                  </form>
                </>
              )}
            </>
          </DialogContent>
        </Dialog>
      </>
    );
  } else {
    return null;
  }
}

export default RecurringWizard;
