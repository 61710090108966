import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Slide,
  Typography,
  useMediaQuery
} from '@mui/material';

import { useTheme } from '@mui/styles';

import useStyles from './styles';

import {
  useGetCurrentUser,
  useMutateAddComment,
  useMutateDelAttachment
} from '../../../hooks';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});


const DelAttachment = (props) => {

  const {
    ticket,
    isDelAttachmentDialogOpen,
    setIsDelAttachmentDialogOpen,
    selectedAttachmentId,
    setSelectedAttachmentId,
    setIsAttachmentChecked
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isBreakpointsDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBreakpointsUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const currentUser = useGetCurrentUser();
  const addCommentMutation = useMutateAddComment();
  const delAttachmentMutation = useMutateDelAttachment();

  const handleCloseDialog = () => {
    setSelectedAttachmentId();
    setIsAttachmentChecked(false);
    setIsDelAttachmentDialogOpen(false);
  };

  const delAttachment = (attachmentId, ticket, user) => {
    if (attachmentId) {
      delAttachmentMutation.mutate({
        id: attachmentId
      },
      {
        // onSuccess: () => {
        //   addCommentMutation.mutate({
        //     body: {
        //       text: `Auto-Comment: Attachment deleted`,
        //       companyId: user.companyId,
        //       createdById: user.id,
        //       ticketId: ticket?.data?.id
        //     }
        //   });
        // }
      });
    }
  };

  const handleOnSubmit = () => {
    delAttachment(selectedAttachmentId, ticket, currentUser?.data);
    handleCloseDialog();
  };

  return (
    <Dialog
      fullScreen={isBreakpointsDownSm}
      style={{ minWidth: !isBreakpointsDownSm ? 600 : 200 }}
      open={isDelAttachmentDialogOpen}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Typography component='h1' variant='h5' align='center'>
          Do you want to delete this attachment?
        </Typography>
      </DialogTitle>
      <DialogActions>
        <Button
          variant='contained'
          className={classes.button}
          onClick={handleCloseDialog}
        >
          No
        </Button>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          className={classes.button}
          onClick={handleOnSubmit}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DelAttachment;
