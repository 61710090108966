import { useEffect, useState } from 'react';

export default function useAuth() {
  const [, setIsLoggedIn] = useState(!!JSON.parse(localStorage.getItem('isLoggedIn')));

  const setSession = (accessToken, refreshToken) => {
    localStorage.setItem('isLoggedIn', true);
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    setIsLoggedIn(true);
  };

  const removeSession = () => {
    localStorage.setItem('isLoggedIn', false);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    setIsLoggedIn(false);
    window.location = '/login';
  };

  const getSession = () => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    return {
      accessToken: accessToken || '',
      refreshToken: refreshToken || '',
      isLoggedIn: isLoggedIn && accessToken && refreshToken ? true : false,
    };
  };

  const refreshToken = (accessToken) => {
    localStorage.setItem('accessToken', accessToken);
  };

  useEffect(() => {
    // Sync all tabs on login or logout
    window.addEventListener('storage', e => {
      if (e.key === 'isLoggedIn') {
        setIsLoggedIn(e.newValue);
      }
    });
  });

  return {
    setSession,
    removeSession,
    getSession,
    refreshToken
  };
}