import React, { useRef, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';

import { useTheme } from '@mui/styles';

import useStyles from './styles';

import {
  useGetCurrentUser,
  useMutateAddComment
} from '../../../hooks';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const NewComment = (props) => {

  const {
    isNewCommentDialogOpen,
    setIsNewCommentDialogOpen,
    ticket
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isBreakpointsDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBreakpointsUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const formRef = useRef();

  const [text, setText] = useState();

  const currentUser = useGetCurrentUser();
  const addCommentMutation = useMutateAddComment();

  const addComment = () => {
    addCommentMutation.mutate({
      body: {
        text: text,
        companyId: currentUser?.data?.companyId,
        plantId: currentUser?.data?.plantId,
        createdById: currentUser?.data?.id,
        ticketId: ticket?.data?.id
      }
    });
  };

  const handleCloseDialog = () => {
    setText();
    setIsNewCommentDialogOpen(false);
  };

  const handleOnSubmit = () => {
    if (formRef.current.reportValidity()) {
      addComment();
      setText();
      setIsNewCommentDialogOpen(false);
    }
  }

  return (
    <Dialog
      fullScreen={isBreakpointsDownSm}
      fullWidth={!isBreakpointsDownSm}
      style={{ minWidth: !isBreakpointsDownSm ? 600 : 200 }}
      open={isNewCommentDialogOpen}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Typography component='h1' variant='h4' align='center'>
          New Feedback
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <form ref={formRef}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                required
                multiline
                rows={6}
                id='new-comment'
                label='Feedback'
                variant='outlined'
                fullWidth
                value={text ? text : ''}
                onChange={(event) => { setText(event.target.value) }}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          className={classes.button}
          onClick={handleCloseDialog}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          className={classes.button}
          onClick={handleOnSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NewComment;
