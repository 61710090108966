import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Card,
  CardHeader,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  useMediaQuery
} from '@mui/material';

import {
  DataGrid
} from '@mui/x-data-grid';

import { useTheme } from '@mui/styles';

import { format, parseISO } from 'date-fns';

import jsPDF from 'jspdf';
import 'jspdf-autotable';

import Title from '../layout/Title';
import TableIcons from '../layout/TableIcons';

import AssetWizard from './wizards/AssetWizard';
import EditAsset from './dialogs/EditAsset';
import AlertDialogSlide from '../alertdialogs/AlertDialogSlide';
import NewSearch from '../searchdialogs/NewSearch';


import {
  useGetCurrentUser,
  useGetAssets,
  useMutateDelAsset
} from '../../hooks';


const AssetsTable = (props) => {

  const {
    includedFields,
    plantId,
    zoneId,
    tableHeight
  } = props;

  const theme = useTheme();
  const isBreakpointsDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBreakpointsUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const isBreakpointsUpLg = useMediaQuery(theme.breakpoints.up('lg'));
 
  const history = useHistory();
  const currentUser = useGetCurrentUser();
  const isAuthorized = true ? (currentUser?.data?.role === 'manager'
    || currentUser?.data?.role === 'admin'
    || currentUser?.data?.role === 'superadmin') : false;

  const delAssetMutation = useMutateDelAsset();

  const [activeStep, setActiveStep] = useState(0);
  const [dialogMode, setDialogMode] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDelDialogOpen, setIsDelDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isNewSearchDialogOpen, setIsNewSearchDialogOpen] = useState(false);

  const [delDialogTitle,] = useState('Do you want to delete the selected asset?');
  const [delDialogText,] = useState('This operation will remove the selected asset.');

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [search, setSearch] = useState();

  const [selectedItem, setSelectedItem] = useState();
  const [selectionModel, setSelectionModel] = useState([]);

  const [sortModel, setSortModel] = useState([{ field: orderBy, sort: order }]);

  const handleSortModelChange = (newModel) => {
    setOrderBy(newModel[0]?.field);
    setOrder(newModel[0]?.sort);
    setSortModel(newModel);
  };

  const assets = useGetAssets({
    page: page,
    limit: limit,
    orderBy: orderBy,
    order: order,
    search: search,
    plantId: plantId,
    zoneId: zoneId
  });

  const dataForPDF = useGetAssets({
    orderBy: orderBy,
    order: order
  });

  const [rowCountState, setRowCountState] = useState(assets?.data?.headers['x-total-count']);

  const handleClickView = (event) => {
    history.push(`/assets/${event.row.id}`);
  };

  const handleClickNew = () => {
    setDialogMode('new');
    setIsDialogOpen(true);
    setActiveStep(0);
  };

  const handleDeleteItem = (asset) => {
    if (asset) {
      setIsDelDialogOpen(true);
    };
  };

  const handleEditAsset = (asset) => {
    if (asset) {
      setIsEditDialogOpen(true);
    };
  };
  
  const handleSearchDialog = () => {
    setIsNewSearchDialogOpen(true);
  };

  const handleDelDialogAgree = () => {
    if (selectedItem) {
      delAssetMutation.mutate({ id: selectedItem.id});
      setIsDelDialogOpen(false);
      setSelectedItem();
      setSelectionModel([]);
    };
  };

  const handleDelDialogDisagree = () => {
    setIsDelDialogOpen(false);
    setSelectedItem();
    setSelectionModel([]);
  };


  const columns = [
    // {
    //   field: 'id',
    //   headerName: 'Id',
    //   type: 'string',
    //   flex: 1,
    //   sortable: true,
    //   renderCell: (params) => {
    //     let end = params.row.id.length;
    //     return <div className="rowitem">{params.row.id.slice(18,end)}</div>; // nested structure
    //   }
    // },
    {
      field: 'customId',
      headerName: 'Custom Id',
      type: 'string',
      flex: 1,
      sortable: true
    },
    {
      field: 'name',
      headerName: 'Name',
      type: 'string',
      flex: 1,
      sortable: true
    },
    // {
    //   field: 'plant.name',
    //   headerName: 'Plant',
    //   type: 'string',
    //   flex: 1,
    //   sortable: true,
    //   renderCell: (params) => {
    //     return <div className="rowitem">{params.row.plant.name}</div>; // nested structure
    //   }
    // },
    {
      field: 'zone.name',
      headerName: 'Zone',
      type: 'string',
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return <div className="rowitem">{params.row.zone.name}</div>; //nested structure
      }
    },
    {
      field: 'workingHours',
      headerName: 'Usage',
      type: 'string',
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return <div className="rowitem">{params.row.workingHours > 0 ? params.row.workingHours : 0} hr</div>; //nested structure
      }
    },
    {
      field: 'updatedAt',
      headerName: 'Date Modified',
      type: 'string',
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        let date = parseISO(params.row.updatedAt);
        return <div className="rowitem">{format(date, 'dd MMM yyyy, h:mm bbb')}</div>
      }
    }
  ]

  const filterColumnsByFields = (objectsArray, includedFields) => {
    if (!includedFields || includedFields.length === 0) {
      return objectsArray
    }
    return objectsArray.filter(obj => includedFields.includes(obj.field));
  };

  const filteredArray = filterColumnsByFields(columns, includedFields);

  const exportPDF = (data) => {
    const doc = new jsPDF('l', 'mm', 'a4');
    if (data) {
      let tableData = data.data.data.map(asset => {
        let updatedAt = parseISO(asset.updatedAt);

        let props = {
          id: asset.id,
          customId: asset.customId,
          name: asset.name,
          plant: asset.plant.name,
          zone: asset.zone.name,
          updatedAt: format(updatedAt, 'dd MMM yyyy, h:mm bbb'),
        }
        return props;
      })
      let mappedData = tableData.map(Object.values);

      doc.autoTable({
        head: [['Id', 'CustomId', 'Name', 'Plant', 'Zone', 'Date Modified']],
        body: mappedData,
        margin: { top: 25 },
        theme: 'striped',
        didDrawPage: function (data) {
          // Header
          doc.setFontSize(20)
          doc.setTextColor(40)
          doc.text(`Factory Maintenance Pro - Assets`, 14, 20);

          // Footer
          let str = 'Page ' + doc.internal.getNumberOfPages()
          // Total page number plugin only available in jspdf v1.0+
          doc.setFontSize(10)

          // jsPDF 1.4+ uses getWidth, <1.4 uses .width
          var pageSize = doc.internal.pageSize
          var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
          doc.text(str, data.settings.margin.left, pageHeight - 10)
          doc.text(`Generated ${format(Date.now(), 'dd MMM yyyy')} at ${format(Date.now(), 'h:mm bbb')}`, 215, pageHeight - 10);
        },
      });
      doc.save(`Factory Maintenance Pro - Assets - ${format(Date.now(), 'dd MMM yyyy')}.pdf`);
    };
  };

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      assets?.data?.headers['x-total-count'].toString() !== undefined
        ? assets?.data?.headers['x-total-count'].toString()
        : prevRowCountState,
    );
  }, [assets, setRowCountState]);

  return (
    <Grid container spacing={3}>

      <Grid item xs>
        <Card>
          <CardHeader
            title={
              <>
                <Title>Assets</Title>
              </>
            }
            action={
              <>
                {!selectedItem && !isBreakpointsDownSm &&
                  <>
                    <TextField
                      id="assets-table-search-field"
                      label="Search"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <TableIcons.Search />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <TableIcons.Tune />
                          </InputAdornment>
                        )
                      }}
                      value={search}
                      onChange={(event) => {
                        setSearch(event.target.value);
                      }}
                    />
                    <Tooltip title="Refresh" placement="bottom" arrow>
                      <IconButton
                        onClick={() => {
                          assets.refetch();
                        }}
                      >
                        <TableIcons.Sync />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Export all assets to PDF" placement="bottom" arrow>
                      <IconButton
                        onClick={() => {
                          exportPDF(dataForPDF);
                        }}
                      >
                        <TableIcons.ExportToPdf />
                      </IconButton>
                    </Tooltip>
                  </>
                }
                {!selectedItem && isBreakpointsDownSm &&
                  <>
                    <Tooltip title="Search" placement="bottom" arrow>
                      <IconButton
                        onClick={() => {
                          handleSearchDialog();
                        }}
                      >
                        <TableIcons.Search />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Refresh" placement="bottom" arrow>
                      <IconButton
                        onClick={() => {
                          setSearch();
                          assets.refetch();
                        }}
                      >
                        <TableIcons.Sync />
                      </IconButton>
                    </Tooltip>
                  </>
                }
                {isAuthorized && !selectedItem &&
                  <>
                    <Tooltip title="Add new asset" placement="bottom" arrow>
                      <IconButton
                        onClick={() => {
                          handleClickNew();
                        }}
                      >
                        <TableIcons.Add />
                      </IconButton>
                    </Tooltip>
                  </>
                }
                {isAuthorized && selectedItem &&
                  <>
                    <Tooltip title="Edit selected asset" placement="bottom" arrow>
                      <IconButton
                        onClick={() => {
                          handleEditAsset(selectedItem);
                        }}
                      >
                        <TableIcons.Edit />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete selected asset" placement="bottom" arrow>
                      <IconButton
                        onClick={() => {
                          handleDeleteItem(selectedItem);
                        }}
                      >
                        <TableIcons.Delete />
                      </IconButton>
                    </Tooltip>
                  </>
                }
              </>
            }
          />
          <div style={ isBreakpointsUpLg ? { height: tableHeight, width: '100%' } : { height: 420, width: '100%' } }>
            <DataGrid
              columns={
                isBreakpointsDownSm ? filterColumnsByFields(columns, ['name'])
                  : isBreakpointsUpMd && !isBreakpointsUpLg ? filterColumnsByFields(columns, ['customId', 'name', 'zone.name'])
                  : filteredArray
              }
              rows={assets?.data?.data || []}
              disableColumnMenu
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={(ids) => {
                setSelectionModel((prevModel) =>
                  ids.filter((newId) => !prevModel.includes(newId))
                );
                if (ids.length === 0) {
                  setSelectedItem();
                };
                if (ids.length > 0 && ids.length < 3) {
                  setSelectedItem(assets?.data?.data.find((row) => row.id === ids[ids.length - 1]));
                };
              }}
              selectionModel={selectionModel}
              loading={assets.isLoading}
              pagination
              paginationMode="server"
              sortingMode="server"
              sortModel={sortModel}
              onSortModelChange={handleSortModelChange}
              rowsPerPageOptions={!isBreakpointsDownSm ? [5, 10, 15] : []}
              rowCount={parseInt(rowCountState) || 0}
              pageSize={limit}
              onPageSizeChange={(newPageSize) => {
                setLimit(newPageSize);
              }}
              onPageChange={(newPage) => {
                setPage(newPage);
              }}
              onRowClick={(event) => handleClickView(event)}
            />
          </div>
        </Card>
      </Grid>
      <AssetWizard
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        dialogMode={dialogMode}
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
      />
      <EditAsset
        isDialogOpen={isEditDialogOpen}
        setIsDialogOpen={setIsEditDialogOpen}
        asset={selectedItem}
      />
      <AlertDialogSlide
        title={delDialogTitle}
        text={delDialogText}
        isDialogOpen={isDelDialogOpen}
        setIsDialogOpen={setIsDelDialogOpen}
        handleAgree={handleDelDialogAgree}
        handleDisagree={handleDelDialogDisagree}
      />
      <NewSearch
        isNewSearchDialogOpen={isNewSearchDialogOpen}
        setIsNewSearchDialogOpen={setIsNewSearchDialogOpen}
        search={search}
        setSearch={setSearch}
        label="Type words included in asset names"
      />
    </Grid>
  )
}

export default AssetsTable;
