import React, { useState } from 'react';
import { useSnackbar } from 'notistack';

import {
  Autocomplete,
  FormControl,
  Grid,
  TextField,
  Typography
} from '@mui/material';

import { DropzoneAreaBase } from 'react-mui-dropzone';

import useStyles from './styles';

const DetailsForm = (props) => {
  const {
    plants,
    zones,
    plantId,
    setPlantId,
    zoneId,
    setZoneId,
    fileObjs,
    setFileObjs
  } = props;

  const classes = useStyles();

  const [isUploading, setIsUploading] = useState(false);

  /* Notification */
  const { enqueueSnackbar } = useSnackbar();
  const notify = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant
    });
  };

  const options = (array) => {
    if (array) {
      const mappedData = array.map((option) => {
        const firstLetter = option.name[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
          ...option,
        };
      });
      return mappedData.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter));
    };
  };

  return (
    <>
      <Typography variant='h6' gutterBottom>
        Plant association
      </Typography>
      <Grid container spacing={3}>

        {/* Plant */}
        <Grid item xs={12} sm={12}>
          <FormControl
            variant='outlined'
            fullWidth
          >
            <Autocomplete
              id='plant-select-zone-wizard'
              options={plants ? options(plants) : []}
              groupBy={(option) => option.firstLetter}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => { return (option.id === value.id )}}
              renderInput={(params) =>
                <TextField
                  {...params}
                  required
                  label='Plant'
                />
              }
              onChange={(event, value, reason) => {
                if (reason !== 'clear') {
                  setPlantId(value.id);
                }
              }}
            />
          </FormControl>
        </Grid>

        {/* Zone image */}
        <Grid item xs={12}>
            <Typography color='textSecondary' className={classes.assetContext}>
              Upload 360 picture (PNG/JPEG)
            </Typography>
            <DropzoneAreaBase
              acceptedFiles={['image/png', 'image/jpg', 'image/jpeg']}
              fileObjects={fileObjs}
              filesLimit={1}
              maxFileSize={10000000}
              onAlert={(message, variant) => notify(message, variant)}
              showAlerts={false}
              onAdd={(files) => {
                setFileObjs(files);
                setIsUploading(true);
              }}
              onDelete={(files) => {
                setFileObjs([]);
                setIsUploading(false);
              }}
            />
          </Grid>

      </Grid>
    </>
  );
}

export default DetailsForm;
