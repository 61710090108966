import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import {
  AppBar,
  Box,
  Card,
  CardHeader,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Tabs,
  Tab,
  Tooltip,
  Typography
} from '@mui/material';

import SwipeableViews from 'react-swipeable-views';

import { makeStyles, useTheme } from '@mui/styles';

import { ConfirmationNumber } from '@mui/icons-material';

import {
  useGetCurrentUser,
  useGetAsset,
  useGetTickets
} from '../../hooks';

import styles from '../../components/layout/styles';
import Title from '../../components/layout/Title';
import TableIcons from '../../components/layout/TableIcons';

import QRGenerator from '../../components/qr/QRGenerator';
import exportQRtoPDF from '../../components/qr/exportQRtoPDF';

import TicketsTable from '../../components/tickets/TicketsTable';
import EditAsset from '../../components/assets/dialogs/EditAsset';
import EditUsage from '../../components/assets/dialogs/EditUsage';

const useStyles = makeStyles(styles);

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`action-asset-tabpanel-${index}`}
      aria-labelledby={`action-asset-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `action-asset-tab-${index}`,
    'aria-controls': `action-asset-tabpanel-${index}`,
  };
}

const AssetPage = () => {
  const classes = useStyles();
  const theme = useTheme();

  const routerParams = useParams();
  const history = useHistory();

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isEditUsageDialogOpen, setIsEditUsageDialogOpen] = useState(false);

  const [plant, setPlant] = useState();
  const [zone, setZone] = useState();
  const [usage, setUsage] = useState();
  const [totalTicketsCount, setTotalTicketsCount] = useState();
  const [openTicketsCount, setOpenTicketsCount] = useState();
  const [newTicketsCount, setNewTicketsCount] = useState();

  const [tabValue, setTabValue] = useState(0);
  const [hasSensor, setHasSensor] = useState(false);

  const assetOnSuccess = (data) => {
    setPlant(data.plant);
    setZone(data.zone);
    setUsage(data.workingHours > 0 ? data.workingHours : 0);
  };

  const ticketsOnSuccess = (data) => {
    setTotalTicketsCount(data.headers['x-total-count'].toString());
  };

  const openTicketsOnSuccess = (data) => {
    setOpenTicketsCount(data.headers['x-total-count'].toString());
  };

  const newTicketsOnSuccess = (data) => {
    setNewTicketsCount(data.headers['x-total-count'].toString());
  };

  const currentUser = useGetCurrentUser();
  const asset = useGetAsset({ id: routerParams.id }, assetOnSuccess);
  const tickets = useGetTickets({ type: 'single', assetId: routerParams.id }, ticketsOnSuccess);
  const openTickets = useGetTickets({ type: 'single', assetId: routerParams.id, status: 'open' }, openTicketsOnSuccess);
  const newTickets = useGetTickets({ type: 'single', assetId: routerParams.id, status: 'new' }, newTicketsOnSuccess);

  const isAuthorized = true ? (currentUser?.data?.role === 'manager'
    || currentUser?.data?.role === 'admin'
    || currentUser?.data?.role === 'superadmin') : false;

  const handleInfo = () => {
    setIsEditDialogOpen(true);
  };

  const handleUsage = () => {
    setIsEditUsageDialogOpen(true);
  }

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setTabValue(index);
  };


  return (
    asset?.isLoading && tickets?.isLoading ? (
      <LinearProgress />
    ) : (
      <Grid container spacing={3}>

        {/* Asset info */}
        <Grid item xs={12} sm={12} md={12} lg={5}>
          <Card>
            <Grid container spacing={2}>
              <Grid item>
                <QRGenerator
                  assetId={asset?.data?.id}
                />
              </Grid>
              <Grid item xs>
                <CardHeader
                  title={
                    <>
                      <Title>Asset</Title>
                      <Typography component='p' variant='h4'>
                        {asset ? asset?.data?.name : ''}
                      </Typography>
                      <Typography color='textSecondary' className={classes.assetContext}>
                        {asset ? asset?.data?.description : ''}
                      </Typography>
                    </>
                  }
                  action={
                    <>
                      {isAuthorized &&
                      <Tooltip title='Edit asset' placement='bottom' arrow>
                        <IconButton
                          variant='outlined'
                          onClick={handleInfo}
                        >
                          <TableIcons.Edit />
                        </IconButton>
                      </Tooltip>
                      }
                      <Tooltip title='Download QR code' placement='bottom' arrow>
                        <IconButton
                          variant='outlined'
                          onClick={() => {
                            exportQRtoPDF('qr-container', asset?.data);
                          }}
                        >
                          <TableIcons.ExportToPdf />
                        </IconButton>
                      </Tooltip>
                    </>
                  }
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>

        {/* Zone */}
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <Card>
            <Grid container spacing={2}>
              <Grid item xs>
                <CardHeader
                  title={
                    <>
                      <Title>Zone</Title>
                      <Typography component='p' variant='h4'>
                        {asset ? asset?.data?.zone?.name : ''}
                      </Typography>
                      <Typography color='textSecondary' className={classes.assetContext}>
                        {plant ? plant?.name : ''}
                      </Typography>
                    </>
                  }
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>

        {/* Usage */}
        <Grid item xs={12} sm={12} md={3} lg={2}>
          <Card>
            <Grid container spacing={2}>
              <Grid item xs>
                <CardHeader
                  title={
                    <>
                      <Title>Usage</Title>
                      <Typography component='p' variant='h4'>
                        {asset ? usage : ''}
                      </Typography>
                      <Typography color='textSecondary' className={classes.assetContext}>
                        Working Hours
                      </Typography>
                    </>
                  }
                  action={
                    <>
                      {isAuthorized &&
                      <Tooltip title='Edit usage' placement='bottom' arrow>
                        <IconButton
                          variant='outlined'
                          onClick={handleUsage}
                        >
                          <TableIcons.Edit />
                        </IconButton>
                      </Tooltip>
                      }
                    </>
                  }
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        {/* Total Work Orders number */}
        <Grid item xs={12} sm={12} md={3} lg={2}>
          <Paper className={classes.paper}>
            <Title>Work Orders</Title>
            <Typography component='p' variant='h4'>
              {openTicketsCount && newTicketsCount ?
                (parseInt(openTicketsCount)+parseInt(newTicketsCount)).toString()
              : openTicketsCount && !newTicketsCount ?
                openTicketsCount
              : !openTicketsCount && newTicketsCount ?
                newTicketsCount
              : null} / {totalTicketsCount}
            </Typography>
            <Typography color='textSecondary' className={classes.assetContext}>
              Open / Total
            </Typography>
          </Paper>
        </Grid>

        {/* Tabs */}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card>
            <AppBar position='static'>
              <Tabs
                value={tabValue}
                onChange={handleChange}
                indicatorColor='primary'
                textColor='primary'
                aria-label='viewer tab actions'
                variant='fullWidth'
              >
                <Tab label='Tickets' icon={<ConfirmationNumber />} {...a11yProps(0)} />
                {/*}
                <Tab label='Docs' icon={<TableIcons.Folder />} {...a11yProps(1)} />
                <Tab label='Gallery' icon={<TableIcons.Gallery />} {...a11yProps(2)} />
                */}
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={tabValue}
              onChangeIndex={handleChangeIndex}
              disabled={true}
            >
              {/* Tickets */}
              <TabPanel value={tabValue} index={0} dir={theme.direction}>
                <Card
                  variant='outlined'
                >
                  <TicketsTable
                    tableHeight={371}
                    assetId={routerParams?.id}
                  />
                </Card>
              </TabPanel>

              {/* Docs */}
              <TabPanel value={tabValue} index={1} dir={theme.direction}>
                <Card
                  variant='outlined'
                >
                  <Typography color='textSecondary' className={classes.assetContext}>
                    There are no documents for this asset.
                  </Typography>
                </Card>
              </TabPanel>

            </SwipeableViews>
          </Card>
        </Grid>

        <EditAsset
          isDialogOpen={isEditDialogOpen}
          setIsDialogOpen={setIsEditDialogOpen}
          asset={asset?.data}
        />

        <EditUsage
          isDialogOpen={isEditUsageDialogOpen}
          setIsDialogOpen={setIsEditUsageDialogOpen}
          asset={asset?.data}
        />
      </Grid>
    )
  );
};

export default AssetPage;
