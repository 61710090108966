import React from 'react';

import protectedRoutes from '../../routes/protectedRoutes';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { NavLink } from 'react-router-dom';

import {
  Grid,
  Paper
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import styles from '../../styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const useStyles = makeStyles(styles);

export default function CustomBreadcrumbs() {
  const breadcrumbs = useBreadcrumbs(protectedRoutes);
  const classes = useStyles();

  return (
    <Grid container spacing={3}>

      <Grid item xs>
        <Paper className={classes.breadcrumbPaper}>
          <Breadcrumbs
            className={classes.breadcrumb}
            separator={<NavigateNextIcon fontSize='small' />}
            aria-label='breadcrumb'
          >
            {breadcrumbs.map(({ match, breadcrumb }) => (
              <span key={match.url}>
                <NavLink className={classes.linkBreadcrumb} to={match.url}>
                  {breadcrumb}
                </NavLink>
              </span>
            ))}
          </Breadcrumbs>
        </Paper>
      </Grid>

    </Grid>
  );
}
