import React, { forwardRef, useRef, useState } from 'react';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Step,
  Stepper,
  StepLabel,
  Typography,
  useMediaQuery } from '@mui/material';

import { useTheme } from '@mui/styles';
import useStyles from './styles';

import {
  useMutateAddUser,
  useGetCurrentUser
} from '../../../../hooks';

import MainForm from './MainForm';
import DetailsForm from './DetailsForm';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const UserWizard = (props) => {

  const {
    activeStep,
    setActiveStep,
    dialogMode,
    isDialogOpen,
    setIsDialogOpen,
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isBreakpointsDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBreakpointsUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const currentUser = useGetCurrentUser();
  const addUserMutation = useMutateAddUser();

  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [organization, setOrganization] = useState('');
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const formRef = useRef();

  const steps = ['Main info', 'User details'];

  const isLastStep = activeStep === steps.length - 1;

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <MainForm
            currentUser={currentUser}
            email={email}
            setEmail={setEmail}
            role={role}
            setRole={setRole}
            password={password}
            setPassword={setPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
          />
        )
      case 1:
        return (
          <DetailsForm
            firstname={firstname}
            setFirstname={setFirstname}
            lastname={lastname}
            setLastname={setLastname}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            organization={organization}
            setOrganization={setOrganization}
            role={role}
          />
        )
      default:
        return <div>Not Found</div>;
    }
  };

  const submitForm = () => {
    if (dialogMode === 'new' && formRef.current.reportValidity()) {
      addUserMutation.mutate({
        body: {
          firstname: firstname,
          lastname: lastname,
          email: email,
          password: password,
          companyId: currentUser.data.companyId,
          plantId: currentUser.data.plantId,
          createdById: currentUser.data.id,
          phoneNumber: phoneNumber,
          role: role
        }
      });
      setIsSubmitting(false);
      setActiveStep(activeStep + 1);
    }
  }

  const handleNewOne = () => {
    setActiveStep(0);
    setFirstname();
    setLastname();
    setRole();
    setEmail();
    setPhoneNumber();
    setPassword();
    setConfirmPassword();
  }

  const handleNext = () => {
    if (formRef.current.reportValidity()) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  const handleReset = () => {
    setActiveStep(0);
    setFirstname();
    setLastname();
    setRole();
    setEmail();
    setPhoneNumber();
    setPassword();
    setConfirmPassword();
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setActiveStep(0);
    setFirstname();
    setLastname();
    setRole();
    setEmail();
    setPhoneNumber();
    setPassword();
    setConfirmPassword();
  }

  if (dialogMode === 'new') {
    return (
      <>
        <Dialog
          fullScreen={isBreakpointsDownSm}
          style={{ minWidth: !isBreakpointsDownSm ? 600 : 200 }}
          open={isDialogOpen}
          onClose={handleReset}
          TransitionComponent={Transition}
        >
          <DialogTitle>
            <Typography variant='h4' align='center'>
              User creation
            </Typography>
          </DialogTitle>
          <DialogContent
            style={{ minWidth: !isBreakpointsDownSm ? 600 : 200 }}
            dividers
          >
            <Stepper
              activeStep={activeStep}
              className={classes.stepper}
              alternativeLabel
            >
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <>
              {activeStep === steps.length ? (
                <>
                  <Typography variant='h5' align='center' gutterBottom>
                    Operations completed.
                  </Typography>
                  <Typography align='center' variant='subtitle1'>
                    You successfully submitted a new user.
                  </Typography>
                  <div className={classes.buttons}>
                    <Button
                      onClick={handleCloseDialog}
                      className={classes.button}
                    >
                      Close
                    </Button>
                    <div className={classes.wrapper}>
                      <Button
                        onClick={handleNewOne}
                        type='submit'
                        variant='contained'
                        color='primary'
                        className={classes.button}
                      >
                        Create a new one
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <form ref={formRef}>

                    {renderStepContent(activeStep)}

                    <div className={classes.buttons}>
                      {activeStep !== 0 && (
                        <Button
                          className={classes.button}
                          type='submit'
                          onClick={handleBack}
                        >
                          Back
                        </Button>
                      )}
                      <div className={classes.wrapper}>
                        <Button
                          className={classes.button}
                          onClick={handleCloseDialog}
                        >
                          Cancel
                        </Button>

                        {(isLastStep) ? (
                          <Button
                            type='submit'
                            variant='contained'
                            color='primary'
                            className={classes.button}
                            onClick={submitForm}
                          >
                            Save
                          </Button>
                        ) : (
                          <Button
                            disabled={isSubmitting}
                            type='submit'
                            variant='contained'
                            color='primary'
                            className={classes.button}
                            onClick={handleNext}
                          >
                            Next
                          </Button>
                        )}
                        {isSubmitting && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </div>
                    </div>
                  </form>
                </>
              )}
            </>
          </DialogContent>
        </Dialog>
      </>
    );
  } else {
    return null;
  }
}

export default UserWizard;
