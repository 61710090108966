import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Grid,
  LinearProgress,
  Paper,
  Tabs,
  Tab,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';

import { MdPanoramaHorizontal, MdSettings } from 'react-icons/md';
import { FaIndustry, FaMapMarkedAlt } from 'react-icons/fa';
import { BsArrowsFullscreen } from 'react-icons/bs';

import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@mui/styles';

import styles from '../../styles';
import Title from '../../components/layout/Title';

import onFullScreen from '../../utils/onFullScreen';

import {
  useGetCurrentUser,
  useGetAssets,
  useGetPlant,
  useGetTickets
} from '../../hooks';

import PlantMap from '../../components/plants/PlantMap';
import PlantMapPlanimetry from '../../components/plants/PlantMapPlanimetry';
import PlantMapPano from '../../components/plants/PlantMapPano';
import PlantMapPanoActions from '../../components/plants/PlantMapPanoActions';
import TicketsTable from '../../components/tickets/TicketsTable';
import AssetsTable from '../../components/assets/AssetsTable';


const useStyles = makeStyles(styles)

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`,
  };
}


const PlantPage = () => {

  const classes = useStyles();
  const theme = useTheme();
  const viewer = clsx(classes.paper, classes.fixedHeight);
  const isBreakpointsDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBreakpointsUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const isBreakpointsUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const [value, setValue] = useState(0); // Map as default

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const [zones, setZones] = useState();
  const [totalAssetsCount, setTotalAssetsCount] = useState();
  const [totalTicketsCount, setTotalTicketsCount] = useState();
  const [openTicketsCount, setOpenTicketsCount] = useState();
  const [newTicketsCount, setNewTicketsCount] = useState();
  const [notCompletedTicketsCount,] = useState(openTicketsCount && !newTicketsCount ?
      openTicketsCount
    : openTicketsCount && newTicketsCount ?
      (parseInt(openTicketsCount)+parseInt(newTicketsCount)).toString()
    : !openTicketsCount && newTicketsCount ?
      newTicketsCount
    : null
  );

  const [zoneId, setZoneId] = useState();

  const plantOnSuccess = (data) => {
    setZones(data.zones);
    setZoneId(data.zones[0].id);
  };

  const assetsOnSuccess = (data) => {
    setTotalAssetsCount(data.headers['x-total-count'].toString());
  };

  const ticketsOnSuccess = (data) => {
    setTotalTicketsCount(data.headers['x-total-count'].toString());
  };

  const openTicketsOnSuccess = (data) => {
    setOpenTicketsCount(data.headers['x-total-count'].toString());
  };

  const newTicketsOnSuccess = (data) => {
    setNewTicketsCount(data.headers['x-total-count'].toString());
  };

  const routerParams = useParams();

  const currentUser = useGetCurrentUser();
  const plant = useGetPlant({ id: routerParams.id }, plantOnSuccess);
  const assets = useGetAssets({ plantId: routerParams.id }, assetsOnSuccess);
  const tickets = useGetTickets({ type: 'single', plantId: routerParams.id }, ticketsOnSuccess);
  const openTickets = useGetTickets({ type: 'single', plantId: routerParams.id, status: 'open' }, openTicketsOnSuccess);
  const newTickets = useGetTickets({ type: 'single', plantId: routerParams.id, status: 'new' }, newTicketsOnSuccess);

  const [selectedZone, setSelectedZone] = useState({});
  const [anchorEl, setAnchorEl] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [dialogMode, setDialogMode] = useState();
  const [isZoneToRerender, setIsZoneToRerender] = useState(0);
  const [newZoneId, setNewZoneId] = useState(null);

  const isAuthorized = true ? (currentUser?.data?.role === 'admin'
    || currentUser?.data?.role === 'superadmin') : false;
  
  const isLoading = (currentUser.isLoading
    && plant.isLoading
    && assets.isLoading
    && tickets.isLoading
    && openTickets.isLoading
  );

  const [isConfiguring,] = useState(false); //WIP for Conf Mode


  /* Render */
  return (
    isLoading ? (
      <LinearProgress />
    ) : (
      <Grid container spacing={3}>

        {/* Plant */}
        <Grid item xs={12} sm={8} md={8} lg={6}>
          <Card>
            <CardHeader
              title={
                <>
                  <Title>Plant</Title>
                  <Typography component='p' variant='h4'>
                    {plant?.data?.name}
                  </Typography>
                  <Typography color='textSecondary' className={classes.assetContext}>
                    Coordinates: {plant?.data?.location?.coordinates[1]}, {plant?.data?.location?.coordinates[0]}
                  </Typography>
                </>
              }
              action={
                <>
                  {isConfiguring && isAuthorized &&
                    <Box display='flex' p={1}>
                      <Box p={1} flexGrow={1}>
                        <IconButton
                          size='small'
                          variant='outlined'
                          color='textSecondary'
                          onClick={() => {
                            alert('Plant settings')
                          }}
                        >
                          <MdSettings />
                        </IconButton>
                      </Box>
                    </Box>
                  }
                </>
              }
            />
          </Card>
        </Grid>

        {/* Zones */}
        <Grid item xs={6} sm={4} md={4} lg={2}>
          <Card>
            <CardHeader
              title={
                <>
                  <Title>Zones</Title>
                  <Typography component='p' variant='h4'>
                    {zones?.length}
                  </Typography>
                  <Typography color='textSecondary' className={classes.assetContext}>
                    Configured
                  </Typography>
                </>
              }
              action={
                <>
                  {isConfiguring && isAuthorized &&
                    <Box display='flex' p={1}>
                      <Box p={1} flexGrow={1}>
                        <IconButton
                          size='small'
                          variant='outlined'
                          color='textSecondary'
                          onClick={() => {
                            alert('Zone settings')
                          }}
                        >
                          <MdSettings />
                        </IconButton>
                      </Box>
                    </Box>
                  }
                </>
              }
            />
          </Card>
        </Grid>

        {/* Assets number */}
        <Grid item xs={6} sm={6} md={6} lg={2}>
          <Paper className={classes.paper}>
            <Title>Assets</Title>
            <Typography component='p' variant='h4'>
              {totalAssetsCount}
            </Typography>
            <Typography color='textSecondary' className={classes.assetContext}>
              Total
            </Typography>
          </Paper>
        </Grid>

        {/* Total Work Orders number */}
        <Grid item xs={12} sm={12} md={6} lg={2}>
          <Paper className={classes.paper}>
            <Title>Work Orders</Title>
            <Typography component='p' variant='h4'>
              {openTicketsCount && newTicketsCount ?
                (parseInt(openTicketsCount)+parseInt(newTicketsCount)).toString()
              : openTicketsCount && !newTicketsCount ?
                openTicketsCount
              : !openTicketsCount && newTicketsCount ?
                newTicketsCount
              : null} / {totalTicketsCount}
            </Typography>
            <Typography color='textSecondary' className={classes.assetContext}>
              Open / Total
            </Typography>
          </Paper>
        </Grid>

        {/* Viewer */}
        <Grid item xs={12} sm={12} md={12} lg={7}>
          <Card>
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                aria-label="viewer tab actions"
                variant="fullWidth"
              >
                <Tab label='Map' icon={<FaMapMarkedAlt />} {...a11yProps(0)} />
                <Tab label='Planimetry' icon={<FaIndustry />} {...a11yProps(1)} />
                <Tab label='Virtual 360' icon={<MdPanoramaHorizontal />} {...a11yProps(2)} />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
              disabled={true}
            >

              <TabPanel value={value} index={0} dir={theme.direction}>
                <Card
                  variant='outlined'
                  className={viewer}
                >
                  <CardHeader
                    title={
                      <Title>Viewer</Title>
                    }
                    action={
                      <>
                        <Box display="flex" p={1}>
                          <Box p={1} flexGrow={1}>
                            <Tooltip title="Fullscreen" placement="bottom" arrow>
                              <IconButton
                                variant="outlined"
                                size="small"
                                onClick={() => {
                                  onFullScreen('#plantmap-map')
                                }}
                              >
                                <BsArrowsFullscreen />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Box>
                      </>
                    }
                  />
                  <PlantMap />
                </Card>
              </TabPanel>

              <TabPanel value={value} index={1} dir={theme.direction}>
                <Card
                  variant='outlined'
                  className={viewer}
                >
                  <CardHeader
                    title={
                      <Title>Viewer</Title>
                    }
                    action={
                      <>
                        <Box display='flex' p={1}>
                          <Box p={1} flexGrow={1}>
                            <Tooltip title='Fullscreen' placement='bottom' arrow>
                              <IconButton
                                variant='outlined'
                                size='small'
                                onClick={() => {
                                  onFullScreen('#plant-map')
                                }}
                              >
                                <BsArrowsFullscreen />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Box>
                      </>
                    }
                  />
                  <PlantMapPlanimetry />
                </Card>
              </TabPanel>

              <TabPanel value={value} index={2} dir={theme.direction}>
                {(zones && zones?.length > 0) ?
                  <Card
                    variant='outlined'
                    className={dialogMode !== 'hotspot' ? viewer : null}
                    style={{ minHeight: dialogMode === 'hotpost' ? 800 : null}}
                  >
                    <CardHeader
                      title={
                        <Title>Viewer</Title>
                      }
                      action={
                        <PlantMapPanoActions
                          /* to PlantMapPanoActions */
                          anchorEl={anchorEl}
                          setAnchorEl={setAnchorEl}
                          setSelectedZone={setSelectedZone}
                          zones={zones}
                          /* to PlantZonesWizard */
                          activeStep={activeStep}
                          setActiveStep={setActiveStep}
                          isZoneToRerender={isZoneToRerender}
                          setIsZoneToRerender={setIsZoneToRerender}
                          newZoneId={newZoneId}
                          setNewZoneId={setNewZoneId}
                          /* to PlantMapPanoActions and PlantZonesWizard */
                          zoneId={zoneId}
                          dialogMode={dialogMode}
                          setDialogMode={setDialogMode}
                          selectedZone={selectedZone}
                          setZoneId={setZoneId}
                          isAuthorized={isAuthorized}
                        />
                      }
                    />
                    <PlantMapPano
                      selectedZone={selectedZone}
                      setSelectedZone={setSelectedZone}
                      assets={assets?.data?.data}
                      dialogMode={dialogMode}
                      setDialogMode={setDialogMode}
                      setIsZoneToRerender={setIsZoneToRerender}
                      zoneId={zoneId}
                      setZoneId={setZoneId}
                      isAuthorized={isAuthorized}
                    />
                  </Card>
                :
                  <Card variant='outlined' style={{ height: 150 }}>
                    <CardHeader
                      title={
                        <Title>Viewer</Title>
                      }
                      action={
                        <PlantMapPanoActions
                          anchorEl={anchorEl}
                          setAnchorEl={setAnchorEl}
                          activeStep={activeStep}
                          setActiveStep={setActiveStep}
                          dialogMode={dialogMode}
                          setDialogMode={setDialogMode}
                          isAuthorized={isAuthorized}
                        />
                      }
                    />
                    <CardContent>
                      This plant has no configured zones.
                    </CardContent>
                  </Card>
                }
              </TabPanel>
            </SwipeableViews>
            </Card>
        </Grid>

        {/* Tickets Table */}
        <Grid item xs={12} sm={12} md={12} lg={5}>
          <TicketsTable
            plantId={routerParams.id}
            tableHeight={618}
            tableType='mini'
            title="Work Orders"
          />
        </Grid>

        {/* Assets Table */}
        <Grid item xs={12}>
          <AssetsTable
            plantId={routerParams.id}
            tableHeight={635}
          />
        </Grid>

      </Grid>
    )
  );
};

export default PlantPage;
