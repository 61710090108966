import axiosInstance from '../axios.instance';

export const apiAddPlant = async (variables) => {
  let config = {
    method: 'post',
    url: `/plants`,
    data: variables.body
  };
  const response = await axiosInstance(config);
  return response.data;
};

export const apiGetPlants = async (variables) => {
  let config = {
    method: 'get',
    url: '/plants',
    params: variables
  };
  const { data, headers } = await axiosInstance(config);
  return {
    data,
    headers
  };
};

export const apiGetPlant = async (variables) => {
  let config = {
    method: 'get',
    url: `/plants/${variables.id}`
  };
  const response = await axiosInstance(config);
  return response.data;
};

export const apiEditPlant = async (variables) => {
  let config = {
    method: 'put',
    url: `/plants/${variables.id}`,
    data: variables.body
  };
  const response = await axiosInstance(config);
  return response.data;
};

export const apiDelPlant = async (variables) => {
  let config = {
    method: 'delete',
    url: `/plants/${variables.id}`
  }
  const response = await axiosInstance(config);
  return response.data;
};

export const apiUploadPlantImage = async (variables) => {
  let config = {
    method: 'post',
    url: `/plants/${variables.id}`,
    data: variables.body
  };
  const response = await axiosInstance(config);
  return response.data;
};
