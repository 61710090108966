import React, { useEffect, useState } from 'react';

import {
  Card,
  CardHeader,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  useMediaQuery
} from '@mui/material';

import {
  DataGrid
} from '@mui/x-data-grid';

import { useTheme } from '@mui/styles';

import { format, parseISO } from 'date-fns';

import jsPDF from 'jspdf';
import 'jspdf-autotable';

import {
  useGetCurrentUser,
  useGetUsers,
  useMutateDelUser
} from '../../hooks';

import Title from '../layout/Title';
import TableIcons from '../layout/TableIcons';

import UserWizard from './wizards/UserWizard';
import EditUser from './dialogs/EditUser';
import AlertDialogSlide from '../alertdialogs/AlertDialogSlide';
import NewSearch from '../searchdialogs/NewSearch';


const UsersTable = (props) => {

  const {
    includedFields,
    tableHeight
  } = props;

  const theme = useTheme();
  const isBreakpointsDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBreakpointsUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const isBreakpointsUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  
  const currentUser = useGetCurrentUser();
  const isAuthorized = true ? (currentUser?.data?.role === 'manager'
    || currentUser?.data?.role === 'admin'
    || currentUser?.data?.role === 'superadmin') : false;

  const delUserMutation = useMutateDelUser();

  const [activeStep, setActiveStep] = useState(0);
  const [addDialogMode, setAddDialogMode] = useState();
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isDelDialogOpen, setIsDelDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isNewSearchDialogOpen, setIsNewSearchDialogOpen] = useState(false);

  const [delDialogTitle,] = useState('Do you want to delete the selected user?');
  const [delDialogText,] = useState('This operation will remove the selected user.');

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [search, setSearch] = useState();

  const [selectedItem, setSelectedItem] = useState();
  const [selectionModel, setSelectionModel] = useState([]);
  const [sortModel, setSortModel] = useState([{ field: orderBy, sort: order }]);

  const handleSortModelChange = (newModel) => {
    setOrderBy(newModel[0]?.field);
    setOrder(newModel[0]?.sort);
    setSortModel(newModel);
  };

  const users = useGetUsers({
    page: page,
    limit: limit,
    orderBy: orderBy,
    order: order,
    search: search,
  });

  const dataForPDF = useGetUsers({
    orderBy: orderBy,
    order: order
  });

  const [rowCountState, setRowCountState] = useState(users?.data?.headers['x-total-count']);

  const handleClickNew = () => {
    setAddDialogMode('new');
    setIsAddDialogOpen(true);
    setActiveStep(0);
  };

  const handleDeleteUser = () => {
    setIsDelDialogOpen(true);
  };

  const handleEditUser = (user) => {
    if (user) {
      setIsEditDialogOpen(true);
    };
  };

  const handleSearchDialog = () => {
    setIsNewSearchDialogOpen(true);
  };

  const handleDelDialogAgree = () => {
    if (selectedItem && selectedItem.id !== currentUser?.data?.id) {
      delUserMutation.mutate({ id: selectedItem.id});
      setIsDelDialogOpen(false);
    };
  };

  const columns = [
    {
      field: 'fullname',
      headerName: 'Full name',
      type: 'string',
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return <div className="rowitem">{params.row.firstname} {params.row.lastname}</div>
      }
    },
    {
      field: 'role',
      headerName: 'Role',
      type: 'string',
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return <div className="rowitem">{params.row.role.toUpperCase()}</div>; // nested structure
      }
    },
    {
      field: 'active',
      headerName: 'Status',
      type: 'string',
      flex: 1,
      width: 100,
      sortable: true,
      renderCell: (params) => {
        return <div className="rowitem">{params.row.active ? 'enabled'.toUpperCase() : 'disabled'.toUpperCase()}</div>; // nested structure
      }
    },
    {
      field: 'updatedAt',
      headerName: 'Date Modified',
      type: 'string',
      flex: 1,
      width: 100,
      sortable: true,
      renderCell: (params) => {
        let date = parseISO(params.row.updatedAt);
        return <div className="rowitem">{format(date, 'dd MMM yyyy, h:mm bbb')}</div>
      }
    },
  ];

  const filterColumnsByFields = (objectsArray, includedFields) => {
    if (!includedFields || includedFields.length === 0) {
      return objectsArray
    }
    return objectsArray.filter(obj => includedFields.includes(obj.field));
  };

  const filteredArray = filterColumnsByFields(columns, includedFields);
  
  const exportPDF = (data) => {
    const doc = new jsPDF('l', 'mm', 'a4');
    if (data) {
      let tableData = data.data.data.map(user => {
        let creationDate = parseISO(user.createdAt);

        let props = {
          id: user.id,
          name: user.firstname,
          lastname: user.lastname,
          role: user.role.toUpperCase(),
          createdAt: format(creationDate, 'dd MMM yyyy'),
          status: user.active ? 'enabled'.toUpperCase() : 'disabled'.toUpperCase()
        }
        return props;
      })
      let mappedData = tableData.map(Object.values);

      doc.autoTable({
        head: [['Id', 'First Name', 'Last Name', 'Role', 'Created', 'Status']],
        body: mappedData,
        margin: { top: 25 },
        theme: 'striped',
        didDrawPage: function (data) {
          // Header
          doc.setFontSize(20)
          doc.setTextColor(40)
          doc.text(`Factory Maintenance Pro - Users`, 14, 20);

          // Footer
          let str = 'Page ' + doc.internal.getNumberOfPages()
          // Total page number plugin only available in jspdf v1.0+
          doc.setFontSize(10)

          // jsPDF 1.4+ uses getWidth, <1.4 uses .width
          var pageSize = doc.internal.pageSize
          var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
          doc.text(str, data.settings.margin.left, pageHeight - 10)
          doc.text(`Generated ${format(Date.now(), 'dd MMM yyyy')} at ${format(Date.now(), 'pp')}`, 215, pageHeight - 10);
        },
      });
      doc.save(`Factory Maintenance Pro - Users - ${format(Date.now(), 'dd MMM yyyy')}.pdf`);
    };
  };

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      users?.data?.headers['x-total-count'].toString() !== undefined
        ? users?.data?.headers['x-total-count'].toString()
        : prevRowCountState,
    );
  }, [users, setRowCountState]);

  return (
    <Grid container spacing={3}>

      <Grid item xs>
        <Card>
          <CardHeader
            title={
              <>
                <Title>Users</Title>
              </>
            }
            action={
              <>
                {!selectedItem && !isBreakpointsDownSm &&
                  <>
                    <TextField
                      id="users-table-search-field"
                      label="Search"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <TableIcons.Search />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <TableIcons.Tune />
                          </InputAdornment>
                        )
                      }}
                      value={search}
                      onChange={(event) => {
                        setSearch(event.target.value);
                      }}
                    />
                    <Tooltip title="Refresh" placement="bottom" arrow>
                      <IconButton
                        onClick={() => {
                          users.refetch();
                        }}
                      >
                        <TableIcons.Sync />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Export all users to PDF" placement="bottom" arrow>
                      <IconButton
                        onClick={() => {
                          exportPDF(dataForPDF);
                        }}
                      >
                        <TableIcons.ExportToPdf />
                      </IconButton>
                    </Tooltip>
                  </>
                }
                {!selectedItem && isBreakpointsDownSm &&
                  <>
                    <Tooltip title="Search" placement="bottom" arrow>
                      <IconButton
                        onClick={() => {
                          handleSearchDialog();
                        }}
                      >
                        <TableIcons.Search />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Refresh" placement="bottom" arrow>
                      <IconButton
                        onClick={() => {
                          setSearch();
                          users.refetch();
                        }}
                      >
                        <TableIcons.Sync />
                      </IconButton>
                    </Tooltip>
                  </>
                }
                {isAuthorized && !selectedItem &&
                  <>
                    <Tooltip title="Add new user" placement="bottom" arrow>
                      <IconButton
                        onClick={() => {
                          handleClickNew();
                        }}
                      >
                        <TableIcons.Add />
                      </IconButton>
                    </Tooltip>
                  </>
                }
                {isAuthorized && selectedItem &&
                  <>
                    <Tooltip title="Edit selected user" placement="bottom" arrow>
                      <IconButton
                        onClick={() => {
                          handleEditUser(selectedItem);
                        }}
                      >
                        <TableIcons.Edit />
                      </IconButton>
                    </Tooltip>
                  </>
                }
                {isAuthorized && selectedItem && selectedItem?.id !== currentUser?.data?.id &&
                  <>
                    <Tooltip title="Delete selected user" placement="bottom" arrow>
                      <IconButton
                        onClick={() => {
                          handleDeleteUser(selectedItem);
                        }}
                      >
                        <TableIcons.Delete />
                      </IconButton>
                    </Tooltip>
                  </>
                }
              </>
            }
          />
          <div style={ isBreakpointsUpLg ? { height: tableHeight, width: '100%' } : { height: 420, width: '100%' } }>
            <DataGrid
              columns={
                isBreakpointsDownSm ? filterColumnsByFields(columns, ['fullname', 'role'])
                  : isBreakpointsUpMd && !isBreakpointsUpLg ? filterColumnsByFields(columns, ['firstname', 'lastname', 'role', "active"])
                  : filteredArray
              }
              rows={users?.data?.data || []}
              disableColumnMenu
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={(ids) => {
                setSelectionModel((prevModel) =>
                  ids.filter((newId) => !prevModel.includes(newId))
                );
                if (ids.length === 0) {
                  setSelectedItem();
                };
                if (ids.length > 0 && ids.length < 3) {
                  setSelectedItem(users?.data?.data.find((row) => row.id === ids[ids.length - 1]));
                };
              }}
              selectionModel={selectionModel}
              loading={users.isLoading}
              pagination
              paginationMode="server"
              sortingMode="server"
              sortModel={sortModel}
              onSortModelChange={handleSortModelChange}
              rowsPerPageOptions={!isBreakpointsDownSm ? [5, 10, 15] : []}
              rowCount={rowCountState}
              pageSize={limit}
              onPageSizeChange={(newPageSize) => {
                setLimit(newPageSize);
              }}
              onPageChange={(newPage) => {
                setPage(newPage);
              }}
            />
          </div>
        </Card>
      </Grid>
      <UserWizard
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        dialogMode={addDialogMode}
        isDialogOpen={isAddDialogOpen}
        setIsDialogOpen={setIsAddDialogOpen}
      />
      <EditUser
        isDialogOpen={isEditDialogOpen}
        setIsDialogOpen={setIsEditDialogOpen}
        user={selectedItem}
        setSelectedItem={setSelectedItem}
        setSelectionModel={setSelectionModel}
      />
      <AlertDialogSlide
        title={delDialogTitle}
        text={delDialogText}
        isDialogOpen={isDelDialogOpen}
        setIsDialogOpen={setIsDelDialogOpen}
        handleAgree={handleDelDialogAgree}
        handleDisagree={() => { setIsDelDialogOpen(false) }}
      />
      <NewSearch
        isNewSearchDialogOpen={isNewSearchDialogOpen}
        setIsNewSearchDialogOpen={setIsNewSearchDialogOpen}
        search={search}
        setSearch={setSearch}
        label="Type a first name"
      />
    </Grid>
  )
}

export default UsersTable;
