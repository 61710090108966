import React from 'react';
import { Grid } from '@mui/material';
import AssetsTable from '../../components/assets/AssetsTable';

const AssetsPage = () => {

  return (
    <Grid container spacing={3}>

      <Grid item xs>
        <AssetsTable tableHeight={650} />
      </Grid>
    </Grid>
  )
}

export default AssetsPage;
