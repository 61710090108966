import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

import { apiEditTicket } from '../../api/endpoints';

const useMutateEditTicket = () => {

  const queryClient = useQueryClient();

  /* Notification */
  const { enqueueSnackbar } = useSnackbar();
  const notify = (msg, variant) => {
    enqueueSnackbar(msg, {
      variant: variant
    });
  };

  return useMutation((variables) => apiEditTicket(variables), {
    onSuccess: () => {
      notify('Ticket updated!', 'success');
      queryClient.invalidateQueries('tickets');
      queryClient.invalidateQueries('calendar');
      queryClient.invalidateQueries('ticket');
    },
    onError: (error) => {
      console.log(error);
      notify('Something went wrong, please try again.', 'error');
    }
  });
};

export default useMutateEditTicket;