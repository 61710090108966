import React, { useRef, useState, useEffect } from 'react';

import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Slide,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';

import { useTheme } from '@mui/styles';

import useStyles from './styles';

import {
  useGetZones,
  useMutateEditAsset
} from '../../../hooks';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});


const EditAsset = (props) => {

  const {
    isDialogOpen,
    setIsDialogOpen,
    asset
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isBreakpointsDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBreakpointsUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const formRef = useRef();

  const [name, setName] = useState(asset?.name);
  const [description, setDescription] = useState(asset?.description);
  const [customId, setCustomId] = useState(asset?.customId);
  const [zoneId, setZoneId] = useState(asset?.zoneId);

  const zones = useGetZones({ plantId: asset?.plantId });

  const editAssetMutation = useMutateEditAsset();

  const editAsset = (name, description, customId, zoneId) => {
    editAssetMutation.mutate({
      id: asset?.id,
      body: {
        name: name,
        description: description,
        customId: customId,
        zoneId: zoneId
      }
    },
    {
      onSuccess: (data) => {
        handleCloseDialog(data);
        //asset.refetch();
      }
    });
  };

  const options = zones && zones.data?.data?.map((option) => {
    const firstLetter = option.name[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

  const handleCloseDialog = (data) => {
    setIsDialogOpen(false);
    setName(data.name || asset?.name);
    setDescription(data.description || asset?.description);
    setCustomId(data.customId || asset?.customId);
    setZoneId(data.zoneId || asset?.zoneId);
  };

  const handleOnSubmit = () => {
    if (formRef.current.reportValidity()) {
      editAsset(name, description, customId, zoneId);
    }
  };

  useEffect(() => {
    setName(asset?.name);
    setDescription(asset?.description);
    setCustomId(asset?.customId);
    setZoneId(asset?.zoneId)
  }, [asset]);

  return (
    <Dialog
      fullScreen={isBreakpointsDownSm}
      fullWidth={!isBreakpointsDownSm}
      style={{ minWidth: !isBreakpointsDownSm ? 600 : 200 }}
      open={isDialogOpen}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Typography component='h1' variant='h4' align='center'>
          Edit Asset
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <form ref={formRef}>
          <Grid container spacing={3}>

            {/* Name */}
            <Grid item xs={12}>
              <TextField
                required
                id='name'
                label='Name'
                variant='outlined'
                fullWidth
                value={name}
                onChange={(event) => { setName(event.target.value) }}
              />
            </Grid>

            {/* Description */}
            <Grid item xs={12}>
              <TextField
                required
                multiline
                rows={6}
                id='description'
                label='Description'
                variant='outlined'
                fullWidth
                value={description}
                onChange={(event) => { setDescription(event.target.value) }}
              />
            </Grid>

            {/* Custom Id */}
            <Grid item xs={12}>
              <TextField
                id='customId'
                label='Custom Id'
                variant='outlined'
                fullWidth
                value={customId}
                onChange={(event) => { setCustomId(event.target.value) }}
              />
            </Grid>

            {/* Zone */}
            <Grid item xs={12} sm={12}>
              <FormControl
                variant='outlined'
                fullWidth
              >
                <Autocomplete
                  id='zone-select-asset-dialog'
                  options={zones ? options?.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter)) : []}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => { return (option.id === value.id )}}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      label='Zone'
                    />
                  }
                  onChange={(event, value, reason) => {
                    if (reason !== 'clear') {
                      setZoneId(value.id);
                    }
                  }}
                />
              </FormControl>
            </Grid>

          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          className={classes.button}
          onClick={handleCloseDialog}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          className={classes.button}
          onClick={handleOnSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditAsset;
