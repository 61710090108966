import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Slide,
  Typography,
  useMediaQuery
} from '@mui/material';

import { useTheme } from '@mui/styles';

import useStyles from './styles';

import {
  useMutateDelTicketPart
} from '../../../hooks';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});


const DelTicketPart = (props) => {

  const {
    isDelTicketPartDialogOpen,
    setIsDelTicketPartDialogOpen,
    selectedTicketPartId,
    setSelectedTicketPartId,
    setIsTicketPartChecked
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isBreakpointsDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBreakpointsUpLg = useMediaQuery(theme.breakpoints.up('lg'));

  const delTicketPartMutation = useMutateDelTicketPart();

  const handleCloseDialog = () => {
    setSelectedTicketPartId();
    setIsTicketPartChecked(false);
    setIsDelTicketPartDialogOpen(false);
  };

  const delTicketPart = (ticketPartId) => {
    if (ticketPartId) {
      delTicketPartMutation.mutate({ id: ticketPartId });
    };
  };

  const handleOnSubmit = () => {
    delTicketPart(selectedTicketPartId);
    handleCloseDialog();
  };

  return (
    <Dialog
      fullScreen={isBreakpointsDownSm}
      style={{ minWidth: !isBreakpointsDownSm ? 600 : 200 }}
      open={isDelTicketPartDialogOpen}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Typography component='h1' variant='h5' align='center'>
          Do you want to remove this part?
        </Typography>
      </DialogTitle>
      <DialogActions>
        <Button
          variant='contained'
          className={classes.button}
          onClick={handleCloseDialog}
        >
          No
        </Button>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          className={classes.button}
          onClick={handleOnSubmit}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DelTicketPart;
