import React from 'react';
import QRCode from 'qrcode.react';
import { Base64 } from 'js-base64';

const QRGenerator = (props) => {

  const {
    assetId
  } = props;

  return (
    <div id='qr-container' style={{width: 128, height: 128}}>
      <QRCode
        id='qr-asset'
        value={Base64.encode(JSON.stringify({
          assetId: assetId
        }))}
        size={128}
        bgColor={'#ffffff'}
        fgColor={'#000000'}
        level={'H'}
        includeMargin={false}
        renderAs={'svg'}
      />
    </div>
  )
}

export default QRGenerator;
